import React from "react";
import "../GetStarted.css";
import "./TrialCTA.css";
import { Typography } from "@mui/material";
import XFATag from "../../General/XfaTag/XfaTag";

interface TrialCardProps {
  text: string;
  description: string;
  tagText: string;
  tagIcon?: string;
}

const TrialCard: React.FC<TrialCardProps> = ({
  text,
  description,
  tagText,
  tagIcon,
}) => {
  return (
    <div className={`trial-card`}>
      <div>
        <Typography variant="h3" className={`trial-card-title`}>
          {text}
        </Typography>
        <Typography variant="body1" className={`trial-card-description`}>
          {description}
        </Typography>
      </div>
      <XFATag text={tagText} icon={tagIcon} type="info" />
    </div>
  );
};

export default TrialCard;
