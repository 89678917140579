import React from "react";
import "./SelectionButton.css";

interface SelectionButtonProps {
  logo: string;
  disabled?: boolean;
  title: string;
  description: string;
  onClick: () => void;
}

const SelectionButton: React.FC<SelectionButtonProps> = (
  props: SelectionButtonProps,
) => {
  return (
    <div
      className={`selection-button ${props.disabled ? "selection-button-disabled" : ""}`}
      onClick={props.onClick}
    >
      <div className="header">
        {props.logo && <img src={props.logo} alt="Logo" className="logo" />}
        <div className="title">{props.title}</div>
      </div>
      <div className="description">{props.description}</div>
    </div>
  );
};

export default SelectionButton;
