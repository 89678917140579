import React from "react";
import { Card, CardContent, Radio, Box, Divider, Link } from "@mui/material";
import "./DiscoveryCard.css";
import { XfaSwitch } from "../General/XfaSwitch";

interface DiscoveryCardProps {
  value: string;
  selected: boolean;
  logoSrc: string;
  title: string;
  description: string;
  additionalInfo?: string;
  additionalInfo2?: string;
  children?: React.ReactNode;
  onSelect: (value: string) => void;
  onHandleEasySetupChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => void;
}

const DiscoveryCard: React.FC<DiscoveryCardProps> = ({
  value,
  selected,
  logoSrc,
  title,
  description,
  additionalInfo,
  additionalInfo2,
  children,
  onSelect,
  onHandleEasySetupChange,
}) => {
  return (
    <Card
      className={`discovery-card ${selected ? "selected" : ""}`}
      onClick={() => onSelect(value)}
    >
      <Radio
        checked={selected}
        className="discovery-card-radio"
        onClick={(e) => {
          onSelect(value);
          e.stopPropagation();
        }}
      />
      <CardContent className="discovery-card-container">
        <Box className="discovery-card-content">
          <img src={logoSrc} alt={title} className="discovery-logo" />
          <Box className="discovery-card-copy">
            <div className="discovery-card-title">{title}</div>
            <div className="discovery-card-description">{description}</div>
            {additionalInfo && (
              <>
                <Divider className="discovery-card-divider" />
                <div className="discovery-card-additional-info-container">
                  {value === "google" && (
                    <>
                      <XfaSwitch
                        color="primary"
                        className="discovery-card-switch"
                        onChange={onHandleEasySetupChange}
                      />
                      <div className="discovery-card-additional-info">
                        {additionalInfo}
                      </div>
                    </>
                  )}
                  {value === "microsoft" && (
                    <div className="discovery-card-additional-info">
                      {additionalInfo}
                      <Link
                        onClick={(e) => {
                          window.Intercom("show");
                          e.stopPropagation();
                        }}
                        style={{ textDecoration: "none", cursor: "pointer" }}
                      >
                        <div className="discovery-card-additional-info-link">
                          {additionalInfo2}
                        </div>
                      </Link>
                    </div>
                  )}
                </div>
              </>
            )}
            {children && <Box>{children}</Box>}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default DiscoveryCard;
