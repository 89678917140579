import React from "react";
import "./DiscoveryHighlight.css";
import QuestionIcon from "../../images/question-icon.svg";
import { Tooltip } from "@mui/material";
import { Device } from "../API/XFA_API";
import { isMobile } from "../Users/UserUtils";
import { useTranslation } from "react-i18next";

interface DiscoveryHighlightProps {
  devices: Device[];
  propertyName: keyof Device;
  tooltip?: string;
  tooltipExtra?: string;
}

const getTitleByPropertyName = (propertyName: keyof Device): string => {
  const { t } = useTranslation();
  const titles: { [key in keyof Device]?: string } = {
    diskencryption_active: t("discovery.DiskEncryption.title"),
    browser_uptodate: t("discovery.BrowserUpToDate.title"),
    antivirus_enabled: t("discovery.Antivirus.title"),
    authentication_active: t("discovery.Authentication.title"),
    os_uptodate: t("discovery.OSUpToDate.title"),
    nativeclient_installed: t("discovery.NativeClientInstalled.title"),
    biometrics: t("discovery.Biometrics.title"),
    os_autoupdate: t("discovery.OSAutoUpdate.title"),
    passwordmanager: t("discovery.PasswordManager.title"),
  };

  return titles[propertyName] || "Unknown Property";
};

const calculateCorrectedPercentages = (
  enabled: number,
  disabled: number,
  notSet: number,
) => {
  const total = enabled + disabled + notSet;

  const exactEnabledPercentage = (enabled / total) * 100;
  const exactDisabledPercentage = (disabled / total) * 100;
  const exactNotSetPercentage = (notSet / total) * 100;

  let enabledPercentage = Math.round(exactEnabledPercentage);
  let disabledPercentage = Math.round(exactDisabledPercentage);
  let notSetPercentage = Math.round(exactNotSetPercentage);

  const totalPercentage =
    enabledPercentage + disabledPercentage + notSetPercentage;

  if (totalPercentage !== 100) {
    const difference = 100 - totalPercentage;

    if (
      enabledPercentage >= disabledPercentage &&
      enabledPercentage >= notSetPercentage
    ) {
      enabledPercentage += difference;
    } else if (
      disabledPercentage >= enabledPercentage &&
      disabledPercentage >= notSetPercentage
    ) {
      disabledPercentage += difference;
    } else {
      notSetPercentage += difference;
    }
  }

  return { enabledPercentage, disabledPercentage, notSetPercentage };
};

const DiscoveryHighlight: React.FC<DiscoveryHighlightProps> = ({
  devices,
  propertyName,
  tooltip,
  tooltipExtra,
}) => {
  const { t } = useTranslation();
  const filteredDevices = devices.filter((device) => {
    if (
      [
        "antivirus_enabled",
        "nativeclient_installed",
        "browser_uptodate",
        "passwordmanager",
        "os_autoupdate",
      ].includes(propertyName)
    ) {
      return !isMobile(device.os_name);
    } else if (propertyName === "biometrics") {
      return isMobile(device.os_name);
    }
    return true;
  });
  const total = filteredDevices.length;

  let enabled = 0;
  let notSet = 0;
  let disabled = 0;
  if (propertyName === "passwordmanager") {
    enabled = filteredDevices.filter(
      (device) =>
        device.passwordmanager &&
        Object.values(device.passwordmanager).some(
          (enabled) => enabled === true,
        ),
    ).length;
    notSet = filteredDevices.filter(
      (device) =>
        device.passwordmanager &&
        Object.values(device.passwordmanager).every(
          (enabled) => enabled === false,
        ),
    ).length;
  } else if (propertyName === "biometrics") {
    enabled = filteredDevices.filter(
      (device) => device.biometrics && device.biometrics !== "none",
    ).length;
    notSet = filteredDevices.filter(
      (device) => device.biometrics === "none",
    ).length;
  } else if (propertyName === "browser_uptodate") {
    enabled = filteredDevices.filter((device) => {
      const browsers = [
        device.chrome_uptodate,
        device.firefox_uptodate,
        device.edge_uptodate,
        device.safari_uptodate,
        device.browser_uptodate,
      ];

      const hasAtLeastOneUpToDate = browsers.some((status) => status === true);
      const hasNoFalse = browsers.every((status) => status !== false);

      return hasAtLeastOneUpToDate && hasNoFalse;
    }).length;
    notSet = filteredDevices.filter(
      (device) =>
        device.chrome_uptodate === false ||
        device.firefox_uptodate === false ||
        device.edge_uptodate === false ||
        device.safari_uptodate === false,
    ).length;
  } else {
    enabled = filteredDevices.filter(
      (device) => device[propertyName] === true,
    ).length;
    notSet = filteredDevices.filter(
      (device) => device[propertyName] === false,
    ).length;
  }
  disabled = total - enabled - notSet;

  const { enabledPercentage, disabledPercentage, notSetPercentage } =
    calculateCorrectedPercentages(enabled, disabled, notSet);

  return (
    <div className="highlight-card">
      <div className="highlight-header">
        <span className="highlight-title">
          {getTitleByPropertyName(propertyName)}
        </span>
        {tooltip && (
          <Tooltip
            title={
              <>
                <span>{tooltip}</span>
                {tooltipExtra && (
                  <>
                    <br />
                    <br />
                    <span>{tooltipExtra}</span>
                  </>
                )}
              </>
            }
            arrow
            placement={"right"}
          >
            <img src={QuestionIcon} className="highlight-icon" />
          </Tooltip>
        )}
      </div>
      <div className="highlight-progress-bar-container">
        <Tooltip title={enabled} arrow placement={"top"}>
          <div
            className="highlight-progress-bar highlight-enabled-bar"
            style={{ width: `${enabledPercentage}%` }}
          />
        </Tooltip>
        <Tooltip title={disabled} arrow placement={"top"}>
          <div
            className="highlight-progress-bar highlight-disabled-bar"
            style={{ width: `${disabledPercentage}%` }}
          />
        </Tooltip>
        <Tooltip title={notSet} arrow placement={"top"}>
          <div
            className="highlight-progress-bar highlight-not-encrypted-bar"
            style={{ width: `${notSetPercentage}%` }}
          />
        </Tooltip>
      </div>
      <div className="highlight-legend-container">
        <div className="highlight-legend">
          <div className="highlight-legend-item">
            <div className="highlight-legend-color highlight-enabled-color" />
            <span className="highlight-legend-total">{enabled}</span>
            <span className="highlight-legend-percentage">
              ({enabledPercentage}%)
            </span>
          </div>
          <div className="highlight-legend-item">
            <div className="highlight-legend-color highlight-disabled-color" />
            <span className="highlight-legend-total">{disabled}</span>
            <span className="highlight-legend-percentage">
              ({disabledPercentage}%)
            </span>
          </div>
          <div className="highlight-legend-item">
            <div className="highlight-legend-color highlight-not-encrypted-color" />
            <span className="highlight-legend-total">{notSet}</span>
            <span className="highlight-legend-percentage">
              ({notSetPercentage}%)
            </span>
          </div>
        </div>
        <span className="highlight-legend-percentage">
          {t("discovery.total")} {total}
        </span>
      </div>
    </div>
  );
};

export default DiscoveryHighlight;
