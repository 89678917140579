import React from "react";

export const parseWindows = (
  osName: string,
  osVersion: string | undefined,
  osFlavour: string | undefined,
) => {
  if (!osVersion) {
    return osName;
  }
  const splittedVersion = osVersion.split(".");
  if (splittedVersion.length < 3 || splittedVersion.length > 4) {
    let windows = osName;
    if (osVersion) {
      windows += " " + osVersion;
    }
    if (osFlavour) {
      windows += " (" + osFlavour + ")";
    }
    return windows;
  }
  const build = Number(splittedVersion[2]);
  const buildVersion = splittedVersion.slice(2).join(".");
  //future: these are manual changes, should be included on versions as a specifal Marketing version of Windows
  if (build > 22631) {
    return (
      "Windows 11 " +
      (osFlavour ? osFlavour + " " : "") +
      "(" +
      buildVersion +
      ")"
    );
  } else if (build == 22631) {
    return (
      "Windows 11 " +
      (osFlavour ? osFlavour + " " : "") +
      "(23H2, " +
      buildVersion +
      ")"
    );
  } else if (build >= 22621) {
    return (
      "Windows 11 " +
      (osFlavour ? osFlavour + " " : "") +
      "(22H2, " +
      buildVersion +
      ")"
    );
  } else if (build >= 22000) {
    return (
      "Windows 11 " +
      (osFlavour ? osFlavour + " " : "") +
      "(21H2, " +
      buildVersion +
      ")"
    );
  } else if (build === 19044) {
    return (
      "Windows 10 " +
      (osFlavour ? osFlavour + " " : "") +
      "(21H2, " +
      buildVersion +
      ")"
    );
  } else if (build === 19043) {
    return (
      "Windows 10 " +
      (osFlavour ? osFlavour + " " : "") +
      "(21H1, " +
      buildVersion +
      ")"
    );
  } else if (build === 19042) {
    return (
      "Windows 10 " +
      (osFlavour ? osFlavour + " " : "") +
      "(20H2, " +
      buildVersion +
      ")"
    );
  } else if (build === 19041) {
    return (
      "Windows 10 " +
      (osFlavour ? osFlavour + " " : "") +
      "(2004, " +
      buildVersion +
      ")"
    );
  } else if (build === 18363) {
    return (
      "Windows 10 " +
      (osFlavour ? osFlavour + " " : "") +
      "(1909, " +
      buildVersion +
      ")"
    );
  } else if (build === 18362) {
    return (
      "Windows 10 " +
      (osFlavour ? osFlavour + " " : "") +
      "(1903, " +
      buildVersion +
      ")"
    );
  } else if (build === 17763) {
    return (
      "Windows 10 " +
      (osFlavour ? osFlavour + " " : "") +
      "(1809, " +
      buildVersion +
      ")"
    );
  } else if (build === 17134) {
    return (
      "Windows 10 " +
      (osFlavour ? osFlavour + " " : "") +
      "(1803, " +
      buildVersion +
      ")"
    );
  } else if (build === 16299) {
    return (
      "Windows 10 " +
      (osFlavour ? osFlavour + " " : "") +
      "(1709, " +
      buildVersion +
      ")"
    );
  } else if (build === 15063) {
    return (
      "Windows 10 " +
      (osFlavour ? osFlavour + " " : "") +
      "(1703, " +
      buildVersion +
      ")"
    );
  } else if (build === 14393) {
    return (
      "Windows 10 " +
      (osFlavour ? osFlavour + " " : "") +
      "(1607, " +
      buildVersion +
      ")"
    );
  } else if (build === 10586) {
    return (
      "Windows 10 " +
      (osFlavour ? osFlavour + " " : "") +
      "(1511, " +
      buildVersion +
      ")"
    );
  } else if (build === 10240) {
    return (
      "Windows 10 " +
      (osFlavour ? osFlavour + " " : "") +
      "(1507, " +
      buildVersion +
      ")"
    );
  } else if (build === 9600) {
    return (
      "Windows 8.1 " +
      (osFlavour ? osFlavour + " " : "") +
      "(" +
      buildVersion +
      ")"
    );
  } else if (build === 9200) {
    return (
      "Windows 8 " +
      (osFlavour ? osFlavour + " " : "") +
      "(" +
      buildVersion +
      ")"
    );
  } else if (build === 7601) {
    return (
      "Windows 7 " +
      (osFlavour ? osFlavour + " " : "") +
      "(" +
      buildVersion +
      ")"
    );
  } else {
    return osName + " " + osVersion + (osFlavour ? " (" + osFlavour + ")" : "");
  }
};

export const parseOSVersion = (
  osName: string,
  osVersion: string | undefined,
  osFlavour: string | undefined,
  androidSecurityPatchLevel: string | undefined,
) => {
  if (osName === "Windows") {
    return parseWindows(osName, osVersion, osFlavour);
  } else if (osName === "Android") {
    const postFixSecurityLevel =
      androidSecurityPatchLevel === undefined ||
      androidSecurityPatchLevel === null
        ? ""
        : " (" + androidSecurityPatchLevel + ")";
    return osName + " " + osVersion + postFixSecurityLevel;
  } else {
    return osName + " " + osVersion;
  }
};

export const parseOS = (
  osName: string | undefined,
  osVersion: string | undefined,
  osFlavour: string | undefined,
  androidSecurityPatchLevel: string | undefined,
) => {
  if (osName === undefined || osVersion?.includes(osName)) {
    return osVersion;
  } else if (osName === null || osVersion === null) {
    return "";
  } else {
    return parseOSVersion(
      osName,
      osVersion,
      osFlavour,
      androidSecurityPatchLevel,
    );
  }
};
