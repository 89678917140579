import React from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Alert,
} from "@mui/material";
import "./AdminTab.css";
import { Role, DashboardUser } from "../API/XFA_API";
import AdminUserActionMenu from "./AdminUserMenu";

export interface AdminTabProps {
  t: (key: string) => string;
  openInviteUsers: () => void;
  users: DashboardUser[];
  setAdminInProgress: (email: string) => void;
  refreshUsers: () => void;
  error: string;
  setError: (message: string) => void;
  formatDate: (date: string) => string;
  getNrOfAdmins: () => number;
  role: Role;
}

const AdminTab: React.FC<AdminTabProps> = ({
  t,
  openInviteUsers,
  users,
  setAdminInProgress,
  refreshUsers,
  error,
  setError,
  formatDate,
  getNrOfAdmins,
  role,
}) => {
  return (
    <div className="admin-container">
      <div className="admin-settings-section">
        <div>
          <div className="inline-settings-header">{t("settings.team")}</div>
          <div className="inline-settings-description">
            {t("settings.manageAdmins")}
          </div>
        </div>
      </div>
      <div className="table-section">
        <Button onClick={openInviteUsers} className="invite-button">
          {t("settings.addAdmin")}
        </Button>
        <TableContainer component={Paper} className="no-margin-top">
          <Table aria-label="simple table" className="admin-table">
            <TableHead className="table-head">
              <TableRow>
                <TableCell>{t("Users.email")}</TableCell>
                <TableCell>
                  <div>{t("settings.dateAdded")}</div>
                </TableCell>
                <TableCell style={{ textAlign: "right" }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users?.map((user) => (
                <TableRow className="selectableRow" key={user.email}>
                  <TableCell component="th" scope="row">
                    <div className="email">{user.email}</div>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {formatDate(user.timestamp ?? "")}
                  </TableCell>
                  <TableCell style={{ textAlign: "right" }}>
                    <AdminUserActionMenu
                      user={user}
                      role={role}
                      setAdminInProgress={setAdminInProgress}
                      refreshUsers={refreshUsers}
                      getNrOfAdmins={getNrOfAdmins}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {error && (
            <Alert
              onClose={() => {
                setError("");
              }}
              severity="error"
            >
              {error}
            </Alert>
          )}
        </TableContainer>
      </div>
    </div>
  );
};

export default AdminTab;
