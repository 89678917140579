import React from "react";
import { Chip, Tooltip } from "@mui/material";
import { DeviceRisk, DeviceStatus } from "./UserUtils";
import { useTranslation } from "react-i18next";
import { RiskIcons } from "./RiskIcons";
import MicrosoftIcon from "../Applications/NewOrEditPage/Microsoft/microsoft.svg";

const DeviceStatusChip = ({
  status,
  risk,
  label,
  tooltip,
  managed,
}: {
  status: DeviceStatus;
  risk: DeviceRisk;
  label: string;
  tooltip?: string;
  managed?: boolean;
}) => {
  const { t } = useTranslation();
  const icon = RiskIcons[risk] || RiskIcons.unsupported;
  return (
    <div
      style={{ width: "fit-content", display: "flex", alignItems: "center" }}
    >
      {managed && (
        <Chip
          icon={
            <div
              style={{
                marginLeft: 5,
                marginRight: -6,
              }}
            >
              <img
                src={MicrosoftIcon}
                style={{ height: "12px", width: "12px" }}
              />
            </div>
          }
          style={{
            marginRight: "-18px",
            height: "24px",
            width: "44px",
            marginTop: "4px",
          }}
        />
      )}
      <Tooltip
        title={
          tooltip
            ? tooltip
            : risk === DeviceRisk.Unknown && t("devices.StatusUnknownTooltip")
        }
      >
        <Chip
          icon={icon}
          label={label}
          style={{
            backgroundColor:
              status !== DeviceStatus.Unverified ? "#E2E4E8" : "white",
            color:
              status !== DeviceStatus.Unverified
                ? "#10131A"
                : "var(--color-gray-400)",
            marginRight: "8px",
            marginTop: "4px",
            fontWeight: 600,
            fontSize: "14px",
            height: "24px",
            cursor: "pointer",
            border:
              status === DeviceStatus.Unverified ? "1px dashed black" : "none",
            borderColor: "var(--color-gray-400)",
          }}
        />
      </Tooltip>
    </div>
  );
};

export default DeviceStatusChip;
