import { Typography, Tooltip } from "@mui/material";
import React from "react";
import { XfaSwitch } from "../../General/XfaSwitch";

const BooleanSetting = (props: {
  label: string;
  explanation: string;
  value: boolean;
  onChange: (value: boolean) => void;
  disabled?: boolean;
  disabledTooltip?: string;
}) => {
  return (
    <div style={{ display: "flex", width: "100%" }}>
      <div style={{ width: "100%" }}>
        <Typography variant="setting">{props.label}</Typography>
        <Typography variant="instructions">{props.explanation}</Typography>
      </div>
      <div>
        <Tooltip title={(props.disabled && props.disabledTooltip) || ""}>
          <div className="divForTooltipToWork">
            <XfaSwitch
              checked={props.value}
              disabled={props.disabled}
              disableRipple
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                props.onChange(event.target.checked)
              }
            />
          </div>
        </Tooltip>
      </div>
    </div>
  );
};
export default BooleanSetting;
