import { useState, useCallback, useEffect } from "react";
import XFA_API, { FullOrganization } from "../../API/XFA_API";

interface UseRefreshOrganizationParams {
  organizationId: string;
  t: (key: string) => string;
}

const useOrganization = ({
  organizationId,
  t,
}: UseRefreshOrganizationParams) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [organization, setOrganization] = useState<FullOrganization | null>(
    null,
  );
  const [autoVerifyMail, setAutoVerifyMail] = useState(false);
  const [autoRiskMail, setAutoRiskMail] = useState(false);

  const refreshOrganization = useCallback(async () => {
    setLoading(true);
    try {
      const org = await XFA_API.getOrganizationFull(organizationId);
      if (!org) {
        setError(t("organization.error"));
        return;
      }
      setOrganization(org);
      setAutoVerifyMail(org.notify_discovered_devices ?? false);
      setAutoRiskMail(org.notify_unsafe_devices ?? false);
    } catch (error: any) {
      setError(t("organization.error"));
    } finally {
      setLoading(false);
    }
  }, [t, organizationId]);

  useEffect(() => {
    refreshOrganization();
  }, [refreshOrganization]);

  return {
    loading,
    error,
    organization,
    autoVerifyMail,
    autoRiskMail,
    refreshOrganization,
  };
};

export default useOrganization;
