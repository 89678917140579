import { t } from "i18next";
import { Application, Policies } from "../../API/XFA_API";

export const getApplicationString = (
  applications: Application[],
  policy: Policies,
  applicationCount: number,
) => {
  if (applications) {
    const filteredApplications = applications.filter(
      (application) => application.PolicyID === policy.policy_id,
    );

    let applicationString = "";
    applications.forEach((application) => {
      if (application.PolicyID === policy.policy_id) {
        applicationString += application.Name + ", ";
      }
    });
    if (applicationCount === filteredApplications.length) {
      return t("policies.allIntegrations");
    } else if (applicationString.length > 0) {
      return applicationString.slice(0, -2);
    } else {
      return t("policies.noIntegrations");
    }
  }
  return t("policies.noIntegrations");
};

export const hasApplications = (
  applications: Application[],
  policy: Policies,
) => {
  if (applications) {
    return applications.some(
      (application) => application.PolicyID === policy.policy_id,
    );
  }
  return false;
};

export const applicationPolicy = (
  application: Application,
  policies: Policies[],
  policyName?: string,
) => {
  if (policies) {
    return (
      policies.find((policy) => policy.policy_id === application.PolicyID)
        ?.name ??
      policyName ??
      t("policies.customPolicy")
    );
  }
  return policyName ?? t("policies.customPolicy");
};
