import {v4 as uuidv4} from 'uuid';

const csrfTokenStorageName = 'csrfToken';

export const getCsrfToken = () => {
  // check if csrf token is set
  const csrfToken = getSessionStorage(csrfTokenStorageName);
  if (csrfToken === '') {
    return null;
  }
  return csrfToken;
};

export const setCsrfToken = () => {
  // generate csrf token
  const csrfToken = uuidv4();

  // set csrf token in session storage
  setSessionStorage(csrfTokenStorageName, csrfToken);

  // return csrf token so it can be used in the request
  return csrfToken;
};

export const getSessionStorage = (name: string) => {
  if (typeof window !== 'undefined') {
    return sessionStorage.getItem(name);
  }
};

export const setSessionStorage = (name: string, value: string) => {
  if (typeof window !== 'undefined') {
    sessionStorage.setItem(name, value);
  }
};
