import React from "react";
import { DeviceRisk } from "./UserUtils";
import { Shadow } from "../API/XFA_API";
import "./StickyFooter.css";
import { useTranslation } from "react-i18next";
import StatusChip from "./StatusChip";

interface StickyFooterProps {
  user: Shadow;
  securityStatus: DeviceRisk;
}

const StickyFooter: React.FC<StickyFooterProps> = ({
  user,
  securityStatus,
}) => {
  const { t } = useTranslation();
  return (
    <div className="sticky-footer">
      <div className="sticky-footer-name">
        {user.devices.find((device) => device.user?.full_name) && (
          <div className="fullname">
            {
              user.devices.find((device) => device.user?.full_name)?.user
                ?.full_name
            }
          </div>
        )}
        <div className="email">{user.email}</div>
      </div>
      <div className="sticky-footer-status">
        {t("Users.securityStatus")}
        <StatusChip status={securityStatus} />
      </div>
    </div>
  );
};

export default StickyFooter;
