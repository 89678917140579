import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  FormControl,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Application, ConnectionError } from "../../../API/XFA_API";
import { Visibility, VisibilityOff } from "@mui/icons-material";

interface OktaDiscoveryProps {
  application: Application;
  setApplication: (application: Application) => void;
  vertical: boolean;
  connectionError?: ConnectionError;
}

const OktaDiscovery: React.FC<OktaDiscoveryProps> = ({
  application,
  setApplication,
  vertical,
}) => {
  const { t } = useTranslation();

  const [showSecret, setShowSecret] = useState(false);

  return (
    <div>
      <div
        className={vertical ? "verticalSelectionSection" : "selectionSection"}
      >
        <div>
          <Typography variant="formsectiontitle">
            {t("applications.okta.discoveryTitle")}
          </Typography>
          <Typography variant="instructions" style={{ marginTop: 16 }}>
            {t("applications.okta.step1")}
          </Typography>
          <FormControl fullWidth={true}>
            <Typography variant="instructions">
              {t("applications.okta.step1description")}
            </Typography>
            <Typography variant="instructions" style={{ marginBottom: 16 }}>
              {t("applications.okta.step2")}
            </Typography>
            <Typography variant="formlabel">
              {t("applications.okta.oktaDomain")}
            </Typography>
            <TextField
              required
              id="oktaDomain"
              style={{ marginBottom: 16 }}
              defaultValue={application?.OktaConnection?.OktaDomain}
              placeholder={t("applications.okta.oktaDomain")}
              onChange={(event) => {
                if (!application) return;
                application.OktaConnection = {
                  OktaDomain: event.target.value,
                  ClientID: application.OktaConnection?.ClientID || "",
                  ClientSecret: application.OktaConnection?.ClientSecret || "",
                };
                setApplication(application);
              }}
            ></TextField>
            <Typography variant="formlabel">
              {t("applications.okta.clientId")}
            </Typography>
            <TextField
              style={{ marginBottom: 16 }}
              required
              id="clientId"
              defaultValue={application?.OktaConnection?.ClientID}
              placeholder={t("applications.okta.clientId")}
              onChange={(event) => {
                if (!application) return;
                application.OktaConnection = {
                  OktaDomain: application.OktaConnection?.OktaDomain || "",
                  ClientID: event.target.value,
                  ClientSecret: application.OktaConnection?.ClientSecret || "",
                };
                setApplication(application);
              }}
            ></TextField>
            <Typography variant="formlabel">
              {t("applications.okta.clientSecret")}
            </Typography>
            <TextField
              style={{ marginBottom: 16 }}
              required
              id="clientSecret"
              type={showSecret ? "text" : "password"}
              defaultValue={application?.OktaConnection?.ClientSecret}
              placeholder={t("applications.okta.clientSecret")}
              onChange={(event) => {
                if (!application) return;
                application.OktaConnection = {
                  OktaDomain: application.OktaConnection?.OktaDomain || "",
                  ClientID: application.OktaConnection?.ClientID || "",
                  ClientSecret: event.target.value,
                };
                setApplication(application);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle secret visibility"
                      onClick={() => setShowSecret(!showSecret)}
                      edge="end"
                    >
                      {showSecret ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            ></TextField>
          </FormControl>
        </div>
      </div>
    </div>
  );
};

export default OktaDiscovery;
