import React from "react";
import "./XfaSwitchWithDescription.css";
import { XfaSwitch } from "./XfaSwitch";

interface XfaSwitchWithDescriptionProps {
  label: string;
  description?: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
  disabled?: boolean;
}

const XfaSwitchWithDescription: React.FC<XfaSwitchWithDescriptionProps> = ({
  label,
  description,
  checked,
  onChange,
  disabled = false,
}) => {
  return (
    <div className={`checkbox-container ${disabled ? "disabled" : ""}`}>
      <XfaSwitch
        checked={checked}
        onChange={(e) => onChange(e.target.checked)}
        disabled={disabled}
      />
      <div className="checkbox-content">
        <label className="checkbox-label">{label}</label>
        {description && <p className="checkbox-description">{description}</p>}
      </div>
    </div>
  );
};

export default XfaSwitchWithDescription;
