import { NavigateFunction } from "react-router-dom";
import { ConnectionError } from "../components/API/XFA_API";

class NavigationService {
  private navigate: NavigateFunction | null = null;

  setNavigate(navigate: NavigateFunction) {
    this.navigate = navigate;
  }

  navigateTo(path: string, state?: any) {
    if (this.navigate) {
      this.navigate(path, { state });
    } else {
      console.error(
        "Navigation function not set. Make sure to initialize NavigationService with 'setNavigate'.",
      );
    }
  }

  navigateToLogin() {
    this.navigateTo("/login");
  }

  navigateToExternal(url: string) {
    window.location.assign(url);
  }

  navigateToHome(params?: string) {
    this.navigateTo("/" + (params ?? ""));
  }

  navigateToNewApplication() {
    this.navigateTo("/applications/new");
  }

  navigateToApplications() {
    this.navigateTo("/applications");
  }

  navigateToPolicies() {
    this.navigateTo("/policies");
  }
  navigateToPolicy(id?: string) {
    if (id) {
      this.navigateTo(`/policies/${id}`);
      return;
    }
    this.navigateTo("/policies/new");
  }

  navigateToDevices(filter?: string) {
    this.navigateTo(`/devices${filter ? `?filterStatus=${filter}` : ""}`);
  }

  navigateToApplication(applicationId: string, error?: string) {
    // if error is provided, navigate to the application with the error
    if (error) {
      this.navigateTo(`/applications/${applicationId}?error=${error}`);
      return;
    }
    this.navigateTo(`/applications/${applicationId}`);
  }

  navigateToDiscoveryOverview(params?: string) {
    this.navigateTo(`/discovery${params ? `?${params}` : ""}`);
  }

  navigateToAwarenessOverview(params?: string) {
    this.navigateTo(`/awareness${params ? `?${params}` : ""}`);
  }

  navigateToDiscovery(applicationId: string, error?: ConnectionError) {
    // if error is provided, navigate to the application with the error
    if (error) {
      this.navigateTo(`/discovery/${applicationId}`, {
        state: { connectionError: error },
      });
      return;
    }
    this.navigateTo(`/discovery/${applicationId}`);
  }

  navigateToSignup(step: number) {
    this.navigateTo("/signup?step=" + step);
  }

  navigateToBilling() {
    this.navigateTo("/settings/billing");
  }

  navigateToOktaIntegration() {
    this.navigateTo("/okta-integration");
  }

  goBack() {
    if (this.navigate) {
      this.navigate(-1);
    }
  }

  goBackTwice() {
    if (this.navigate) {
      this.navigate(-2);
    }
  }
}

export default new NavigationService();
