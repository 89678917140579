import { Fab } from "@mui/material";
import React, { ReactNode, useEffect } from "react";
import { useLocation } from "react-router-dom";

import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import { useTranslation } from "react-i18next";
import { Role } from "../API/XFA_API";
import { SignedInUser } from "../Auth/Login/SignedInUser";

// Extend the Window interface to include Intercom typings
// This step is optional but recommended for type safety
declare global {
  interface Window {
    Intercom: (...args: any[]) => void;
    intercomSettings?: any;
    attachEvent?: (event: string, listener: () => void) => void;
  }
}

// Set your APP_ID
export const APP_ID: string = "qana8cn9";

// Loads Intercom with the snippet
// This must be run before boot, it initializes window.Intercom
export const loadIntercom: () => void = () => {
  // prettier-ignore
  (function() {
    var w: Window = window;
    var ic: any = w.Intercom;
    if (typeof ic === "function") {
      ic('reattach_activator');
      ic('update', w.intercomSettings);
    } else {
      var d = document;
      var i: any = function() { i.c(arguments); };
      i.q = [];
      i.c = function(args: IArguments) { i.q.push(args); };
      w.Intercom = i;
      var l: () => void = function() {
        var s = d.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = 'https://widget.intercom.io/widget/' + APP_ID;
        var x = d.getElementsByTagName('script')[0];
        x.parentNode?.insertBefore(s, x);
      };if (document.readyState === "complete") {
        l();
      } else if (w.attachEvent) {
        w.attachEvent('onload', l);
      } else {
        w.addEventListener('load', l, false);
      }
    }
  })();
};

export const trackEvent: (eventName: string, metadata?: any) => void = (
  eventName,
  metadata,
) => {
  window &&
    window.Intercom &&
    window.Intercom("trackEvent", eventName, metadata);
};

// Initializes Intercom
export const bootIntercom: (options?: { [key: string]: any }) => void = (
  options = {},
) => {
  window &&
    window.Intercom &&
    window.Intercom("boot", {
      app_id: APP_ID,
      ...options,
      custom_launcher_selector: "#intercom",
      hide_default_launcher: true,
      vertical_padding: 80,
    });
};

export const updateIntercom: () => void = () => {
  window && window.Intercom && window.Intercom("update");
};

// Define a type for the component's props
type IntercomProviderProps = {
  children: ReactNode;
  user?: SignedInUser;
  role?: Role;
};

export const IntercomProvider = ({
  children,
  user,
  role,
}: IntercomProviderProps) => {
  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    if (typeof window !== "undefined") {
      loadIntercom();
      bootIntercom();
    }
  }, []);

  useEffect(() => {
    if (typeof window !== "undefined") {
      updateIntercom();
    }
  }, [location]); // This effect runs when the location changes.

  useEffect(() => {
    if (role === undefined) {
      return;
    }

    window.Intercom("update", {
      user_id: user?.username,
      user_hash: role.intercom_hmac,
      email: role.email,
      company: role.organization.name,
    });
  }, [role]);

  return (
    <>
      {children}
      <Fab
        variant="extended"
        id="intercom"
        style={{
          right: 20,
          bottom: 20,
          fontWeight: 600,
          position: "fixed",
          textTransform: "none",
        }}
      >
        <SupportAgentIcon sx={{ mr: 1 }} />
        {t("support")}
      </Fab>
    </>
  );
};
