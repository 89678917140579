import React from 'react';
import {useTranslation} from 'react-i18next';
import {Typography, Alert} from '@mui/material';
import './StatusOverview.css';
import {Role} from '../API/XFA_API';

interface StatusOverviewProps {
  role: Role;
}

const StatusOverview: React.FC<StatusOverviewProps> = () => {
  const {t} = useTranslation();

  return (
    <div>
      <Typography variant="h4" color="primary">
        {t('navigation.status')}
      </Typography>
      <Alert severity="info">Not yet available</Alert>
    </div>
  );
};

export default StatusOverview;
