import React from "react";
import { Card, CardContent } from "@mui/material";
import { useTranslation } from "react-i18next";
import "./FreeTrialCard.css";

const FreeTrialCard: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Card elevation={0} className="free-trial-card">
      <CardContent className="card-content">
        <div className="free-trial-top">{t("login.signup.freeTrial1")}</div>
        <div className="free-trial-title">{t("login.signup.freeTrial2")}</div>
        <div className="free-trial-subtitle">
          {t("login.signup.freeTrial3")}
        </div>
      </CardContent>
    </Card>
  );
};

export default FreeTrialCard;
