import React, { FormEvent } from "react";
import { useTranslation } from "react-i18next";
import { Button, TextField, Typography } from "@mui/material";
import { Auth } from "aws-amplify";
import "./AuthStateApp.css";

export interface CognitoUser {}

interface SetNewPasswordProps {
  onSignIn: (user: CognitoUser) => void;
  user: any;
}

const SetNewPassword = (props: SetNewPasswordProps) => {
  const [password, setPassword] = React.useState<string>("");
  const [error, setError] = React.useState<string | undefined>();
  const { t } = useTranslation();

  const login = (e: FormEvent<HTMLFormElement>) => {
    Auth.completeNewPassword(props.user, password)
      .then(function (user: any) {
        props.onSignIn(user);
      })
      .catch((err) => {
        console.log(err);
        setError(err.message);
        switch (err.code) {
          case "InvalidPasswordException":
            setError("login.errorInvalidNewPassword");
            break;
        }
      });
    e.preventDefault();
  };

  return (
    <form onSubmit={login}>
      <Typography variant="formtitle">{t("login.setNewPassword")}</Typography>
      <div id="fields">
        <div id="password">
          <div style={{ marginBottom: "4px" }}>
            <Typography variant="formlabel">{t("login.password")}</Typography>
          </div>
          <TextField
            placeholder={t("login.password")}
            variant="outlined"
            type={"password"}
            fullWidth
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            error={error !== undefined}
            helperText={error && t(error)}
            autoComplete="new-password"
            autoFocus
            required
          />
        </div>
        <div className="sendButton">
          <Button
            variant={"contained"}
            type={"submit"}
            color={"primary"}
            disableElevation
            size="large"
          >
            {t("login.setPassword")}
          </Button>
        </div>
      </div>
    </form>
  );
};
export default SetNewPassword;
