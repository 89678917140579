import { useState, useEffect, useCallback } from "react";
import { isDiscoveryApplication } from "../../../utils";
import NavigationService from "../../../utils/NavigationService";
import { Application, Policies, Role } from "../../API/XFA_API";
import { trackEvent } from "../../intercom/intercomProvider";
import { useTranslation } from "react-i18next";

interface Task {
  id: number;
  text: string;
  completed: boolean;
  onClick: () => void;
}

interface UseTaskManagerProps {
  applications: Application[];
  hasDevices: boolean;
  policies: Policies[] | undefined;
  dashboardApplicationId: string | undefined;
  isBlocked: boolean;
  role: Role;
}

export const useTaskManager = ({
  applications,
  hasDevices,
  policies,
  dashboardApplicationId,
  isBlocked,
  role,
}: UseTaskManagerProps): { tasks: Task[]; loading: boolean } => {
  const [tasks, setTasks] = useState<Task[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const { t } = useTranslation();

  const isTaskCompleted = useCallback(
    (
      taskId: number,
      savedTasks: { completed?: boolean }[] | undefined,
      additionalCondition = false,
    ): boolean => {
      const savedTask = savedTasks?.[taskId - 1];
      return savedTask?.completed || additionalCondition;
    },
    [],
  );

  useEffect(() => {
    if (isBlocked) return;

    setLoading(true);

    const savedTasks: { completed?: boolean }[] = JSON.parse(
      localStorage.getItem("tasks") || "[]",
    );
    const filteredApplications = applications.filter((app) =>
      isDiscoveryApplication(app),
    );

    const updatedTasks: Task[] = [
      {
        id: 1,
        text: t("statistics.emptyState.step1"),
        completed: isTaskCompleted(
          1,
          savedTasks,
          filteredApplications.length > 0,
        ),
        onClick: () => {
          let currentUrl = window.location.origin + "/signup?step=5";
          NavigationService.navigateToExternal(currentUrl);
          trackEvent("Started " + t("statistics.emptyState.step1"));
        },
      },
      {
        id: 2,
        text: t("statistics.emptyState.step2"),
        completed: isTaskCompleted(2, savedTasks, hasDevices),
        onClick: () => {
          NavigationService.navigateToDevices();
          trackEvent("Started " + t("statistics.emptyState.step2"));
        },
      },
      {
        id: 3,
        text: t("statistics.emptyState.step3"),
        completed: isTaskCompleted(
          3,
          savedTasks,
          new URLSearchParams(window.location.search).get("taskCompleted") !==
            null,
        ),
        onClick: () => {
          if (dashboardApplicationId) {
            const url = new URL(process.env.REACT_APP_TOKEN_ENDPOINT!);
            url.searchParams.set(
              "redirect_url",
              `${window.location.href}?taskCompleted=true`,
            );
            url.searchParams.set("email", role.email);
            url.searchParams.set("application_id", dashboardApplicationId);
            NavigationService.navigateToExternal(url.toString());
            trackEvent("Started " + t("statistics.emptyState.step3"));
          }
        },
      },
      {
        id: 4,
        text: t("statistics.emptyState.step4"),
        completed: isTaskCompleted(
          4,
          savedTasks,
          Boolean(localStorage.getItem("verifyMail")),
        ),
        onClick: () => {
          NavigationService.navigateToAwarenessOverview("showLearnMore=true");
          trackEvent("Started " + t("statistics.emptyState.step4"));
        },
      },
      {
        id: 5,
        text: t("statistics.emptyState.step5"),
        completed: isTaskCompleted(
          5,
          savedTasks,
          policies !== undefined && policies.length > 0,
        ),
        onClick: () => {
          NavigationService.navigateToPolicy();
          trackEvent("Started " + t("statistics.emptyState.step5"));
        },
      },
      {
        id: 6,
        text: t("statistics.emptyState.step6"),
        completed: isTaskCompleted(6, savedTasks, applications.length > 1),
        onClick: () => {
          NavigationService.navigateToNewApplication();
          trackEvent("Started " + t("statistics.emptyState.step6"));
        },
      },
    ];

    localStorage.setItem(
      "tasks",
      JSON.stringify(updatedTasks.map(({ completed }) => ({ completed }))),
    );
    setTasks(updatedTasks);
    setLoading(false);
  }, [
    applications,
    hasDevices,
    policies,
    dashboardApplicationId,
    isBlocked,
    role,
    t,
    isTaskCompleted,
  ]);

  return { tasks, loading };
};
