import React, { useState } from "react";
import "../General/Dropdown/DropdownMenu.css";
import XFA_API, { Role, DashboardUser } from "../API/XFA_API";
import { useTranslation } from "react-i18next";
import deleteIcon from "../../images/delete-icon.svg";
import { IconButton, Tooltip } from "@mui/material";
import ConfirmationDialog from "../Dialogs/ConfirmationDialog";

interface AdminUserActionMenuProps {
  user: DashboardUser;
  role: Role;
  setAdminInProgress: (email: string) => void;
  refreshUsers: () => void;
  getNrOfAdmins: () => number;
}

const AdminUserActionMenu: React.FC<AdminUserActionMenuProps> = ({
  user,
  role,
  setAdminInProgress,
  refreshUsers,
  getNrOfAdmins,
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [confirmDeleteAdmin, setConfirmDeleteAdmin] = useState<
    | {
        email: string;
        organizationId: string;
      }
    | undefined
  >(undefined);
  const [deleteAdminInProgress, setDeleteAdminInProgress] =
    useState<boolean>(false);

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <div>
        <Tooltip
          title={
            getNrOfAdmins() === 1 || role.email === user.email
              ? t("settings.removeAdminTooltip")
              : ""
          }
          arrow
          placement="left"
        >
          {getNrOfAdmins() === 1 || role.email === user.email ? (
            <img
              src={deleteIcon}
              alt="deleteIcon"
              className="button-icon"
              style={{
                opacity: 0.5,
                marginLeft: "auto",
                marginRight: "8px",
              }}
            />
          ) : (
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={() => {
                setConfirmDeleteAdmin({
                  organizationId: role.organization.organization_id,
                  email: user.email,
                });
              }}
            >
              <img src={deleteIcon} alt="deleteIcon" className="button-icon" />
            </IconButton>
          )}
        </Tooltip>
      </div>
      {confirmDeleteAdmin !== undefined && (
        <ConfirmationDialog
          title={
            t("settings.deleteAdmin.confirmTitle") + confirmDeleteAdmin.email
          }
          cancelText={t("settings.deleteAdmin.cancel")}
          confirmText={t("settings.deleteAdmin.confirm")}
          inProgress={deleteAdminInProgress}
          onCancel={() => {
            setConfirmDeleteAdmin(undefined);
          }}
          onConfirm={async () => {
            setDeleteAdminInProgress(true);

            setAdminInProgress(user.email);
            XFA_API.deleteAdminUser(
              confirmDeleteAdmin.organizationId,
              confirmDeleteAdmin.email,
            ).finally(() => {
              refreshUsers();
              setAdminInProgress("");
              handleClose();
              setDeleteAdminInProgress(false);
              setConfirmDeleteAdmin(undefined);
            });
          }}
        />
      )}
    </div>
  );
};

export default AdminUserActionMenu;
