import React from "react";
import { useTranslation } from "react-i18next";
import {
  Alert,
  Button,
  TextField,
  Typography,
  Link,
  Box,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import { Auth } from "aws-amplify";
import "../AuthStateApp.css";

interface ConfirmSignUpProps {
  username: string | undefined;
  password: string | undefined;
  onNextStep: () => void;
  onPreviousStep: () => void;
  onSignIn: (user: any) => void;
}

const ConfirmSignUp = (props: ConfirmSignUpProps) => {
  const [error, setError] = React.useState<string | undefined>();
  const [verificationCode, setVerificationCode] = React.useState<string[]>([
    "",
    "",
    "",
    "",
    "",
    "",
  ]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [resendCompleted, setResendCompleted] = React.useState<boolean>(false);
  const [password, setPassword] = React.useState<string | undefined>(
    props.password,
  );
  const { t } = useTranslation();

  const verify = () => {
    const code = verificationCode.join("");

    if (
      username !== undefined &&
      password !== undefined &&
      code.length === 6 &&
      password!.length > 11
    ) {
      setIsLoading(true);

      Auth.confirmSignUp(username!, code)
        .then(() => {
          Auth.signIn(username!, password)
            .then(function (user: any) {
              console.log(user);
              props.onSignIn(user);
              props.onNextStep();
            })
            .catch((err) => {
              console.log(err);
              setError(err.message);
              switch (err.code) {
                case "UserNotFoundException":
                  setError("login.errorUserNotFound");
                  break;
                case "NotAuthorizedException":
                  setError("login.errorNotAuthorized");
                  break;
                case "InvalidParameterException":
                  setError("login.errorInvalidParameter");
                  break;
              }
            })
            .finally(() => {
              setIsLoading(false);
            });
        })
        .catch((err) => {
          console.log(err.message);
          setError(err.message);
          setIsLoading(false);
        });
    } else {
      setError(t("login.signup.wrongLinkConfirm"));
    }
  };

  React.useEffect(() => {
    if (verificationCode.join("").length === 6 && props.password) {
      verify();
    }
  }, [verificationCode]);

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    const value = event.clipboardData.getData("Text");
    const newVerificationCode = value.split("").slice(0, 6);
    setVerificationCode(newVerificationCode);
    document
      .getElementById(`code-input-${newVerificationCode.length - 1}`)
      ?.focus();
  };

  const handleInputChange =
    (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      const newCode = [...verificationCode];

      // Distribute the value among the fields
      for (let i = 0; i < value.length && index + i < 6; i++) {
        newCode[index + i] = value[i];
      }

      setVerificationCode(newCode);

      // Move focus to the next empty field
      let nextIndex = newCode.findIndex((val) => val === "");
      if (nextIndex === -1) {
        nextIndex = 5; // If all fields are filled, keep focus on the last one
      }
      document.getElementById(`code-input-${nextIndex}`)?.focus();
    };

  const handleKeyDown =
    (index: number) => (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Backspace") {
        event.preventDefault(); // Prevent the default backspace behavior

        const newCode = [...verificationCode];
        if (newCode[index] === "" && index > 0) {
          document.getElementById(`code-input-${index - 1}`)?.focus();
          newCode[index - 1] = "";
        } else {
          newCode[index] = "";
        }
        setVerificationCode(newCode);
      }
    };

  const resendEmail = () => {
    setError(undefined);
    console.log("Resending verification code to: ", props.username);
    if (username) {
      console.log("Resending verification code to: ", username);
      Auth.resendSignUp(username)
        .then(() => {
          console.log("Verification code resent successfully");
          setResendCompleted(true);
        })
        .catch((err) => {
          console.log("Error resending code: ", err);
          setError(t("login.signup.resendError"));
        });
    } else {
      setError(t("login.signup.resendError"));
    }
  };

  const username = props.username
    ? props.username.length > 0
      ? props.username
      : localStorage.getItem("signUp")
    : localStorage.getItem("signUp");

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          verify();
        }}
      >
        <Typography variant="forminstructions">
          {t("login.signup.confirmationTitle", {
            email: username,
          })}
        </Typography>
        <>
          <Typography
            variant="formlinkdescription"
            style={{ display: "inline", paddingRight: 3 }}
          >
            {t("login.signup.wrongEmail1")}
          </Typography>
          <Link
            onClick={() => {
              localStorage.removeItem("signUp");
              props.onPreviousStep();
            }}
            style={{ textDecoration: "none", cursor: "pointer" }}
          >
            <Typography variant="formlink" style={{ display: "inline" }}>
              {t("login.signup.wrongEmail2")}
            </Typography>
          </Link>
        </>
        <div id="fields">
          <div id="username">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              {verificationCode.map((value, index) => (
                <TextField
                  key={index}
                  disabled={isLoading}
                  id={`code-input-${index}`}
                  variant="outlined"
                  value={value}
                  onPaste={handlePaste}
                  onChange={handleInputChange(index)}
                  onKeyDown={handleKeyDown(index)}
                  inputProps={{
                    maxLength: 1,
                    style: {
                      textAlign: "center",
                      height: "50px",
                      fontSize: "48px",
                      fontWeight: 700,
                      lineHeight: "50px",
                    },
                  }}
                  autoComplete="off"
                  autoFocus={index === 0}
                  required
                  style={{
                    width: "70px",
                    height: "70px",
                    borderRadius: "8px",
                  }}
                />
              ))}
            </Box>
            <div style={{ marginTop: "16px" }}>
              {resendCompleted ? (
                <>
                  <Typography variant="formlinkdescription">
                    {t("login.signup.resendComplete")}
                  </Typography>
                  <Link
                    onClick={() => {
                      window.Intercom("show");
                    }}
                    style={{ textDecoration: "none", cursor: "pointer" }}
                  >
                    <Typography
                      variant="formlink"
                      style={{ display: "inline" }}
                    >
                      {t("login.signup.resendCompleteSupport")}
                    </Typography>
                  </Link>
                </>
              ) : (
                <>
                  <Typography
                    variant="formlinkdescription"
                    style={{ display: "inline", paddingRight: 3 }}
                  >
                    {t("login.signup.noEmail1")}
                  </Typography>
                  <Link
                    onClick={resendEmail}
                    style={{ textDecoration: "none", cursor: "pointer" }}
                  >
                    <Typography
                      variant="formlink"
                      style={{ display: "inline" }}
                    >
                      {t("login.signup.noEmail2")}
                    </Typography>
                  </Link>
                </>
              )}
            </div>
          </div>
          {!props.password && (
            <div id="password">
              <div
                style={{
                  marginBottom: "4px",
                  marginTop: "16px",
                }}
              >
                <Typography variant="formlabel">
                  {t("login.password")}
                </Typography>
              </div>
              <Tooltip
                title={t("login.signup.passwordPolicy") || ""}
                arrow
                open={password!.length < 12 && password!.length > 0}
                placement={"right"}
              >
                <TextField
                  id="password-input"
                  variant="outlined"
                  type="password"
                  placeholder={t("login.passwordPlaceholder")}
                  fullWidth
                  value={password}
                  onChange={(event) => {
                    setPassword(event.target.value);
                  }}
                  autoComplete="current-password"
                  disabled={isLoading}
                  required
                />
              </Tooltip>
              <Typography variant="formlinkdescription">
                {t("login.signup.passwordRefresh")}
              </Typography>
            </div>
          )}
        </div>
        {error && (
          <div id={"errorMessage"}>
            <Alert severity="error">{t(error)}</Alert>
          </div>
        )}
        <div className="sendButton">
          <Button
            variant={"contained"}
            type={"submit"}
            color={"primary"}
            disableElevation
            size="large"
            style={{ width: "100%" }}
            disabled={
              isLoading ||
              verificationCode.some((code) => code === "") ||
              password === undefined ||
              password!.length < 12
            }
          >
            {isLoading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              t("login.signup.continue")
            )}
          </Button>
        </div>
      </form>
    </>
  );
};

export default ConfirmSignUp;
