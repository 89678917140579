import React from "react";
import SettingsSection from "../../../Settings/SettingsSection";
import { XfaSwitch } from "../../../General/XfaSwitch";
import { useTranslation } from "react-i18next";

export interface SettingOption {
  title: string;
  description: string;
  allowEnabled: boolean;
  onAllowChange: (checked: boolean) => void;
  warningEnabled: boolean;
  onWarningChange: (checked: boolean) => void;
  warnPeriod?: number;
  onWarnPeriodChange?: (value: number) => void;
}

interface AdditionalSettingsProps {
  settings: SettingOption[];
}

const WarnSettings: React.FC<{
  isEnabled: boolean;
  onEnableChange: (checked: boolean) => void;
  warningEnabled: boolean;
  onWarningChange: (checked: boolean) => void;
  warnPeriod?: number;
  onWarningPeriodChange?: (value: number) => void;
}> = ({
  isEnabled,
  onEnableChange,
  warningEnabled,
  onWarningChange,
  warnPeriod,
  onWarningPeriodChange,
}) => {
  const { t } = useTranslation();
  return (
    <div className="warn-person-section">
      <div
        className="warn-line"
        onClick={() => {
          onWarningChange(!warningEnabled);
        }}
      >
        <input
          type="checkbox"
          style={{ accentColor: "var(--color-gray-900)" }}
          checked={isEnabled && warningEnabled}
          disabled={!isEnabled}
          onChange={(e) => {
            if (e.target.checked) {
              onEnableChange(e.target.checked);
            }
            onWarningChange(e.target.checked);
          }}
        />
        <span
          className={`warn-text warn-text-bold ${isEnabled ? "" : "disabled"} `}
        >
          {t("policies.editPolicy.warn")}
        </span>
        {warnPeriod !== undefined && onWarningPeriodChange !== undefined ? (
          <>
            <span className={`warn-text ${isEnabled ? "" : "disabled"} `}>
              {t("policies.editPolicy.warnPerson")}
            </span>
            <input
              type="number"
              value={warnPeriod}
              className="warn-input"
              min={0}
              disabled={!isEnabled}
              onChange={(e) => {
                onWarningPeriodChange(parseInt(e.target.value) || 0);
              }}
            />
            <span
              className={`warn-text warn-text-bold ${isEnabled ? "" : "disabled"} `}
            >
              {t("policies.editPolicy.days")}
            </span>
          </>
        ) : (
          <span className={`warn-text ${isEnabled ? "" : "disabled"} `}>
            {t("policies.editPolicy.person")}
          </span>
        )}
      </div>
    </div>
  );
};

const AdditionalSettings: React.FC<AdditionalSettingsProps> = ({
  settings,
}) => {
  return (
    <div className="additional-settings">
      {settings.map((setting, index) => (
        <SettingsSection
          key={index}
          title={setting.title}
          description={setting.description}
          withoutDivider={index === settings.length - 1}
          mobileSupport={true}
        >
          <div className="additional-settings-content">
            <div>
              <XfaSwitch
                checked={setting.allowEnabled}
                onChange={(e) => setting.onAllowChange(e.target.checked)}
                title={`${setting.title}`}
              />
              <span
                className="additional-settings-label"
                onClick={() => {
                  setting.onAllowChange(!setting.allowEnabled);
                }}
              >
                {`${setting.title}`}
              </span>
            </div>
            <WarnSettings
              isEnabled={setting.allowEnabled}
              onEnableChange={setting.onAllowChange}
              warnPeriod={setting.warnPeriod}
              onWarningPeriodChange={setting.onWarnPeriodChange}
              warningEnabled={setting.warningEnabled}
              onWarningChange={setting.onWarningChange}
            />
          </div>
        </SettingsSection>
      ))}
    </div>
  );
};

export default AdditionalSettings;
