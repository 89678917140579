import NavigationService from "../../utils/NavigationService";
import { setCsrfToken } from "./Csrf";

export const getCodeFromThoropass = (organizationId: string) => {
  /*
		- User should be asked which organization to connect (or create a new one, or login to another account)
		- After decision User should be redirected to the authorization endpoint of Thoropass with the following query parameters:
				- client_id: (of our integration).
				- code_challenge: random string (to be saved and compared later) -> CSRF protection. This should be stored in a cookie on the xfa.tech domain.
        - code_challenge_method: plain
				- redirect_uri: See RedirectURL below, back to dashboard.
    */

  // generate csrf token
  const csrfToken = setCsrfToken();

  // parse params
  const thoropassClientId = process.env.REACT_APP_THOROPASS_CLIENT_ID;
  if (!thoropassClientId) {
    throw new Error("REACT_APP_THOROPASS_CLIENT_ID is not set");
  }
  const redirectUri = getThoropassRedirectUri(organizationId);

  // redirect to thoropass
  const thoropassUrl = new URL("https://app.thoropass.com/partner-api");
  thoropassUrl.searchParams.append("client_id", thoropassClientId);
  thoropassUrl.searchParams.append("code_challenge", csrfToken);
  thoropassUrl.searchParams.append("code_challenge_method", "plain");
  thoropassUrl.searchParams.append("redirect_uri", redirectUri.toString());
  thoropassUrl.searchParams.append("response_type", "code");
  NavigationService.navigateToExternal(thoropassUrl.toString());
};

export const getThoropassRedirectUri = (organizationId: string) => {
  const redirectUri = new URL(window.location.href);
  redirectUri.search = "";
  redirectUri.pathname = "/connect/thoropass";
  return redirectUri.toString();
};
