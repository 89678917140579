import React from "react";
import "./ApplicationCard.css";
import { Button, Tooltip } from "@mui/material";
import BooleanSetting from "./NewOrEditPage/BooleanSetting";
import deleteIcon from "../../images/delete-icon.svg";
import manageIcon from "../../images/manage-icon.svg";
import warningIcon from "../../images/warning-icon.png";
import { useTranslation } from "react-i18next";

interface ApplicationCardProps {
  enabled: boolean | undefined;
  default: boolean;
  title: string;
  logo?: string;
  description: string;
  skipped: boolean;
  filtered: boolean;
  showStatus: boolean;
  setEnabled: ((enabled: boolean) => void) | undefined;
  onManage: () => void;
  onDelete: (() => void) | undefined;
  disabled?: boolean;
  disabledTooltip?: string;
}

const ApplicationCard: React.FC<ApplicationCardProps> = (
  props: ApplicationCardProps,
) => {
  const { t } = useTranslation();

  return (
    <div className={`application-card`}>
      <div>
        <div className="application-card-header">
          <div style={{ opacity: props.enabled !== false ? 1 : 0.5 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div className="application-card-title">{props.title}</div>
              {props.logo && (
                <img
                  src={props.logo}
                  alt="logo"
                  className="application-card-logo"
                />
              )}
            </div>
            <div className="application-card-subtitle">
              {props.default ? t("applications.default") : props.description}
            </div>
          </div>
          {props.setEnabled && (
            <div className="enableButton">
              {props.disabledTooltip ? (
                <Tooltip title={props.disabledTooltip} arrow>
                  <div>
                    <BooleanSetting
                      label={""}
                      explanation={""}
                      disabled={props.disabled}
                      value={props.enabled === undefined ? true : props.enabled}
                      onChange={(value) => props.setEnabled!(value)}
                    />
                  </div>
                </Tooltip>
              ) : (
                <BooleanSetting
                  label={""}
                  explanation={""}
                  disabled={props.disabled}
                  value={props.enabled === undefined ? true : props.enabled}
                  onChange={(value) => props.setEnabled!(value)}
                />
              )}
            </div>
          )}
        </div>
      </div>
      {props.showStatus && (
        <div className="application-card-status">
          {props.skipped && (
            <div className="application-card-status-single">
              <img
                src={warningIcon}
                alt="warning"
                className="application-card-logo"
              />
              <span>{t("applications.skipped")}</span>
            </div>
          )}
          {props.filtered && (
            <div className="application-card-status-single">
              <img
                src={warningIcon}
                alt="warning"
                className="application-card-logo"
              />
              <span>{t("applications.filtered")}</span>
            </div>
          )}
        </div>
      )}
      <div className="application-card-description">
        {props.onDelete ? (
          <Button
            size="small"
            style={{
              minWidth: "min-content",
              opacity: props.default ? 0.5 : 1,
            }}
            onClick={props.onDelete}
            disabled={props.default}
          >
            <img src={deleteIcon} alt="deleteIcon" className="button-icon" />
          </Button>
        ) : (
          <div></div>
        )}
        <Button
          startIcon={
            <img src={manageIcon} className="button-icon" alt="manageIcon" />
          }
          onClick={props.onManage}
        >
          {t("applications.manage")}
        </Button>
      </div>
    </div>
  );
};

export default ApplicationCard;
