import React from "react";
import {
  Alert,
  CircularProgress,
  FormControl,
  Typography,
  TextField,
  Collapse,
  Autocomplete,
} from "@mui/material";
import XFA_API, {
  Application,
  GoogleGroup,
  MicrosoftGroup,
} from "../../API/XFA_API";

import { useTranslation } from "react-i18next";
import { XfaSwitch } from "../../General/XfaSwitch";

export const GroupsSelector: React.FC<{
  application: Application;
  setApplication: (application: Application) => void;
}> = (props) => {
  const [groupsOptions, setGroupsOptions] = React.useState<
    MicrosoftGroup[] | GoogleGroup[] | undefined
  >(undefined);
  const [filteredGroups, setFilteredGroups] = React.useState<
    MicrosoftGroup[] | GoogleGroup[] | undefined
  >(undefined);
  const [error, setError] = React.useState<string | undefined>();
  const [value, setValue] = React.useState<string[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    let newValue: string[] = [];
    if (props.application.MicrosoftConnection?.OnlyGroups) {
      const sortedGroups =
        props.application.MicrosoftConnection.OnlyGroups.sort((a, b) =>
          a.Name.localeCompare(b.Name),
        );
      if (!groupsOptions) {
        setGroupsOptions(sortedGroups);
        setFilteredGroups(sortedGroups);
      }
      newValue = sortedGroups.map((group) => group.ID);
    }
    if (props.application.GoogleConnection?.OnlyGroups) {
      const sortedGroups = props.application.GoogleConnection.OnlyGroups.sort(
        (a, b) => a.Name.localeCompare(b.Name),
      );
      if (!groupsOptions) {
        setGroupsOptions(sortedGroups);
        setFilteredGroups(sortedGroups);
      }
      newValue = sortedGroups.map((group) => group.ID);
    }
    setValue(newValue);
  }, [props.application, groupsOptions]);

  const handleSelectionChange = (event: any, newValue: any) => {
    const selectedIds = newValue.map((option: any) => option.ID);
    setValue(selectedIds);
    const application = { ...props.application };
    if (application.MicrosoftConnection) {
      application.MicrosoftConnection.OnlyGroups = groupsOptions?.filter(
        (group) => selectedIds.includes(group.ID),
      );
    }
    if (application.GoogleConnection) {
      application.GoogleConnection.OnlyGroups = groupsOptions?.filter((group) =>
        selectedIds.includes(group.ID),
      );
    }
    props.setApplication(application);
  };

  const { t } = useTranslation();

  const [enabled, setEnabled] = React.useState<boolean>(
    !!(
      (props.application.MicrosoftConnection?.OnlyGroups?.length &&
        props.application.MicrosoftConnection.OnlyGroups.length > 0) ||
      (props.application.GoogleConnection?.OnlyGroups?.length &&
        props.application.GoogleConnection.OnlyGroups.length > 0)
    ),
  );

  return (
    <FormControl fullWidth={true} size="small">
      <div style={{ display: "flex", width: "100%" }}>
        <div style={{ width: "100%" }}>
          <Typography variant="setting">
            {t("applications.microsoft.groups")}
          </Typography>
          <Typography variant="instructions">
            {t("applications.microsoft.groupsDescription")}
          </Typography>
        </div>
        <div>
          <XfaSwitch
            checked={enabled}
            disableRipple
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setEnabled(event.target.checked);
              if (!event.target.checked) {
                const application = { ...props.application };
                if (application.MicrosoftConnection) {
                  application.MicrosoftConnection.OnlyGroups = [];
                }
                if (application.GoogleConnection) {
                  application.GoogleConnection.OnlyGroups = [];
                }
                props.setApplication(application);
              }
            }}
          />
        </div>
      </div>
      <div style={{ marginTop: 8 }}></div>
      <Collapse in={enabled}>
        <FormControl fullWidth={true} size="small">
          <Autocomplete
            multiple
            options={filteredGroups || []}
            getOptionLabel={(option) => option.Name}
            value={
              groupsOptions?.filter((group) => value.includes(group.ID)) || []
            }
            onChange={handleSelectionChange}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={t("search")}
                variant="outlined"
                size="small"
              />
            )}
            loading={loading}
            onOpen={() => {
              setLoading(true);
              XFA_API.getGroupsDiscoveryIntegration(
                props.application.OrganizationID,
                props.application.ApplicationID,
              )
                .then((response) => {
                  const sortedResponse = response.sort(
                    (a: { Name: string }, b: { Name: any }) =>
                      a.Name.localeCompare(b.Name),
                  );
                  setGroupsOptions(sortedResponse);
                  setFilteredGroups(sortedResponse);
                  setLoading(false);
                })
                .catch((error) => {
                  setError(error.message);
                  setLoading(false);
                });
            }}
          />
          {error && <Alert severity="error">{error}</Alert>}
        </FormControl>
      </Collapse>
    </FormControl>
  );
};

export default GroupsSelector;
