import { useState, useEffect, useCallback } from "react";
import XFA_API, { Application, Role } from "../../API/XFA_API";

export const useApplications = (role: Role) => {
  const [applications, setApplications] = useState<Application[]>([]);
  const [dashboardApplicationId, setDashboardApplicationId] = useState<
    string | undefined
  >(undefined);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchApplications = useCallback(async () => {
    try {
      setLoading(true);
      const result = await XFA_API.getApplications(
        role.organization.organization_id,
      );
      setApplications(result);
      const dashboardApplication = result.find(
        (app) => app.UniquePurpose === "DashboardDemo",
      );
      if (dashboardApplication) {
        const dashboardApplicationId = dashboardApplication.ApplicationID;
        if (dashboardApplicationId !== undefined) {
          setDashboardApplicationId(dashboardApplicationId);
        }
      }
      setError(null);
    } catch (err: any) {
      setError(err.message || "Error fetching applications");
    } finally {
      setLoading(false);
    }
  }, [role.organization.organization_id]);

  useEffect(() => {
    if (role.organization.organization_id !== undefined) {
      fetchApplications();
    }
  }, [fetchApplications, role.organization.organization_id]);

  return {
    applications,
    dashboardApplicationId,
    loading,
    error,
    refresh: fetchApplications,
  };
};
