import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  FormControl,
  TextField,
  FormControlLabel,
  Checkbox,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
  Typography,
} from "@mui/material";
import { SAMLConfiguration } from "../../API/XFA_API";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

interface IDPPageProps {
  creating: boolean;
  samlConfig?: SAMLConfiguration;
  setSAMLConfig: (samlConfig: SAMLConfiguration) => void;
}

const IDPPage: React.FC<IDPPageProps> = (props: IDPPageProps) => {
  const { t } = useTranslation();
  const [creatingOrUpdating, setCreatingOrUpdating] = useState(false);
  const [advancedOpen, setAdvancedOpen] = useState(false);
  const [copiedACSLabel, setCopiedACSLabel] = useState(false);

  const acsUrlForIdP = "https://device-api.xfa.tech/saml2/consume";

  return (
    <div className="section">
      <div style={{ marginBottom: 16 }}>
        <Typography variant="formsectiontitle">
          {t("applications.createDialog.idp")}
        </Typography>
        <Typography variant="instructions">
          {t("applications.createDialog.idpContent")}
        </Typography>
      </div>
      <div>
        <FormControl fullWidth={true}>
          <Typography variant="formlabel">
            {t("applications.createDialog.idpUrl")}
          </Typography>
          <TextField
            required
            id="name"
            value={props.samlConfig?.IdentityProviderSSOURL}
            placeholder={t("applications.createDialog.idpUrlPlaceholder")}
            onChange={(event) => {
              props.setSAMLConfig({
                ...(props.samlConfig || ({} as SAMLConfiguration)),
                IdentityProviderSSOURL: event.target.value,
              });
            }}
          />
          <div style={{ marginBottom: 8 }} />
          <Typography variant="formlabel">
            {t("applications.createDialog.entityId")}
          </Typography>
          <TextField
            required
            id="name"
            value={props.samlConfig?.IdentityProviderIssuer}
            placeholder={t("applications.createDialog.entityIdPlaceholder")}
            onChange={(event) => {
              props.setSAMLConfig({
                ...(props.samlConfig || ({} as SAMLConfiguration)),
                IdentityProviderIssuer: event.target.value,
              });
            }}
          />
          <div style={{ marginBottom: 8 }} />
          <Typography variant="formlabel">
            {t("applications.createDialog.certificate")}
          </Typography>
          <TextField
            required
            multiline
            rows={6}
            id="name"
            value={props.samlConfig?.IdentityProviderPublicCertificate}
            placeholder={t("applications.createDialog.certificatePlaceholder")}
            onChange={(event) => {
              props.setSAMLConfig({
                ...(props.samlConfig || ({} as SAMLConfiguration)),
                IdentityProviderPublicCertificate: event.target.value,
              });
            }}
          />
          <div style={{ marginBottom: 16 }} />
          <Typography variant="formlabel">
            {t("applications.createDialog.XFAACSURL")}
          </Typography>
          <FormControl style={{ flexDirection: "row", alignItems: "center" }}>
            <TextField
              required
              rows={6}
              id="name"
              value={acsUrlForIdP}
              InputProps={{
                readOnly: true,
              }}
              variant="filled"
              fullWidth={true}
            />
            <Tooltip
              title={
                copiedACSLabel
                  ? t("applications.createDialog.copied")
                  : t("applications.createDialog.clickToCopy")
              }
              arrow
              placement={"top"}
              onClose={() => {
                setCopiedACSLabel(false);
              }}
            >
              <Button
                style={{
                  height: "min-content",
                  margin: 5,
                  padding: 10,
                  minWidth: 0,
                }}
                onClick={async () => {
                  await navigator.clipboard.writeText(acsUrlForIdP);
                  setCopiedACSLabel(true);
                }}
              >
                <ContentCopyIcon fontSize="small" />
              </Button>
            </Tooltip>
          </FormControl>
          <div style={{ marginBottom: 16 }} />
          {props.creating || (
            <Accordion
              expanded={advancedOpen}
              disableGutters={true}
              elevation={0}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                onClick={() => setAdvancedOpen(!advancedOpen)}
                style={{ width: "fit-content", padding: 0 }}
              >
                <Typography variant="formlabel">
                  {t("applications.createDialog.AdvancedOptions")}
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                style={{
                  paddingTop: 0,
                  width: "fit-content",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <FormControlLabel
                  label={
                    <Typography variant="formlabel">
                      {t("applications.createDialog.PreventPassiveLogin")}
                    </Typography>
                  }
                  control={
                    <Checkbox
                      checked={props.samlConfig?.PreventPassiveLogin}
                      disabled={creatingOrUpdating}
                      onChange={(event) => {
                        props.setSAMLConfig({
                          ...(props.samlConfig || ({} as SAMLConfiguration)),
                          PreventPassiveLogin: event.target.checked,
                        });
                      }}
                    />
                  }
                />
                <FormControlLabel
                  label={
                    <Typography variant="formlabel">
                      {t("applications.createDialog.ForceReauthentication")}
                    </Typography>
                  }
                  control={
                    <Checkbox
                      checked={props.samlConfig?.ForceReauthentication}
                      disabled={creatingOrUpdating}
                      onChange={(event) => {
                        props.setSAMLConfig({
                          ...(props.samlConfig || ({} as SAMLConfiguration)),
                          ForceReauthentication: event.target.checked,
                        });
                      }}
                    />
                  }
                />
                <FormControlLabel
                  label={
                    <Typography variant="formlabel">
                      {t("applications.createDialog.GoogleForceAccountChooser")}
                    </Typography>
                  }
                  control={
                    <Checkbox
                      checked={props.samlConfig?.GoogleForceAccountChooser}
                      disabled={creatingOrUpdating}
                      onChange={(event) => {
                        props.setSAMLConfig({
                          ...(props.samlConfig || ({} as SAMLConfiguration)),
                          GoogleForceAccountChooser: event.target.checked,
                        });
                      }}
                    />
                  }
                />
                <FormControlLabel
                  label={
                    <Typography variant="formlabel">
                      {t("applications.createDialog.AzureForceAccountChooser")}
                    </Typography>
                  }
                  control={
                    <Checkbox
                      checked={props.samlConfig?.AzureForceAccountChooser}
                      disabled={creatingOrUpdating}
                      onChange={(event) => {
                        props.setSAMLConfig({
                          ...(props.samlConfig || ({} as SAMLConfiguration)),
                          AzureForceAccountChooser: event.target.checked,
                        });
                      }}
                    />
                  }
                />
              </AccordionDetails>
            </Accordion>
          )}
        </FormControl>
      </div>
    </div>
  );
};

export default IDPPage;
