import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, Divider, RadioGroup } from "@mui/material";
import "./DiscoveryOnboarding.css";
import DiscoveryCard from "./DiscoveryCard";
import logo from "../../images/XFA_woordmerk_gray900.svg";
import oktaIcon from "../../images/okta-icon.png";
import idpIcon from "../../images/idp-icon.png";
import { Role } from "../API/XFA_API";

interface DiscoveryOnboardingProps {
  onOtherIdentificationMethod?: () => void;
  onGoogleDiscovery?: () => void;
  onMicrosoftDiscovery?: () => void;
  onOktaDiscovery?: () => void;
  onNoSetup?: () => void;
  setSelectedOption?: (value: string) => void;
  emptyState?: boolean;
  role?: Role;
}

const DiscoveryOnboarding = (props: DiscoveryOnboardingProps) => {
  const { t } = useTranslation();
  const [selectedOption, setSelectedOption] = useState("");
  const [easySetupEnabled, setEasySetupEnabled] = useState(false);

  const handleOptionChange = (value: string) => {
    if (props.setSelectedOption) {
      props.setSelectedOption(value);
    }
    setSelectedOption(value);
  };

  const handleEasySetupChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setEasySetupEnabled(event.target.checked);
  };

  return (
    <div>
      {!props.emptyState && (
        <div className="discovery-logo-header">
          <img alt="logo" src={logo} style={{ height: 26 }} />
        </div>
      )}
      <Box
        className={
          props.emptyState
            ? "discovery-container-empty-state "
            : "discovery-container"
        }
      >
        <Box className="discovery-header">
          <div className="discovery-title">{t("login.discovery.title")}</div>
          <div className="discovery-description">
            {t("login.discovery.description")}
          </div>
          <div className="discovery-subdescription">
            {t("login.discovery.subdescription")}
          </div>
        </Box>
        <RadioGroup
          value={selectedOption}
          onChange={() => {}}
          className="discovery-radio-group"
        >
          <DiscoveryCard
            value="microsoft"
            selected={selectedOption === "microsoft"}
            logoSrc="https://cdn-icons-png.flaticon.com/512/732/732221.png"
            title={t("login.discovery.microsoft.title")}
            description={t("login.discovery.microsoft.description")}
            additionalInfo={t("login.discovery.microsoft.additionalInfo")}
            additionalInfo2={t("login.discovery.microsoft.additionalInfo2")}
            onSelect={handleOptionChange}
          />

          <DiscoveryCard
            value="google"
            selected={selectedOption === "google"}
            logoSrc="https://cdn-icons-png.flaticon.com/512/281/281764.png"
            title={t("login.discovery.google.title")}
            description={t("login.discovery.google.description")}
            onSelect={handleOptionChange}
            onHandleEasySetupChange={handleEasySetupChange}
          />

          <DiscoveryCard
            value="okta"
            selected={selectedOption === "okta"}
            logoSrc={oktaIcon}
            title={t("login.discovery.okta.title")}
            description={t("login.discovery.okta.description")}
            onSelect={handleOptionChange}
            onHandleEasySetupChange={handleEasySetupChange}
          />
        </RadioGroup>
        <Divider />
        <div
          className="identification-method"
          onClick={() =>
            props.onOtherIdentificationMethod &&
            props.onOtherIdentificationMethod()
          }
        >
          <div className="identification-method-icon">
            <img
              src={idpIcon}
              alt="Icon"
              className="identification-method-icon-image"
            />
          </div>
          <div className="identification-method-text-content">
            <div className="identification-method-title">
              {t("login.discovery.otherMethod.title")}
            </div>
            <div className="identification-method-subtitle">
              {t("login.discovery.otherMethod.subtitle")}
            </div>
          </div>
          <div className="identification-method-arrow">
            <span>&#8250;</span>
          </div>
        </div>
      </Box>
      {!props.emptyState && (
        <Box className="discovery-footer">
          <div className="discovery-footer-buttons">
            <Button
              variant="text"
              onClick={() => {
                props.onNoSetup && props.onNoSetup();
              }}
            >
              {t("login.discovery.buttons.continueWithoutSetup")}
            </Button>

            <Button
              variant="contained"
              color="primary"
              disabled={
                !(
                  selectedOption === "google" ||
                  selectedOption === "microsoft" ||
                  selectedOption === "okta"
                )
              }
              onClick={() => {
                if (selectedOption === "google") {
                  if (props.onGoogleDiscovery) {
                    props.onGoogleDiscovery();
                  }
                } else if (selectedOption === "microsoft") {
                  if (props.onMicrosoftDiscovery) {
                    props.onMicrosoftDiscovery();
                  }
                } else if (selectedOption === "okta") {
                  if (props.onOktaDiscovery) {
                    props.onOktaDiscovery();
                  }
                }
              }}
            >
              {t("login.discovery.buttons.connectNow")}
            </Button>
          </div>
        </Box>
      )}
    </div>
  );
};

export default DiscoveryOnboarding;
