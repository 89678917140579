import React from "react";
import { Breadcrumbs as MuiBreadcrumbs, Link } from "@mui/material";
import HomeIcon from "../../images/home-breadcrumb.png";
import "./BreadCrumbs.css";
import NavigationService from "../../utils/NavigationService";

interface BreadcrumbLink {
  label: string;
  href?: string;
}

interface BreadcrumbsProps {
  links: BreadcrumbLink[];
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ links }) => {
  const handleClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    href?: string,
  ) => {
    event.preventDefault();
    if (href) {
      NavigationService.navigateTo(href);
    }
  };

  return (
    <div className="breadcrumbs">
      <MuiBreadcrumbs separator={"/"} aria-label="breadcrumb">
        <Link
          color="inherit"
          href="/"
          onClick={(event) => handleClick(event, "/")}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <img
            src={HomeIcon}
            height={"20px"}
            alt="Completed"
            className="home-icon"
          />
        </Link>
        {links.map((link, index) =>
          link.href ? (
            <Link
              key={index}
              className="link"
              href={link.href}
              onClick={(event) => handleClick(event, link.href)}
            >
              {link.label}
            </Link>
          ) : (
            <div key={index} className="link">
              {link.label}
            </div>
          ),
        )}
      </MuiBreadcrumbs>
    </div>
  );
};

export default Breadcrumbs;
