import React, { useEffect, useState } from "react";
import "./OrganizationTab.css";
import { Alert, Divider } from "@mui/material";
import SettingsSection from "./SettingsSection";
import FileUpload from "../General/FileUpload";
import XFA_API, { Organization } from "../API/XFA_API";
import { useTranslation } from "react-i18next";
import { useToast } from "../General/ToastMessage/ToastProvider";

export interface OrganizationTabProps {
  organizationID: string;
}

const OrganizationTab: React.FC<OrganizationTabProps> = ({
  organizationID,
}) => {
  const { t } = useTranslation();
  const { addToast } = useToast();
  const allowedLogoFormats = [
    "image/svg+xml",
    "image/png",
    "image/jpeg",
    "image/gif",
  ];
  const [organization, setOrganization] = useState<Organization | undefined>(
    undefined,
  );

  const [loading, setLoading] = React.useState<boolean>(false);
  const [localError, setLocalError] = React.useState<string | undefined>();
  const [hasChanged, setHasChanged] = useState<boolean>(false);

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!organization) return;
    setHasChanged(true);

    const updatedOrganization = { ...organization, name: e.target.value };

    setOrganization(updatedOrganization);
  };

  const handleFileSelect = (file: File) => {
    setLocalError(undefined);
    const reader = new FileReader();
    reader.onloadend = () => {
      if (organization) {
        reader.result;
        const updatedOrganization = {
          ...organization,
          logo_url: reader.result as string,
        };
        setOrganization(updatedOrganization);
        setHasChanged(true);
      }
    };

    const fileSizeInKB = file.size / 1024;
    if (fileSizeInKB > 350) {
      const errorMessageSize = t("settings.organization.errorMessageSize");
      setLocalError(errorMessageSize);
    } else if (!allowedLogoFormats.includes(file.type)) {
      const errorMessageType = t("settings.organization.errorMessageType");
      setLocalError(errorMessageType);
    } else {
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    const autoSaveSettings = async () => {
      setLoading(true);
      if (organization) {
        try {
          await XFA_API.updateOrganization(organizationID, organization);
          addToast({
            isSaved: true,
          });
        } catch (error: any) {
          addToast({
            isSaved: false,
            message: t("settings.organization.failMessage"),
            title: t("settings.organization.fail"),
          });
          getOrganization();
        } finally {
          setLoading(false);
          setHasChanged(false);
        }
      }
    };

    const debounceTimeout = setTimeout(() => {
      if (hasChanged) {
        autoSaveSettings();
      }
    }, 1000);

    return () => clearTimeout(debounceTimeout);
  }, [organization]);

  const getOrganization = () => {
    setLoading(true);
    XFA_API.getOrganizationFull(organizationID).then((organization) => {
      if (!organization) {
        setLoading(false);
        setHasChanged(false);
        return;
      }
      setOrganization(organization);
      setLoading(false);
    });
  };

  useEffect(() => {
    getOrganization();
  }, []);

  return (
    <div className="container">
      <div className="organization-section">
        <div className="organization-section-container">
          <div className="organization-title-section">
            <div className="inline-settings-header">
              {t("settings.organization.title")}
            </div>
            <div className="inline-settings-description">
              {t("settings.organization.description")}
            </div>
          </div>
        </div>
        <Divider />
        <SettingsSection
          title={t("settings.organization.name")}
          description={t("settings.organization.nameDescription")}
        >
          <input
            type="text"
            placeholder={t("settings.organization.namePlaceholder")}
            value={organization?.name ?? ""}
            onChange={handleNameChange}
            className="settings-input"
          />
        </SettingsSection>
        <SettingsSection
          title={t("settings.organization.logo")}
          description={t("settings.organization.logoDescription")}
        >
          <div className="logo-upload-section">
            {organization?.logo_url && (
              <img
                src={organization?.logo_url}
                alt="Company Logo"
                className="uploaded-logo"
              />
            )}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
              }}
            >
              <FileUpload onFileSelect={handleFileSelect} t={t} />

              {localError && <Alert severity="error">{localError}</Alert>}
            </div>
          </div>
        </SettingsSection>
      </div>
    </div>
  );
};

export default OrganizationTab;
