import { use } from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import languageEN from "./locale/en/translate.json";
import languageNL from "./locale/nl/translate.json";

const I18n = use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: languageEN,
      nl: languageNL,
    },
    fallbackLng: "en",
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    pluralSeparator: "_",
  });

export default I18n;
