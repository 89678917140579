import React from "react";
import { useTranslation } from "react-i18next";
import { Chip, FormControl, Typography } from "@mui/material";
import {
  Application,
  ConnectionError,
  MicrosoftConnection,
} from "../../../API/XFA_API";
import Button from "@mui/material/Button";
import warningIcon from "../../../../images/warning-icon.png";
import "./MicrosoftPage.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faCircleExclamation,
} from "@fortawesome/free-solid-svg-icons";

import BooleanSetting from "../BooleanSetting";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import { GroupsSelector } from "../GroupsSelector";
import { askForMicrosoftPermissions } from "../../../../utils/DiscoveryService";
import { useLocation } from "react-router-dom";

interface MicrosoftPageProps {
  creating: boolean;
  application: Application;
  setApplication: (application: Application) => void;
  vertical: boolean;
  connectionError?: ConnectionError;
}

const MicrosoftPage: React.FC<MicrosoftPageProps> = (
  props: MicrosoftPageProps,
) => {
  const { t } = useTranslation();
  const location = useLocation();

  const [connectionError, setConnectionError] = React.useState<
    ConnectionError | undefined
  >(location.state?.connectionError || props.connectionError);

  const getMicrosoftConnection = React.useCallback(
    (application: Application) => {
      if (!application.MicrosoftConnection) {
        return "disconnected";
      }
      if (application.MicrosoftConnection.Error) {
        return "error";
      }
      return "connected";
    },
    [],
  );

  const [microsoftConnection, setMicrosoftConnection] = React.useState<
    "connected" | "disconnected" | "error"
  >(getMicrosoftConnection(props.application));

  React.useEffect(() => {
    setMicrosoftConnection(getMicrosoftConnection(props.application));
  }, [props.application, getMicrosoftConnection]);

  return (
    <div>
      <div
        className={
          props.vertical ? "verticalSelectionSection" : "selectionSection"
        }
      >
        <div style={{ marginBottom: 16 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="formsectiontitle">
              {t("applications.microsoft.title")}
            </Typography>
            <Chip size="small" label={"Beta"} color="primary" />
          </div>
          <Typography variant="instructions">
            {t("applications.microsoft.content")}
          </Typography>
        </div>
        <div>
          <FormControl fullWidth={true}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div className="connection-status" style={{ width: "100%" }}>
                {microsoftConnection === "connected" && (
                  <span style={{ color: "var(--color-green)" }}>
                    <FontAwesomeIcon
                      icon={faCircleCheck}
                      className="application-card-logo"
                    />

                    <span>{t("applications.microsoft.connected")}</span>
                  </span>
                )}
                {microsoftConnection === "disconnected" && (
                  <span
                    style={{
                      color: "var(--color-yellow)",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={warningIcon}
                      alt="warning"
                      className="application-card-logo"
                    />
                    <span>{t("applications.microsoft.disconnected")}</span>
                  </span>
                )}
                {microsoftConnection === "error" && (
                  <span
                    style={{
                      color: "var(--color-red)",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faCircleExclamation}
                      className="application-card-logo"
                    />
                    <span>{t("applications.microsoft.error")}</span>
                  </span>
                )}
              </div>
              <Button
                size="small"
                variant="outlined"
                onClick={() => {
                  askForMicrosoftPermissions(props.application.ApplicationID);
                }}
              >
                {microsoftConnection === "connected"
                  ? t("applications.microsoft.reconnect")
                  : t("applications.microsoft.connect")}
              </Button>
            </div>
            <Typography
              variant="caption"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginTop: 24,
                marginBottom: 8,
              }}
            >
              <InfoOutlinedIcon
                style={{
                  fontSize: 14,
                  marginRight: 8,
                  marginLeft: 8,
                }}
              />
              <div>{t("applications.microsoft.p1tip")}</div>
            </Typography>
            <div style={{ marginTop: 16 }}></div>
            <BooleanSetting
              label={t("applications.microsoft.excludeDesktop")}
              explanation={t(
                "applications.microsoft.excludeDesktopDescription",
              )}
              value={
                props.application.MicrosoftConnection?.ExcludeDesktop || false
              }
              onChange={(value) => {
                const application = { ...props.application };
                if (!application.MicrosoftConnection) {
                  application.MicrosoftConnection = {} as MicrosoftConnection;
                }
                application.MicrosoftConnection.ExcludeDesktop = value;
                props.setApplication(application);
              }}
            />
            <div style={{ marginTop: 16 }}></div>
            <BooleanSetting
              label={t("applications.microsoft.excludeMobile")}
              explanation={t("applications.microsoft.excludeMobileDescription")}
              value={
                props.application.MicrosoftConnection?.ExcludeMobile || false
              }
              onChange={(value) => {
                const application = { ...props.application };
                if (!application.MicrosoftConnection) {
                  application.MicrosoftConnection = {} as MicrosoftConnection;
                }
                application.MicrosoftConnection.ExcludeMobile = value;
                props.setApplication(application);
              }}
            />
            <div style={{ marginTop: 16 }}></div>
            <BooleanSetting
              label={t("applications.microsoft.onlyUnmanaged")}
              explanation={t("applications.microsoft.onlyUnmanagedDescription")}
              value={
                props.application.MicrosoftConnection?.OnlyUnmanaged || false
              }
              onChange={(value) => {
                const application = { ...props.application };
                if (!application.MicrosoftConnection) {
                  application.MicrosoftConnection = {} as MicrosoftConnection;
                }
                application.MicrosoftConnection.OnlyUnmanaged = value;
                props.setApplication(application);
              }}
            />
            <div style={{ marginTop: 16 }}></div>
            <GroupsSelector
              application={props.application}
              setApplication={props.setApplication}
            />
          </FormControl>
          {connectionError && (
            <div style={{ color: "var(--color-red)" }}>
              {connectionError.MicrosoftAPIError && (
                <span
                  className="connection-status"
                  style={{
                    color: "var(--color-red)",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faCircleExclamation}
                    className="application-card-logo"
                  />
                  <span>{connectionError.MicrosoftAPIError}</span>
                </span>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MicrosoftPage;
