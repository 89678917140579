import React from "react";
import "./OverviewEmptyState.css";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import GetStarted, { Task } from "./GetStarted";

interface OverviewEmptyStateProps {
  tasks: Task[];
  onDismiss: () => void;
  dismissable: boolean;
}

const OverviewEmptyState: React.FC<OverviewEmptyStateProps> = ({
  tasks,
  onDismiss,
  dismissable,
}) => {
  const { t } = useTranslation();

  return (
    <div className="overviewEmptyState">
      {dismissable && (
        <button className="dismiss-button" onClick={onDismiss}>
          &times;
        </button>
      )}
      <div className="overviewEmptyStateContentLeftContainer">
        <div className="overviewEmptyStateContentLeft">
          <h1 className="overviewEmptyStateHeader">
            {t("statistics.emptyState.welcomeTitle")}
          </h1>
          <p className="overviewEmptyStateDescription">
            {t("statistics.emptyState.welcomeDescription1")}
          </p>
          <h2 className="overviewEmptyStateSubheader">
            {t("statistics.emptyState.welcomeSubtitle")}
          </h2>
          <p className="overviewEmptyStateDescription">
            {t("statistics.emptyState.welcomeDescription2")}
          </p>
          <Button
            variant="contained"
            onClick={() => {
              window.open("https://docs.xfa.tech/", "_blank");
            }}
            style={{ marginLeft: "auto", marginTop: "32px" }}
          >
            {t("statistics.emptyState.documentationButton")}
          </Button>
          <Button
            variant="secondary"
            onClick={() => {
              window.open(
                "https://calendly.com/d/3y3-28y-8mk/product-demo",
                "_blank",
              );
            }}
            style={{ marginLeft: "8px", marginTop: "32px" }}
          >
            {t("statistics.emptyState.salesButton")}
          </Button>
        </div>
      </div>
      <div className="overviewEmptyStateContentRight">
        <GetStarted tasks={tasks} dismissable={false} onDismiss={() => {}} />
      </div>
    </div>
  );
};

export default OverviewEmptyState;
