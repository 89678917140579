import React from "react";
import { useTranslation } from "react-i18next";
import { FormControl, Grid, Typography } from "@mui/material";
import { Application, OAuthConfiguration, Role } from "../../../API/XFA_API";
import SelectionButton from "../SelectionButton";

import OneloginLogo from "../OneLogin/onelogin.svg";
import OktaLogo from "../Okta/okta.png";
import MicrosoftLogo from "../Microsoft/microsoft.svg";
import { trackEvent } from "../../../intercom/intercomProvider";

interface SelectionPageProps {
  role: Role;
  setPage: (page: string) => void;
  setCreating: (create: boolean) => void;
  setSp: (sp: string) => void;
  createApplication: (application: Application) => Promise<Application>;
}

const SelectionPage: React.FC<SelectionPageProps> = (
  props: SelectionPageProps,
) => {
  const { t } = useTranslation();

  return (
    <div>
      <div>
        <div className="selectionSection">
          <Typography variant="formsectiontitle">
            {t("applications.createDialog.chooseIdp")}
          </Typography>
          <div style={{ marginBottom: 16 }} />
          <FormControl>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <SelectionButton
                  title={t("applications.createDialog.microsoft")}
                  description={t(
                    "applications.createDialog.microsoftDescription",
                  )}
                  onClick={() => {
                    trackEvent("Selecting Microsoft Integration");
                    props.setSp("microsoft");
                    props.setPage("loading");
                    props
                      .createApplication({
                        Name: "Microsoft",
                        Type: "MicrosoftEAM",
                      } as Application)
                      .then(() => {
                        props.setPage("microsoft");
                      });
                  }}
                  logo={MicrosoftLogo}
                />
              </Grid>
              <Grid item xs={6}>
                <SelectionButton
                  title={t("applications.createDialog.okta")}
                  description={t("applications.createDialog.oktaDescription")}
                  onClick={async () => {
                    trackEvent("Selecting Okta Integration");
                    props.setSp("okta");
                    props.setPage("loading");
                    props
                      .createApplication({
                        Name: "Okta",
                        Type: "Okta",
                        SAMLConfiguration: {
                          FactorOnly: true,
                        },
                      } as Application)
                      .then(() => {
                        props.setPage("okta");
                      });
                  }}
                  logo={OktaLogo}
                />
              </Grid>
              <Grid item xs={6}>
                <SelectionButton
                  title={t("applications.createDialog.onelogin")}
                  description={t(
                    "applications.createDialog.oneloginDescription",
                  )}
                  onClick={async () => {
                    trackEvent("Selecting OneLogin Integration");
                    props.setSp("onelogin");
                    props.setPage("loading");
                    props
                      .createApplication({
                        Name: "OneLogin",
                        Type: "Onelogin",
                      } as Application)
                      .then(() => {
                        props.setPage("onelogin");
                      });
                  }}
                  logo={OneloginLogo}
                />
              </Grid>
            </Grid>
          </FormControl>
        </div>
        <div className="selectionSection">
          <Typography variant="formsectiontitle">
            {t("applications.createDialog.choose")}
          </Typography>
          <div style={{ marginBottom: 16 }} />
          <FormControl>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <SelectionButton
                  title={t("applications.createDialog.custom")}
                  description={t("applications.createDialog.customDescription")}
                  onClick={async () => {
                    trackEvent("Selecting Custom Integration");
                    props.setSp("custom");
                    props.setPage("loading");
                    props.createApplication({} as Application).then(() => {
                      props.setCreating(false);
                    });
                  }}
                  logo={""}
                />
              </Grid>
              <Grid item xs={6}>
                <SelectionButton
                  title={t("applications.createDialog.saml")}
                  description={t("applications.createDialog.samlDescription")}
                  onClick={async () => {
                    trackEvent("Selecting SAML Integration");
                    props.setSp("saml");
                    props.setPage("idp");
                  }}
                  logo={""}
                />
              </Grid>
              <Grid item xs={6}>
                <SelectionButton
                  title={t("applications.createDialog.oauth2")}
                  description={t("applications.createDialog.oauth2Description")}
                  onClick={async () => {
                    trackEvent("Selecting OAuth2 Integration");
                    props.setSp("oauth2");
                    props.setPage("loading");
                    props
                      .createApplication({
                        OAuthConfiguration: {
                          Enabled: true,
                        } as OAuthConfiguration,
                      } as Application)
                      .then(() => {
                        props.setCreating(false);
                      });
                  }}
                  logo={""}
                />
              </Grid>
            </Grid>
          </FormControl>
        </div>
      </div>
    </div>
  );
};

export default SelectionPage;
