import React from "react";

export const DottedCircleIcon = () => (
  <div
    style={{
      height: "14px",
      width: "14px",
      borderRadius: "50%",
      border: "1px dashed var(--color-gray-400)",
    }}
  ></div>
);

export const DottedCircleIconColored = () => (
  <div
    style={{
      height: "14px",
      width: "14px",
      borderRadius: "50%",
      border: "1.5px dashed #92400E",
    }}
  ></div>
);
