import {
  Dialog,
  Alert,
  DialogTitle,
  DialogContent,
  FormControl,
  InputLabel,
  Input,
  Chip,
  Button,
  CircularProgress,
} from "@mui/material";
import React, { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import XFA_API, { DashboardUser, Role, Shadow, User } from "../API/XFA_API";

// InviteUsersDialog Component
interface InviteUsersDialogProps {
  role: Role;
  users: undefined | Shadow[] | User[] | DashboardUser[];
  onInvite: (organizationId: string, email: string) => Promise<void>;
  onClose: (refresh: boolean) => void;
}

interface Invite {
  email: string;
  inviting?: boolean;
  error?: string;
  ok?: boolean;
}

export const InviteUsersDialog: React.FC<InviteUsersDialogProps> = ({
  role,
  users,
  onInvite,
  onClose,
}) => {
  const { t } = useTranslation();
  const [error, setError] = useState<string | undefined>(undefined);
  const [emails, setEmails] = useState<string[] | undefined>([]);
  const [emailInput, setEmailInput] = useState<string>("");
  const [emailInputValid, setEmailInputValid] = useState<boolean>(true);
  const [inviting, setInviting] = useState(false);

  const inviteUser = useCallback((organizationID: string, email: string) => {
    return onInvite(organizationID, email);
  }, []);

  const removeEmail = useCallback(
    (email: string) => {
      if (emails) {
        setEmails(
          emails.filter((e) => e.toLowerCase() !== email.toLowerCase()),
        );
      }
    },
    [emails],
  );

  const addEmail = useCallback(
    (email: string) => {
      email = email.trim().toLowerCase();

      if (emails && emails.includes(email)) {
        return;
      }

      let newEmails = emails;
      if (newEmails) {
        newEmails.push(email);
      } else {
        newEmails = [email];
      }
      setEmails(newEmails);
    },
    [emails],
  );

  const userExists = useCallback(
    (email: string) => {
      return users?.some(
        (user) => user.email.toLowerCase() === email.toLowerCase(),
      );
    },
    [users],
  );

  const isEmail = useCallback((email: string) => {
    const pattern = new RegExp(
      /^[A-Z][A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,63}$/i,
    );
    return pattern.test(email.trim());
  }, []);

  const inviteUsers = useCallback(async () => {
    let invites: Invite[] = [];

    if (emails) {
      invites = emails.map((email) => ({ email }));
    }

    if (isEmail(emailInput)) {
      addEmail(emailInput);
      setEmailInput("");
      invites.push({ email: emailInput });
    }

    invites = invites.filter((invite) => !userExists(invite.email));

    if (invites.length > 0) {
      setInviting(true);
      for (let invite of invites) {
        invite.inviting = true;
        await inviteUser(role.organization.organization_id, invite.email)
          .then(() => {
            invite.inviting = false;
            invite.ok = true;
          })
          .catch((error: any) => {
            invite.inviting = false;
            invite.ok = false;
            invite.error = error;
          });
      }

      const failedInvitations = invites.filter((invite) => !invite.ok);
      if (failedInvitations.length > 0) {
        setInviting(false);
        // TODO display error
      } else {
        onClose(true);
      }
    }
  }, [
    emails,
    emailInput,
    addEmail,
    inviteUser,
    isEmail,
    userExists,
    role.organization.organization_id,
    onClose,
  ]);

  const handleEmailInput = useCallback((e: any) => {
    const input = e.target.value;
    setEmailInput(input ? input : "");
  }, []);

  useEffect(() => {
    if (emailInput.indexOf(",") > -1) {
      const parts = emailInput.split(",");
      for (let part of parts) {
        if (isEmail(part)) {
          addEmail(part);
        } else {
          setEmailInput(parts.slice(parts.indexOf(part)).join(", "));
          return;
        }
      }
      setEmailInput("");
    }
  }, [emailInput, addEmail, isEmail]);

  useEffect(() => {
    setEmailInputValid(isEmail(emailInput));
  }, [emailInput, isEmail]);

  return (
    <Dialog open={true} onClose={() => onClose(false)}>
      {error && (
        <Alert onClose={() => setError(undefined)} severity="error">
          {t("InviteUsers.error")}
        </Alert>
      )}
      <DialogTitle>
        {t("InviteUsers.title")}
        {role.organization.name}
      </DialogTitle>
      <DialogContent>
        <div>{t("InviteUsers.content")}</div>
        <div style={{ marginBottom: 20, marginTop: 10 }}>
          <FormControl variant="standard" fullWidth={true}>
            <InputLabel htmlFor="input-with-icon-adornment">Emails</InputLabel>
            <Input
              style={{ display: "block" }}
              error={!emailInputValid && emailInput !== ""}
              startAdornment={
                <div
                  style={{
                    marginBottom: emails && emails.length > 0 ? 10 : undefined,
                  }}
                >
                  {emails &&
                    emails.map((email) => (
                      <Chip
                        key={email}
                        label={
                          userExists(email)
                            ? `${email} (${t("Users.exists")})`
                            : email
                        }
                        onDelete={() => removeEmail(email)}
                        disabled={inviting}
                        style={{
                          margin: 5,
                          opacity: userExists(email) ? 0.4 : 1,
                        }}
                      />
                    ))}
                </div>
              }
              value={emailInput}
              onKeyDown={({ nativeEvent }) => {
                if (
                  (nativeEvent.key === "Enter" || nativeEvent.key === " ") &&
                  isEmail(emailInput.trim())
                ) {
                  addEmail(emailInput);
                  setEmailInput("");
                } else if (
                  nativeEvent.key === "Backspace" &&
                  emailInput === ""
                ) {
                  if (emails) {
                    const email = emails.pop();
                    if (email) {
                      removeEmail(email);
                      setEmailInput(email);
                    }
                  }
                }
              }}
              onChange={handleEmailInput}
              disabled={inviting}
            />
          </FormControl>
        </div>
        <div style={{ justifyContent: "right", display: "flex" }}>
          <Button
            onClick={inviteUsers}
            disabled={
              inviting ||
              !(
                emailInputValid ||
                (emailInput === "" &&
                  emails &&
                  emails.some((email) => !userExists(email)))
              )
            }
            variant="contained"
          >
            {inviting && (
              <CircularProgress size="20px" style={{ marginRight: "10px" }} />
            )}
            {t("InviteUsers.invite")}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};
