
export interface AuthConfig {
    region: string;
    userPoolId: string;
    userPoolWebClientId: string;
    authenticationFlowType: string;
    mandatorySignIn: boolean;
    oauth: {
      domain: string;
      redirectSignIn: string;
      redirectSignOut: string;
      scope: string[];
      responseType: string;
    };
  }

export const getAuthConfig = (): AuthConfig => ({
    region: process.env.REACT_APP_COGNITO_REGION || "",
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID || "",
    userPoolWebClientId: process.env.REACT_APP_COGNITO_WEB_CLIENT_ID || "",
    authenticationFlowType: "USER_PASSWORD_AUTH",
    mandatorySignIn: true,
    oauth: {
      domain: process.env.REACT_APP_COGNITO_DOMAIN || "",
      redirectSignIn: window.location.origin,
      redirectSignOut: window.location.origin,
      scope: ["email", "openid", "profile"],
      responseType: "code",
    },
  });
