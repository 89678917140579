import React, { CSSProperties, useEffect, useState } from "react";
import { TableCell } from "@mui/material";
import { Device, Policies, Shadow } from "../API/XFA_API";
import { DeviceRisk, UserRisk, getDeviceRisk } from "./UserUtils";
import StatusChip from "./StatusChip";

const SecurityStatusCell = ({
  user,
  activeDevicesUser,
  onClick,
  style,
  policy,
}: {
  user: Shadow;
  activeDevicesUser: (email: string) => Device[];
  onClick?: () => void;
  style: CSSProperties;
  policy?: Policies | undefined;
}) => {
  const activeDevices = activeDevicesUser(user.email);
  let hasUnsafe = false;
  let hasUnknown = false;

  activeDevices.forEach((device) => {
    const status = getDeviceRisk(device, policy);
    if (status === DeviceRisk.Unsafe) {
      hasUnsafe = true;
    } else if (status !== DeviceRisk.Safe) {
      hasUnknown = true;
    }
  });

  let generalStatus: UserRisk;
  if (hasUnsafe) {
    generalStatus = UserRisk.Unsafe;
  } else if (hasUnknown) {
    generalStatus = UserRisk.Unknown;
  } else {
    generalStatus = UserRisk.Safe;
  }

  const [isSmallScreen, setIsSmallScreen] = useState<boolean>(
    window.innerWidth < 991,
  );

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 991);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <TableCell component="th" scope="row" onClick={onClick} style={style}>
      {activeDevices.length > 0 && (
        <>
          <StatusChip status={generalStatus} small={isSmallScreen} />
        </>
      )}
    </TableCell>
  );
};

export default SecurityStatusCell;
