import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Typography,
  CircularProgress,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import "../AuthStateApp.css";
import FreeTrialCard from "./FreeTrialCard";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { nl, enUS } from "date-fns/locale";

import {
  initializeMollieComponents,
  createMollieToken,
  // @ts-ignore
} from "./mollieIntegration";
import XFA_API from "../../API/XFA_API";
import NavigationService from "../../../utils/NavigationService";

interface BillingDetailsProps {
  organization_id: string;
  onNextStep: () => void;
}

const BillingDetails = (props: BillingDetailsProps) => {
  const [error, setError] = useState<string | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [skipChecked, setSkipChecked] = useState<boolean>(false);
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language || "en";

  const today = new Date();
  const endDate = new Date(today.setDate(today.getDate() + 30));
  const dateLocale = currentLanguage === "nl" ? nl : enUS;
  const formattedDate = format(endDate, "d MMMM yyyy", {
    locale: dateLocale,
  });

  useEffect(() => {
    const { mountComponents, unmountComponents, mollie } =
      initializeMollieComponents(
        "pfl_B5YegSLQMz",
        currentLanguage === "nl" ? "nl_NL" : "en_US",
      );

    mountComponents();
    return () => {
      unmountComponents();
    };
  }, [currentLanguage]);

  const verify = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (skipChecked) {
      localStorage.setItem("skipPayment", "true");
      props.onNextStep();
      return;
    }

    setLoading(true);

    const { token, error } = await createMollieToken("");

    if (error) {
      setError(error);
      setLoading(false);
      return;
    }

    await XFA_API.addPaymentMethod(props.organization_id, token)
      .then((checkoutUrl) => {
        window.location.href = checkoutUrl;
      })
      .catch(() => {
        setLoading(false);
        setError(t("login.signup.organizationError"));
        return;
      });

    if (error) {
      setError(error);
      setLoading(false);
      return;
    }
  };

  return (
    <div id="billing-details">
      <form onSubmit={verify}>
        <Typography variant="forminstructions">
          {t("login.signup.step4Description")}
        </Typography>
        <FreeTrialCard />
        <FormControlLabel
          style={{ marginLeft: "0px" }}
          control={
            <Checkbox
              id="skip-payment"
              checked={skipChecked}
              style={{
                marginRight: "16px",
                padding: "0px",
                color: skipChecked
                  ? "var(--color-gray-900)"
                  : "var(--color-gray-500)",
                width: "24px",
              }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setSkipChecked(event.target.checked);
              }}
            />
          }
          label={
            <div
              style={{
                fontSize: "14px",
                fontWeight: 600,
                lineHeight: "20px",
                textAlign: "left",
                color: "var(--color-gray-500)",
              }}
            >
              {t("login.signup.skipPaymentDetails")}
            </div>
          }
        />
        <div>
          <div id="cardHolder">
            <div
              style={{
                marginBottom: "4px",
                marginTop: "16px",
                opacity: skipChecked ? 0.5 : 1,
              }}
            >
              <Typography variant="formlabel">
                {t("login.signup.cardHolder")}
              </Typography>
            </div>
            <div
              id="card-holder"
              style={{
                pointerEvents: skipChecked ? "none" : "auto",
                opacity: skipChecked ? 0.5 : 1,
              }}
            />
          </div>
          <div id="cardNumber">
            <div
              style={{
                marginBottom: "4px",
                marginTop: "16px",
                opacity: skipChecked ? 0.5 : 1,
              }}
            >
              <Typography variant="formlabel">
                {t("login.signup.cardNumber")}
              </Typography>
            </div>
            <div
              id="card-number"
              style={{
                pointerEvents: skipChecked ? "none" : "auto",
                opacity: skipChecked ? 0.5 : 1,
              }}
            />
          </div>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-between",
              gap: "16px",
            }}
          >
            <div id="expirationDate">
              <div
                style={{
                  marginBottom: "4px",
                  marginTop: "16px",
                  opacity: skipChecked ? 0.5 : 1,
                }}
              >
                <Typography variant="formlabel">
                  {t("login.signup.expirationDate")}
                </Typography>
              </div>
              <div
                id="expiry-date"
                style={{
                  pointerEvents: skipChecked ? "none" : "auto",
                  opacity: skipChecked ? 0.5 : 1,
                }}
              />
            </div>
            <div id="securityCode">
              <div
                style={{
                  marginBottom: "4px",
                  marginTop: "16px",
                  opacity: skipChecked ? 0.5 : 1,
                }}
              >
                <Typography variant="formlabel">
                  {t("login.signup.securityCode")}
                </Typography>
              </div>
              <div
                id="verification-code"
                style={{
                  pointerEvents: skipChecked ? "none" : "auto",
                  opacity: skipChecked ? 0.5 : 1,
                }}
              />
            </div>
          </div>
        </div>
        {error && (
          <div id={"errorMessage"}>
            <Alert severity="error">{t(error)}</Alert>
          </div>
        )}
        <div
          className="sendButton"
          style={{ position: "relative", flexDirection: "column" }}
        >
          <Button
            id="billing-setup-button"
            variant={"contained"}
            type={"submit"}
            color={"primary"}
            disableElevation
            size="large"
            style={{ width: "100%" }}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              t("login.signup.freeMonth")
            )}
          </Button>
          <Typography
            variant="body2"
            style={{
              marginTop: "16px",
              textAlign: "left",
              color: "var(--color-gray-500)",
            }}
          >
            {t("login.signup.freeMonthDescription", { date: formattedDate })}
          </Typography>
        </div>
      </form>
    </div>
  );
};

export default BillingDetails;
