import React from "react";
import "./components/I18n/I18n";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import Webfont from "webfontloader";
import "@fontsource/manrope";
import "./index.css";

import * as Sentry from "@sentry/react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

//preload fonts (less flicker)
Webfont.load({
  google: {
    families: ["Manrope:400,500,600,700"],
  },
});

if (window.location.hostname !== "localhost") {
  Sentry.init({
    dsn: "https://972dc74aabcd49f88147feac1fc0dbb6@o1126213.ingest.sentry.io/6166986",
    integrations: [Sentry.browserTracingIntegration()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0,
  });
}

const root = createRoot(document.getElementById("root")!);
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
);

/*
 * If you want your app to work offline and load faster, you can change
 * unregister() to register() below. Note this comes with some pitfalls.
 * Learn more about service workers: https://bit.ly/CRA-PWA
 */
serviceWorker.unregister();
