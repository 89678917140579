export const ADMINISTRATORS_GROUP = "Administrators";

export class SignedInUser {
  public readonly username: string;
  public readonly email: string;
  private readonly groups: string[];

  constructor(username: string, email: string, groups: string[]) {
    this.username = username;
    this.email = email;
    this.groups = groups;
  }

  public isAdministrator() {
    return this.groups.includes(ADMINISTRATORS_GROUP);
  }
}
