import React, { useEffect } from "react";
import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import "./BillingComplete.css";
import NavigationService from "../../../utils/NavigationService";

interface BillingCompleteProps {
  onNextStep: () => void;
}

const BillingComplete = (props: BillingCompleteProps) => {
  const { t } = useTranslation();

  useEffect(() => {
    setTimeout(() => {
      NavigationService.navigateToSignup(5);
      props.onNextStep();
    }, 2000);
  }, []);

  return (
    <div id="billing-complete">
      <CircularProgress />
      <p>{t("login.signup.billingComplete")}</p>
    </div>
  );
};

export default BillingComplete;
