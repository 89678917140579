import React from "react";
import { Trans, useTranslation } from "react-i18next";
import {
  Typography,
  CircularProgress,
  Divider,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Alert,
} from "@mui/material";
import "./Statistics.css";
import { Policies, Role } from "../API/XFA_API";
import Usage from "./Usage";
import Health from "./Health";
import OperatingSystem from "./OperatingSystems";
import NavigationService from "../../utils/NavigationService";
import DropdownMenu from "../General/Dropdown/DropdownMenu";
import DiscoveryHighlights from "../Discovery/DiscoveryHighlights";
import { getRecentDevices } from "../Users/UserUtils";
import { useLocation, useParams } from "react-router-dom";
import { Buffer } from "buffer";
import { isValidEmail } from "../../utils";
import { useDevices } from "../Overview/hooks/useDevices";
import { usePolicies } from "../Overview/hooks/usePolicies";

interface StatisticsProps {
  role: Role;
  onSignOut: (fixedEmail?: string, signup?: boolean) => Promise<void>;
}

const Statistics: React.FC<StatisticsProps> = ({ role, onSignOut }) => {
  const { t } = useTranslation();
  const isBlocked = role.blocked_access;
  const {
    policies,
    loading: policiesLoading,
    error: policiesError,
  } = usePolicies(role, isBlocked);
  const [selectedPolicy, setSelectedPolicy] = React.useState<Policies | null>(
    null,
  );
  const {
    devices,
    loading: devicesLoading,
    error: devicesError,
  } = useDevices(role, selectedPolicy?.policy_id);

  const renderError = (error: string | null) =>
    error && (
      <div style={{ marginBottom: 8, marginTop: 16 }}>
        <Alert severity="error">{error}</Alert>
      </div>
    );

  React.useEffect(() => {
    if (!policies || policies.length === 0) return;
    const defaultPolicy = policies.find(
      (policy) => policy.policy_id === role.organization.default_policy_id,
    );
    setSelectedPolicy(defaultPolicy ?? null);
  }, [policies]);

  if (devicesLoading || policiesLoading) {
    return (
      <div>
        <Typography variant="pagetitle">{t("navigation.overview")}</Typography>
        <Divider style={{ marginTop: 16 }} />
        <div style={{ textAlign: "center", marginTop: 50 }}>
          <CircularProgress size={30} />
        </div>
      </div>
    );
  }

  return (
    <div>
      <div style={{ display: "flex" }}>
        <div className="overview-header">
          <Typography variant="pagetitle">
            {t("navigation.overview")}
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              width: "100%",
            }}
          >
            {policies && policies.length > 0 && (
              <DropdownMenu
                mode="select"
                defaultSelected={selectedPolicy?.name ?? "Select policy"}
                actions={policies.map((policy) => ({
                  label: (policy && policy.name) ?? "policy",
                  icon: null,
                  onClick: () => {
                    setSelectedPolicy(policy);
                  },
                }))}
              />
            )}
          </div>
        </div>
      </div>
      <Divider style={{ marginTop: 16 }} />
      {renderError(policiesError)}
      {renderError(devicesError)}
      <div className="statistics">
        <div className="overview">
          {devices && devices?.length > 0 && (
            <>
              <Usage role={role} />
              <Health devices={devices} selectedPolicy={selectedPolicy} />
              <OperatingSystem devices={devices} />
            </>
          )}
        </div>
      </div>
      {policies &&
        policies.length > 0 &&
        devices &&
        devices.length > 0 &&
        selectedPolicy && (
          <DiscoveryHighlights
            role={role}
            devices={getRecentDevices(devices)}
            selectedPolicy={selectedPolicy}
          />
        )}
    </div>
  );
};

export default Statistics;
