import React from "react";
import "./EmptyState.css";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import emptyStateIconApplications from "../../images/icon_applications_empty_state.png";
import emptyStateIconUsers from "../../images/icon_users_empty_state.png";
import discoveryIcon from "../../images/icon_discovery_empty state.svg";

interface EmptyStateProps {
  context: "users" | "applications" | "policies" | "usersNoDiscovery";
  onClick: () => void;
  onClick2?: () => void;
}
const EmptyState: React.FC<EmptyStateProps> = ({
  context,
  onClick,
  onClick2,
}) => {
  const { t } = useTranslation();
  const icons = {
    users: emptyStateIconUsers,
    applications: emptyStateIconApplications,
    policies: emptyStateIconApplications,
    usersNoDiscovery: discoveryIcon,
  };

  const headerText = {
    users: t("applications.noUsersHeader"),
    applications: t("applications.noApplicationsHeader"),
    policies: t("applications.noPoliciesHeader"),
    usersNoDiscovery: t("Users.discoveryEmptyStateTitle"),
  };
  const description1Text = {
    users: t("applications.noUsersDescription1"),
    applications: t("applications.noApplicationsDescription1"),
    policies: t("applications.noPoliciesDescription1"),
    usersNoDiscovery: t("Users.discoveryEmptyStateDescription1"),
  };
  const description2Text = {
    users: t("applications.noUsersDescription2"),
    applications: t("applications.noApplicationsDescription2"),
    policies: t("applications.noPoliciesDescription2"),
    usersNoDiscovery: t("Users.discoveryEmptyStateDescription2"),
  };

  const buttonIcon = {
    users: <></>,
    applications: <AddIcon />,
    policies: <AddIcon />,
    usersNoDiscovery: <></>,
  };

  const buttonText1 = {
    users: t("applications.noUsersButton1"),
    applications: t("applications.new"),
    policies: t("applications.new"),
    usersNoDiscovery: t("Users.discoverySettings"),
  };

  const buttonText2 = {
    users: t("applications.noUsersButton2"),
    applications: t("applications.noUsersButton2"),
    policies: t("applications.noUsersButton2"),
    usersNoDiscovery: t("applications.noUsersButton2"),
  };

  const icon = icons[context];
  return (
    <div className="noApplications">
      <div className="emptyStateContent">
        <img src={icon} alt="Icon" className="emptyStateIcon" />{" "}
        <h1 className="emptyStateHeader">{headerText[context]}</h1>
        <p className="emptyStateDescription">{description1Text[context]}</p>
        <p className="emptyStateDescription">{description2Text[context]}</p>
        <div>
          {context === "usersNoDiscovery" ? (
            <>
              <Button
                variant="outlined"
                onClick={onClick2}
                style={{
                  marginRight: "16px",
                  marginTop: "32px",
                  backgroundColor: "white",
                }}
              >
                {buttonText2[context]}
              </Button>
              <Button
                variant="contained"
                onClick={onClick}
                style={{
                  marginLeft: "auto",
                  marginTop: "32px",
                }}
              >
                {buttonText1[context]}
              </Button>
            </>
          ) : (
            <Button
              variant="contained"
              startIcon={buttonIcon[context]}
              onClick={onClick}
              style={{ marginLeft: "auto", marginTop: "32px" }}
            >
              {buttonText1[context]}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default EmptyState;
