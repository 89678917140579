import { Role } from "../API/XFA_API";
import React from "react";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

interface ConfirmationDialogProps {
  title: string;
  content?: string;
  cancelText: string;
  confirmText: string;
  inProgress: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = (
  props: ConfirmationDialogProps,
) => {
  return (<Dialog
    sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
    maxWidth="xs"
    open={true}
    onClose={props.onCancel}
  >
    <DialogTitle>
      {props.title}
    </DialogTitle>
    {props.content && <DialogContent>
      {props.content}
    </DialogContent>}
    <DialogActions>
      <Button
        autoFocus
        onClick={props.onCancel}
      >
        {props.cancelText}
      </Button>
      <Button
        onClick={props.onConfirm}
        disabled={props.inProgress}
      >
        {props.inProgress && (
          <CircularProgress size="20px" style={{ marginRight: "10px" }} />
        )}
        {props.confirmText}
      </Button>
    </DialogActions>
  </Dialog>)
}

export default ConfirmationDialog;