import React, { useState } from "react";
import { XfaSwitch } from "../../../General/XfaSwitch";
import { Divider } from "@mui/material";
import { set } from "date-fns";

interface ToggleSwitchProps {
  title: string;
  label?: string;
  isChecked: boolean;
  onCheckedToggle: (checked: boolean) => void;
  onToggle: (isOpen: boolean) => void;
  showToggle: boolean;
  children: React.ReactNode;
}

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
  title,
  label,
  isChecked,
  onCheckedToggle,
  onToggle,
  showToggle,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleArrowClick = () => {
    const newIsOpen = !isOpen;
    setIsOpen(newIsOpen);
    onToggle(newIsOpen);
  };

  return (
    <div className="toggle-switch">
      <div
        className={`toggle-header ${!showToggle ? "no-hover" : ""}`}
        onClick={() => {
          if (showToggle) handleArrowClick();
        }}
      >
        <XfaSwitch
          checked={isChecked}
          onClick={(e) => e.stopPropagation()}
          onChange={(e) => {
            onCheckedToggle(e.target.checked);
            if (e.target.checked && showToggle) {
              onToggle(true);
              setIsOpen(true);
            }
          }}
        />
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <span
            className={`toggle-title ${!showToggle ? "noHover" : ""}`}
            onClick={() => {
              if (showToggle) handleArrowClick();
            }}
          >
            {title}
          </span>
          {label && (
            <span
              className={`toggle-label ${!showToggle ? "noHover" : ""}`}
              onClick={() => {
                if (showToggle) handleArrowClick();
              }}
            >
              {label}
            </span>
          )}
        </div>
        {showToggle ? (
          <button
            className={`toggle-arrow ${isOpen ? "open" : "closed"}`}
            onClick={() => handleArrowClick()}
            style={{ fontSize: "20px" }}
            aria-label={isOpen ? "Collapse section" : "Expand section"}
          >
            {">"}
          </button>
        ) : (
          <div></div>
        )}
      </div>

      <div
        className={`toggle-content-wrapper ${
          isOpen ? "toggle-content-open" : "toggle-content-closed"
        }`}
      >
        {isOpen && (
          <>
            <Divider style={{ marginBottom: "20px" }} />
            <div className="toggle-content">{children}</div>
          </>
        )}
      </div>
    </div>
  );
};

export default ToggleSwitch;
