import React from "react";
import { useTranslation } from "react-i18next";
import { Typography, Chip, IconButton } from "@mui/material";
import BooleanSetting from "./BooleanSetting";
import { Application } from "../../API/XFA_API";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

interface MFAPageProps {
  vertical: boolean;
  application: Application;
  setApplication: (application: Application) => void;
}

const MFAPage: React.FC<MFAPageProps> = (props: MFAPageProps) => {
  const { t } = useTranslation();

  const application = props.application || ({} as Application);

  return (
    <div
      style={{
        opacity: 1,
      }}
      className={
        props.vertical ? "verticalSelectionSection" : "selectionSection"
      }
    >
      <div style={{ marginBottom: 16 }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography variant="formsectiontitle">
              {t("applications.createDialog.multi")}
            </Typography>
            <IconButton
              size="small"
              style={{ marginLeft: 3 }}
              onClick={() => {
                window.open(
                  "https://docs.xfa.tech/docs/integration-guides/multi-factor-authentication/silent-mfa",
                  "_blank",
                );
              }}
            >
              <InfoOutlinedIcon
                style={{
                  fontSize: 16,
                }}
              />
            </IconButton>
          </div>
          <Chip size="small" label="New" color="primary"></Chip>
        </div>
        <Typography variant="instructions">
          {t("applications.createDialog.multiDescription")}
        </Typography>
      </div>
      <div style={{ display: "flex", flexDirection: "column", gap: 24 }}>
        <BooleanSetting
          explanation={t("applications.createDialog.multiEnabledDescription")}
          label={t("applications.createDialog.multiEnabled")}
          value={application.EnableMfa ?? false}
          onChange={(value) => {
            const application = { ...props.application };
            application.EnableMfa = value;
            props.setApplication(application);
          }}
        />
        <BooleanSetting
          explanation={t(
            "applications.createDialog.EnableMfaAutoApproveFirstDeviceDescription",
          )}
          label={t("applications.createDialog.EnableMfaAutoApproveFirstDevice")}
          value={application.EnableMfaAutoApproveFirstDevice || false}
          onChange={(value) => {
            const application = { ...props.application };
            application.EnableMfaAutoApproveFirstDevice = value;
            props.setApplication(application);
          }}
        />
      </div>
    </div>
  );
};

export default MFAPage;
