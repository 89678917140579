import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Select,
  MenuItem,
  FormControl,
  Box,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import LanguageIcon from "@mui/icons-material/Translate";
import "./LanguagePopUp.css";

interface LanguagePopUpProps {
  open: boolean;
  onClose: () => void;
}

const LanguagePopUp: React.FC<LanguagePopUpProps> = ({ open, onClose }) => {
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);

  const handleConfirm = () => {
    console.log("Language set to: ", language);
    i18n.changeLanguage(language);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiDialog-paper": {
          width: 400,
          borderRadius: "12px",
        },
      }}
    >
      <DialogTitle sx={{ padding: "24px 24px 0 24px" }}>
        <Box sx={{ display: "block", alignItems: "center", gap: 2 }}>
          <LanguageIcon
            sx={{
              width: 48,
              height: 48,
              padding: "8px",
              borderRadius: "28px ",
              border: "8px solid #F9FAFB",
              backgroundColor: "var(--color-gray-100)",
              display: "block",
            }}
          />
          <Typography
            variant="h6"
            sx={{
              fontWeight: 600,
              fontSize: 18,
              lineHeight: "28px",
              textAlign: "left",
              display: "block",
              margin: "20px 0 0 0 !important",
            }}
          >
            {t("navigation.language.popUpTitle")}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ padding: "8px 24px" }}>
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: 400,
            lineHeight: "20px",
            textAlign: "left",
            mt: 2,
            margin: "0 !important",
          }}
        >
          {t("navigation.language.popUpDescription")}
        </Typography>
        <FormControl fullWidth sx={{ mt: 2 }}>
          <Select
            labelId="language-select-label"
            id="language-select"
            value={language}
            onChange={(e) => setLanguage(e.target.value)}
            sx={{
              mt: 2,
              borderColor: "var(--color-gray-200)",
              borderRadius: "6px",
            }}
          >
            <MenuItem value="en">English</MenuItem>
            <MenuItem value="nl">Nederlands</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions sx={{ padding: "32px 24px" }}>
        <Button onClick={onClose} variant="secondary" sx={{ width: "170px" }}>
          {t("navigation.language.cancel")}
        </Button>
        <Button
          onClick={handleConfirm}
          variant="contained"
          sx={{ width: "170px" }}
        >
          {t("navigation.language.confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LanguagePopUp;
