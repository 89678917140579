import { useState, useEffect, useCallback } from "react";
import XFA_API, { Device, Role, Shadow } from "../../API/XFA_API";

export const useDevices = (role: Role, policy_id: string | undefined) => {
  const [devices, setDevices] = useState<Device[] | undefined>(undefined);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchDevices = useCallback(async () => {
    try {
      setLoading(true);
      let allDevices: Device[] = [];
      let lastEvaluatedKey: string | undefined = undefined;

      do {
        const result = await XFA_API.getGroupedDevices(
          role.organization.organization_id,
          lastEvaluatedKey,
          policy_id ?? role.organization.default_policy_id,
        );

        if (result && result.shadows) {
          const devicesFromShadows = result.shadows.flatMap(
            (shadow: Shadow) => shadow.devices,
          );
          allDevices = [...allDevices, ...devicesFromShadows];
          lastEvaluatedKey = result.lastEvaluatedKey;
        } else {
          lastEvaluatedKey = undefined;
        }
      } while (lastEvaluatedKey);

      setDevices(allDevices);
      setError(null);
    } catch (err: any) {
      setError(err.message || "Error fetching devices");
    } finally {
      setLoading(false);
    }
  }, [role.organization.organization_id, policy_id]);

  useEffect(() => {
    fetchDevices();
  }, [fetchDevices]);

  return { devices, loading, error, refresh: fetchDevices };
};
