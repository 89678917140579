import React from "react";
import { useTranslation } from "react-i18next";
import { FormControl, Typography } from "@mui/material";
import CopyIdComponent from "../CopyIdComponent";
import MFAPage from "../MFAPage";
import { Application } from "../../../API/XFA_API";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

interface OktaPageProps {
  creating: boolean;
  application: Application;
  setApplication: (application: Application) => void;
  vertical: boolean;
}

const OktaPage: React.FC<OktaPageProps> = (props: OktaPageProps) => {
  const { t } = useTranslation();

  return (
    <div>
      <div
        className={
          props.vertical ? "verticalSelectionSection" : "selectionSection"
        }
      >
        <div style={{ marginBottom: 16 }}>
          <Typography variant="formsectiontitle">
            {t("applications.okta.title")}
          </Typography>
          <Typography variant="instructions">
            {t("applications.okta.content")}
          </Typography>
        </div>
        <div>
          <FormControl fullWidth={true}>
            <Typography variant="instructions" style={{ marginBottom: 16 }}>
              {t("applications.okta.start")}
            </Typography>
            <CopyIdComponent
              title={t("applications.okta.issuer")}
              value={props.application.Issuer!}
              disabled={false}
            />
            <CopyIdComponent
              title={t("applications.okta.signonurl")}
              value={props.application.SSOUrl!}
              disabled={false}
            />
            <Typography variant="formlabel">
              {t("applications.okta.certificate")}
            </Typography>
            <Button
              variant="outlined"
              style={{ marginBottom: 16 }}
              onClick={() => {
                const file = document.createElement("a");
                file.href = URL.createObjectURL(
                  new Blob([props.application.PublicCerts![0]], {
                    type: "application/x-x509-ca-cert",
                  }),
                );
                file.download = "xfa-certificate-for-okta.crt";
                file.click();
              }}
            >
              {t("applications.okta.downloadcertificate")}
            </Button>
            <Typography variant="instructions" style={{ marginBottom: 16 }}>
              {t("applications.okta.otherSettings")}
            </Typography>
            <Typography variant="instructions" style={{ marginBottom: 16 }}>
              {t("applications.okta.postOktaCreationInstructions")}
            </Typography>
            <Typography variant="formlabel">
              {t("applications.okta.acsurl")}
            </Typography>
            <TextField
              required
              id="acsurl"
              style={{ marginBottom: 16 }}
              value={
                props.application.SAMLConfiguration
                  ?.ServiceProviderAssertionConsumerServiceURL || undefined
              }
              placeholder={t("applications.okta.acsurl")}
              onChange={(event) => {
                props.application.SAMLConfiguration!.ServiceProviderAssertionConsumerServiceURL =
                  event.target.value;
                props.setApplication(props.application);
              }}
            ></TextField>
            <Typography variant="formlabel">
              {t("applications.okta.audience")}
            </Typography>
            <TextField
              style={{ marginBottom: 16 }}
              required
              id="audience"
              value={props.application.SAMLConfiguration?.FactorOnlyAudience}
              placeholder={t("applications.okta.audience")}
              onChange={(event) => {
                props.application.SAMLConfiguration!.FactorOnlyAudience =
                  event.target.value;
                props.setApplication(props.application);
              }}
            ></TextField>
            <Typography variant="instructions" style={{ marginBottom: 16 }}>
              {t("applications.okta.nextsteps")}
            </Typography>
          </FormControl>
        </div>
      </div>
    </div>
  );
};

export default OktaPage;
