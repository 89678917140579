import React from "react";
import DropdownMenu, { MenuAction } from "../../General/Dropdown/DropdownMenu";

const DropdownSetting = (props: {
  label: string;
  explanation: string;
  value: { label: string; value: string };
  disabled?: boolean;
  actions: MenuAction[];
}) => {
  return (
    <div style={{ display: "flex", width: "100%" }}>
      <div>
        <DropdownMenu
          actions={props.actions}
          mode="select"
          defaultSelected={props.value.label}
          disabled={props.disabled}
        />
      </div>
    </div>
  );
};

export default DropdownSetting;
