import React from "react";
import { useTranslation } from "react-i18next";
import { CircularProgress, Typography } from "@mui/material";
import XFA_API, { BillingSettings, Role } from "../../API/XFA_API";

interface UsageProps {
  role: Role;
  settings: BillingSettings | undefined;
  settingsLoading: boolean;
}

const Usage: React.FC<UsageProps> = (
  props: UsageProps
) => {
  const { t } = useTranslation();
  const [numberOfUsers, setNumberOfUsers] = React.useState<number>(0);
  const [numberOfDevices, setNumberOfDevices] = React.useState<number>(0);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [isInTrial, setIsInTrial] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (!loading) return;
    if (props.settings) {
      const currentTime = new Date(Date.now());
      const date = new Date(
        currentTime.getFullYear(),
        currentTime.getMonth(),
        1
      );
      if (props.settings.PerUserPrice) {
        XFA_API.getUserCount(props.role.organization.organization_id, date.getFullYear().toString() +
          ("0" + (date.getMonth() + 1)).slice(-2)).then((count) => {
          setNumberOfUsers(count.count);
          setLoading(false);
        });
      } else {
        XFA_API.getDeviceCount(props.role.organization.organization_id, date.getFullYear().toString() +
          ("0" + (date.getMonth() + 1)).slice(-2)).then((count) => {
          setNumberOfDevices(count.count + count.connect_count);
          setLoading(false);
        });
      }
    }
  }, [props.settings]);

  React.useEffect(() => {
    if (!props.settings) return;
    setIsInTrial(new Date().setFullYear(
      parseInt(props.settings.TrialEndDate.substring(0, 4)),
      parseInt(props.settings.TrialEndDate.substring(4, 6)) - 1,
      parseInt(props.settings.TrialEndDate.substring(6))
    ) >= new Date().getTime());
  }, [props.settings]);


  return (
    <div>
      <Typography className="paperTitle" color="primary">
        {t("Billing.usageTitle")}
      </Typography>
      {props.settings && !props.settingsLoading && !loading ? (
          <>
            <Typography
              className="paperContent">{props.settings.PerUserPrice ? t("Billing.usagePerUser") : t("Billing.usagePerDevice")}</Typography>
            <Typography
              className="paperContentBold">{props.settings.PerUserPrice ? numberOfUsers : numberOfDevices}</Typography>
            <Typography className="paperContent" style={{ marginTop: "24px" }}>{t("Billing.currentMonth")}</Typography>
            <Typography className="paperContentBold">{"€ "}{props.settings.PerUserPrice ? numberOfUsers * props.settings.PerUserPrice : numberOfDevices * props.settings.InsightPrice}{isInTrial && "*"}</Typography>
            {isInTrial && (<Typography className="paperContentSmall">{t("Billing.isInTrial")}</Typography>)}
          </>) :
        props.settingsLoading ? (
          <CircularProgress
            size="20px"
            style={{ marginRight: "10px" }}
            color="primary"
          />
        ) : (
          <CircularProgress
            size="20px"
            style={{ marginRight: "10px" }}
            color="primary"
          />
        )}
    </div>
  );
};

export default Usage;
