import { FormControl, Typography, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import PolicyDropdown from "./PolicyDropdown/PolicyDropdown";
import React from "react";
import { Policies } from "../../API/XFA_API";
import { usePolicySettingsConfig } from "../hooks/usePolicySettingsConfig";

interface PolicyRulesComponentProps {
  policy: Policies;
  setPolicy: (policy: Policies) => void;
  loading: boolean;
}

const PolicyRulesComponent: React.FC<PolicyRulesComponentProps> = ({
  policy,
  setPolicy,
  loading,
}) => {
  const { t } = useTranslation();
  const settingsConfig = usePolicySettingsConfig(policy, setPolicy);

  return (
    <FormControl className="policy-rules">
      <div
        style={{ display: "flex", flexDirection: "column", marginBottom: 8 }}
      >
        <Typography variant="formlabel" style={{ marginBottom: 4 }}>
          {t("policies.createPolicy.name")}
        </Typography>
        <TextField
          required
          id="name"
          value={policy?.name}
          disabled={loading}
          placeholder={t("policies.createPolicy.namePlaceholder")}
          onChange={(event: { target: { value: any } }) => {
            if (!policy) return;
            setPolicy({ ...policy, name: event.target.value });
          }}
        />
      </div>
      <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
        {settingsConfig.map((setting, index) => {
          if (setting.type === "PolicyDropdown") {
            return (
              <PolicyDropdown
                key={`${setting.key}-${index}`}
                toggleTitle={setting.toggleTitle!}
                toggleLabel={setting.toggleLabel}
                description={setting.description}
                isVerificationEnabled={setting.isVerificationEnabled!}
                onVerificationToggle={setting.onVerificationToggle!}
                riskPeriod={setting.riskPeriod}
                onRiskPeriodChange={setting.onRiskPeriodChange}
                validationAction={setting.validationAction}
                onValidationActionChange={setting.onValidationActionChange}
                warnActionPeriod={setting.warnActionPeriod}
                onWarnActionPeriodChange={setting.onWarnActionPeriodChange}
                blockActionPeriod={setting.blockActionPeriod}
                onBlockActionPeriodChange={setting.onBlockActionPeriodChange}
                settings={setting.settings!}
                showRiskPeriodInput={setting.showRiskPeriodInput}
                showValidationAction={setting.showValidationAction}
                showAdditionalSettings={setting.showAdditionalSettings}
              />
            );
          }

          return null;
        })}
      </div>
    </FormControl>
  );
};

export default PolicyRulesComponent;
