import React, { FormEvent } from "react";
import { useTranslation } from "react-i18next";
import { Alert, Button, TextField, Typography } from "@mui/material";
import { Auth } from "aws-amplify";
import "../AuthStateApp.css";

interface ForgotPasswordProps {
  username: string;
  goBack: () => void;
}

enum State {
  Start,
  CodeSent,
  PasswordResetDone,
}

const ForgotPassword = (props: ForgotPasswordProps) => {
  const [username, setUsername] = React.useState<string>(props.username);
  const [recoveryCode, setRecoveryCode] = React.useState<string | undefined>();
  const [password, setPassword] = React.useState<string | undefined>();
  const [error, setError] = React.useState<string | undefined>();
  const [forgotPasswordState, setForgotPasswordState] = React.useState<State>(
    State.Start,
  );
  const { t } = useTranslation();

  const login = (e: FormEvent<HTMLFormElement>) => {
    if (forgotPasswordState === State.Start) {
      setForgotPasswordState(State.CodeSent);
      Auth.forgotPassword(username)
        .then(function (value) {
          console.log(value);
          setError(undefined);
        })
        .catch((err) => {
          console.log(err);
          setForgotPasswordState(State.Start);
          setError(err.message);
          switch (err.code) {
            case "UserNotFoundException":
              setError("login.errorUserNotFound");
              break;
          }
        });
    } else if (forgotPasswordState === State.CodeSent) {
      if (recoveryCode && password) {
        Auth.forgotPasswordSubmit(username, recoveryCode, password)
          .then(function (value) {
            console.log(value);
            setForgotPasswordState(State.PasswordResetDone);
          })
          .catch((err) => {
            console.log(err);
            setError(err.message);
            switch (err.code) {
              case "InvalidParameterException":
                setError("login.errorInvalidRecoveryCode");
                break;
              case "CodeMismatchException":
                setError("login.errorInvalidRecoveryCode");
                break;
              case "InvalidPasswordException":
                setError("login.errorInvalidNewPassword");
                break;
            }
          });
      } else {
        setError("login.errorMissingRecoveryCodeAndPassword");
      }
    }
    e.preventDefault();
  };

  return (
    <form onSubmit={login}>
      <Typography variant="formtitle">{t("login.resetPassword")}</Typography>
      <div id="fields">
        {forgotPasswordState !== State.PasswordResetDone && (
          <div id="username">
            <div style={{ marginBottom: "4px" }}>
              <Typography variant="formlabel">{t("login.username")}</Typography>
            </div>
            <TextField
              variant="outlined"
              placeholder={t("login.usernamePlaceholder")}
              helperText={
                forgotPasswordState === State.Start && error !== undefined
                  ? t(error)
                  : undefined
              }
              fullWidth
              value={username}
              onChange={(event) => setUsername(event.target.value)}
              error={forgotPasswordState === State.Start && error !== undefined}
              disabled={forgotPasswordState === State.CodeSent}
              autoComplete="username"
              autoFocus
              required
            />
          </div>
        )}
        {forgotPasswordState === State.CodeSent && (
          <div id="recoveryCode">
            <div style={{ marginBottom: "4px" }}>
              <Typography variant="formlabel">
                {t("login.recoveryCode")}
              </Typography>
            </div>
            <TextField
              variant="outlined"
              helperText={t("login.recoveryCodeHelperText")}
              placeholder={t("login.recoveryCode")}
              fullWidth
              value={recoveryCode}
              onChange={(event) => setRecoveryCode(event.target.value)}
              error={error !== undefined}
              autoFocus
              required
            />
          </div>
        )}
        {forgotPasswordState === State.CodeSent && (
          <div id="password">
            <div style={{ marginBottom: "4px" }}>
              <Typography variant="formlabel">{t("login.password")}</Typography>
            </div>
            <TextField
              placeholder={t("login.password")}
              variant="outlined"
              type={"password"}
              fullWidth
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              error={error !== undefined}
              helperText={error && t(error)}
              autoComplete="new-password"
              required
            />
          </div>
        )}
      </div>
      {forgotPasswordState === State.PasswordResetDone && (
        <div id="passwordResetDone">
          <Alert severity="success">{t("login.passwordResetDone")}</Alert>
        </div>
      )}
      <div className="sendButton">
        {forgotPasswordState !== State.PasswordResetDone && (
          <Button
            variant={"contained"}
            type={"submit"}
            color={"primary"}
            disableElevation
            size="large"
          >
            {forgotPasswordState === State.CodeSent
              ? t("login.setPassword")
              : t("login.sendCode")}
          </Button>
        )}

        <Button
          variant={"outlined"}
          style={{ textTransform: "initial" }}
          disableElevation
          size="large"
          onClick={(e) => {
            props.goBack();
            e.preventDefault();
          }}
        >
          {t("login.goBackToSignIn")}
        </Button>
      </div>
    </form>
  );
};
export default ForgotPassword;
