import React, {
  createContext,
  ReactNode,
  useContext,
  useRef,
  useState,
} from "react";
import "./ToastProvider.css";
import ToastMessage, { ToastMessageProps } from "./ToastMessage";

interface ToastRefs {
  [id: string]: React.RefObject<HTMLDivElement>;
}

interface ToastContextType {
  addToast: (params: {
    isSaved: boolean;
    title?: string;
    message?: string;
    dismissAction?: () => void;
    undoAction?: () => void;
    closeButton?: () => void;
  }) => void;
}

interface ToastProviderProps {
  children: ReactNode;
}

const ToastContext = createContext<ToastContextType | undefined>(undefined);

export const ToastProvider: React.FC<ToastProviderProps> = ({ children }) => {
  const [toasts, setToasts] = useState<ToastMessageProps[]>([]);
  const toastRefs = useRef<ToastRefs>({});

  const addToast: ToastContextType["addToast"] = ({
    isSaved,
    title,
    message,
    dismissAction,
    undoAction,
  }) => {
    const id: string = Date.now().toString();
    toastRefs.current[id] = React.createRef<HTMLDivElement>();

    Object.keys(toastRefs.current).forEach((existingId) => {
      const existingToast = toastRefs.current[existingId];
      if (existingToast && existingToast.current) {
        existingToast.current.classList.add("move-down");
      }
    });

    setToasts((prevToasts) => [
      {
        id,
        isSaved,
        dismissAction,
        undoAction,
        title,
        message,
        closeButtonAction: () => removeToast(id),
      },
      ...prevToasts,
    ]);

    setTimeout(() => removeToast(id), 7000);
  };

  const removeToast = (id: string) => {
    const toast = toastRefs.current[id];
    if (toast && toast.current) {
      toast.current.classList.remove("show");
      toast.current.classList.add("hide");
    }

    setTimeout(() => {
      setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
      delete toastRefs.current[id];

      Object.keys(toastRefs.current).forEach((existingId) => {
        const existingToast = toastRefs.current[existingId];
        if (existingToast && existingToast.current) {
          existingToast.current.classList.remove("move-down");
        }
      });
    }, 300);
  };

  return (
    <ToastContext.Provider value={{ addToast }}>
      {children}
      <div className="toast-container">
        {toasts.map((toast, index) => (
          <ToastMessage
            key={toast.id}
            ref={toastRefs.current[toast.id]}
            {...toast}
          />
        ))}
      </div>
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error("useToast must be used within a ToastProvider");
  }
  return context;
};
