import React, { useEffect } from "react";
import SignUp from "./SignUp";
import ConfirmSignUp from "./ConfirmSignUp";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Buffer } from "buffer";
import { Typography } from "@mui/material";
import OrganizationSetup from "./OrganizationSetup";
import BillingDetails from "./BillingDetails";
import { Role } from "../../API/XFA_API";
import BillingComplete from "./BillingComplete";

interface SignupFormProps {
  currentStep: number;
  onNextStep: () => void;
  onPreviousStep: () => void;
  onSwitchToLogin: () => void;
  onRefreshRoles: () => void;
  onBillingSkip: () => void;
  signUp: boolean;
  fixedEmail: string | undefined;
  onSignIn: (user: any) => void;
  role: Role | undefined;
}

const SignupForm = (props: SignupFormProps) => {
  const params = useParams();
  const decode = (str: string): string =>
    Buffer.from(str, "base64").toString("binary");
  const usernameFromPath = location.pathname.split("/")[2] || "";
  const [email, setEmail] = React.useState<string>(
    params.username ? decode(params.username) : decode(usernameFromPath),
  );
  const [name, setName] = React.useState<string>(
    params.name ? decode(params.name) : "",
  );
  const [firstName, setFirstName] = React.useState<string>(
    params.firstName ? decode(params.firstName) : "",
  );
  const [organization_id, setOrganizationId] = React.useState<
    string | undefined
  >();
  const [password, setPassword] = React.useState<string>("");
  const { t } = useTranslation();

  const signUp = () => {
    if (props.fixedEmail && !email) {
      setEmail(props.fixedEmail);
    }
    props.onNextStep();
  };

  const verifyEmail = () => {
    props.onRefreshRoles();
    props.onNextStep();
  };

  const organizationSetupComplete = (organization_id: string) => {
    setOrganizationId(organization_id);
    props.onNextStep();
  };

  const billingComplete = () => {
    if (localStorage.getItem("skipPayment") === "true") {
      props.onBillingSkip();
      return;
    }
    props.onNextStep();
  };

  const getTitle = () => {
    if (props.currentStep === 1) {
      return t("login.signup.verifyMail");
    } else if (props.currentStep === 0) {
      return t("login.signup.signUp");
    } else if (props.currentStep === 2) {
      return t("login.signup.setupOrganization");
    } else if (props.currentStep === 3) {
      return t("login.signup.step4Title");
    } else {
      return "";
    }
  };

  useEffect(() => {
    if (organization_id === undefined) {
      setOrganizationId(props.role?.organization.organization_id);
    }
  });

  return (
    <div id="signupForm" data-cy="signup">
      <Typography variant="formtitle">{getTitle()}</Typography>
      {props.currentStep === 0 && (
        <SignUp
          onSignUp={signUp}
          email={email}
          name={name}
          onNameChange={setName}
          onEmailChange={setEmail}
          onFirstNameChange={setFirstName}
          onPasswordChange={setPassword}
          fixedEmail={props.fixedEmail}
          onSwitchToLogin={props.onSwitchToLogin}
        />
      )}
      {props.currentStep === 1 && (
        <ConfirmSignUp
          username={email}
          password={password}
          onSignIn={props.onSignIn}
          onNextStep={verifyEmail}
          onPreviousStep={props.onPreviousStep}
        />
      )}
      {props.currentStep === 2 && (
        <OrganizationSetup
          email={props.role?.email ?? email}
          onNextStep={organizationSetupComplete}
        />
      )}
      {props.currentStep === 3 && (
        <BillingDetails
          onNextStep={billingComplete}
          organization_id={organization_id!}
        />
      )}
      {props.currentStep === 4 && (
        <BillingComplete onNextStep={props.onNextStep} />
      )}
    </div>
  );
};

export default SignupForm;
