import {
  Collapse,
  Typography,
  TextField,
  Divider,
  CircularProgress,
  Button,
  Tooltip,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

interface SettingsBarProps {
  isCollapsibleVisible: boolean;
  daysInput: number | undefined;
  setDaysInput: (days: number) => void;
  isTestYourselfDisabled: boolean;
  testYourselfTooltip: string;
  handleLearnMoreClick: () => void;
  handleTestYourselfClick: () => void;
  sendingMail: boolean;
  mailSent: boolean;
  hideTestYourself?: boolean;
}

export const SettingsBar: React.FC<SettingsBarProps> = ({
  isCollapsibleVisible,
  daysInput,
  setDaysInput,
  isTestYourselfDisabled,
  testYourselfTooltip,
  handleLearnMoreClick,
  handleTestYourselfClick,
  sendingMail,
  mailSent,
  hideTestYourself,
}) => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginTop: 16,
      }}
    >
      <Collapse in={isCollapsibleVisible}>
        <div style={{ marginTop: 8, display: "flex", gap: 8 }}>
          <div
            style={{
              display: "flex",
              gap: 8,
              alignItems: "center",
              marginLeft: "0",
            }}
          >
            <Typography variant="instructions">{t("verify.every")}</Typography>
            <TextField
              style={{ width: 80 }}
              type="number"
              value={daysInput != undefined ? daysInput : 3}
              onChange={(event) => {
                if (parseInt(event.target.value) < 1) {
                  event.target.value = "1";
                }
                setDaysInput(parseInt(event.target.value));
              }}
            />
            <Typography variant="instructions">{t("verify.days")}</Typography>
          </div>
        </div>
      </Collapse>
      <Divider style={{ marginTop: 16 }} />
      <div
        style={{
          display: "flex",
          justifyContent: "right",
          marginTop: 16,
        }}
      >
        {mailSent && (
          <div
            style={{
              marginRight: "16px",
              marginTop: "auto",
              marginBottom: "auto",
            }}
          >
            {t("devices.verificationEmailSent")}
          </div>
        )}
        {sendingMail && (
          <div style={{ display: "flex", marginRight: "16px" }}>
            <CircularProgress
              style={{
                margin: "auto",
                height: 20,
                width: 20,
              }}
            />
          </div>
        )}
        <Button
          variant="outlined"
          onClick={() => {
            handleLearnMoreClick();
          }}
        >
          {t("verify.learnMore")}
        </Button>
        {!hideTestYourself && (
          <Tooltip title={testYourselfTooltip} placement="right">
            <div style={{ display: "flex" }}>
              <Button
                variant="contained"
                disabled={isTestYourselfDisabled}
                onClick={() => {
                  handleTestYourselfClick();
                }}
                style={{ marginLeft: 8, flex: 1 }}
              >
                {t("verify.testYourself")}
              </Button>
            </div>
          </Tooltip>
        )}
      </div>
    </div>
  );
};
