import { Divider } from "@mui/material";
import React, { ReactNode } from "react";
import "./SettingsSection.css";

interface SectionLabelProps {
  title: string;
  description: string;
}

const SectionLabel: React.FC<SectionLabelProps> = ({ title, description }) => {
  return (
    <div className="section-label">
      <div className="organization-title-section">
        <div className="settings-section-header">{title}</div>
        <div className="settings-section-description">{description}</div>
      </div>
    </div>
  );
};

interface SettingsSectionProps {
  title: string;
  description: string;
  children?: ReactNode;
  withoutDivider?: boolean;
  mobileSupport?: boolean;
}

const SettingsSection: React.FC<SettingsSectionProps> = ({
  title,
  description,
  children,
  withoutDivider,
  mobileSupport = false,
}) => {
  return (
    <div>
      <div className={`settings-section ${mobileSupport ? "mobile" : ""}`}>
        <SectionLabel title={title} description={description} />
        {children}
      </div>
      {!withoutDivider && <Divider />}
    </div>
  );
};

export default SettingsSection;
