import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import "../Applications/Applications.css";
import { useDevices } from "../Overview/hooks/useDevices";
import XFA_API, {
  ExternalGroup,
  Organization,
  Policies,
  Role,
  FullOrganization,
  Application,
  ExternalUser,
  Device,
} from "../API/XFA_API";
import {
  Alert,
  Autocomplete,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import { Route, Routes, useLocation } from "react-router-dom";
import { isDiscoveryApplication } from "../../utils";
import VerificationMailPreview from "../../images/verification-mail-preview.png";
import RiskMailPreview from "../../images/risk-mail-preview.png";

import BooleanSetting from "../Applications/NewOrEditPage/BooleanSetting";
import CloseIcon from "@mui/icons-material/Close";
import NavigationService from "../../utils/NavigationService";
import { SettingsBar } from "./SettingsBar";
import { getRecentDevices } from "../Users/UserUtils";

interface AwarenessProps {
  role: Role;
}

const Awareness: React.FC<AwarenessProps> = (props: AwarenessProps) => {
  return (
    <Routes>
      <Route path="" element={<Overview {...props} />} />
    </Routes>
  );
};

interface OverviewProps {
  role: Role;
}

const Overview: React.FC<OverviewProps> = (props: OverviewProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [error, setError] = React.useState<string>("");
  const [loading, setLoading] = React.useState<boolean>(true);
  const [organization, setOrganization] = React.useState<FullOrganization>();

  const [hasDevices, setHasDevices] = useState<boolean>(false);
  const [learnMoreDialogOpen, setLearnMoreDialogOpen] =
    useState<boolean>(false);
  const [learnMoreRiskDialogOpen, setLearnMoreRiskDialogOpen] =
    useState<boolean>(false);
  const [verifyMailSent, setVerifyMailSent] = useState<boolean>(false);
  const [sendingVerifyMail, setSendingVerifyMail] = useState<boolean>(false);
  const [riskMailSent, setRiskMailSent] = useState<boolean>(false);
  const [sendingRiskMail, setSendingRiskMail] = useState<boolean>(false);
  const [policies, setPolicies] = useState<Policies[]>([]);
  const [loadingGroups, setLoadingGroups] = React.useState<boolean>(true);
  const [groups, setGroups] = useState<ExternalGroup[]>([]);

  const refreshOrganization = React.useCallback(async () => {
    setLoading(true);
    try {
      const org = await XFA_API.getOrganizationFull(
        props.role.organization.organization_id,
      );
      if (!org) {
        setError(t("organization.error"));
        return;
      }
      setOrganization(org);
    } catch (error: any) {
      setError(t("organization.error"));
    }
    setLoading(false);
  }, [t, props.role.organization.organization_id]);

  const refreshDevices = React.useCallback(async () => {
    setLoading(true);
    try {
      const devices = await XFA_API.getDevicesForUser(
        props.role.organization.organization_id,
        props.role.email,
      );
      const recentDevices = getRecentDevices(devices);
      setHasDevices((recentDevices && recentDevices.length > 0) ?? false);
    } catch (error: any) {
      setError(t("devices.error"));
    }
    setLoading(false);
  }, []);

  const refreshPolicies = React.useCallback(async () => {
    setLoading(true);
    try {
      const policies = await XFA_API.getPolicies(
        props.role.organization.organization_id,
      );
      setPolicies(policies ?? []);
    } catch (error: any) {
      setError(t("policies.error"));
    }
    setLoading(false);
  }, []);

  const [configuration, setConfiguration] = React.useState<
    string | undefined
  >();

  const updateOrganization = useCallback(
    (organization: undefined | FullOrganization, configuration?: string) => {
      setOrganization(organization);
      if (!organization) {
        return;
      }

      // if scope is needed - turn off notifications if not provided
      if (configuration === "scoped" && !isScopeDefined(organization)) {
        organization.notify_discovered_devices = false;
        organization.notify_unsafe_devices = false;
      }

      XFA_API.updateOrganization(
        organization.organization_id,
        organization,
      ).catch(() => {
        //TODO show error -> Toast?
        refreshOrganization();
      });
    },
    [],
  );

  const refreshGroups = useCallback(async () => {
    setLoadingGroups(true);
    try {
      const applications = await XFA_API.getApplications(
        props.role.organization.organization_id,
      );
      const filteredApplications = applications.filter((app) =>
        isDiscoveryApplication(app),
      );
      const discoveryGroups: ExternalGroup[] = [];
      for (const application of filteredApplications) {
        const groups = await XFA_API.getGroupsDiscoveryIntegration(
          props.role.organization.organization_id,
          application.ApplicationID,
        );
        if (groups.length > 0) {
          for (const group of groups) {
            const newGroup: ExternalGroup = {
              name: `${application.Name} - ${group.Name}`,
              id: group.ID,
            };
            discoveryGroups.push(newGroup);
          }
        }
      }
      setLoadingGroups(false);
      setGroups(discoveryGroups);
    } catch (error: any) {
      console.error("Error fetching groups", error);
      setGroups([]);
      setError(t("verify.groupsError"));
      setLoadingGroups(false);
    }
  }, [t, props.role.organization.organization_id]);

  const SendVerifyTestEmail = async () => {
    localStorage.setItem("verifyMail", JSON.stringify(true));
    setSendingVerifyMail(true);

    XFA_API.sendVerificationRequestForUser(
      props.role.organization.organization_id,
      props.role.email,
    )
      .then(() => {
        setVerifyMailSent(true);
        setTimeout(() => {
          //remove confirmation message after 3 seconds
          setVerifyMailSent(false);
        }, 3000);
      })
      .catch((e) => {
        setError(e.message);
      })
      .finally(() => {
        setSendingVerifyMail(false);
      });
  };

  const SendRiskTestEmail = async () => {
    setSendingRiskMail(true);
    XFA_API.sendRiskRequestForUser(
      props.role.organization.organization_id,
      props.role.email,
    )
      .then(() => {
        setRiskMailSent(true);
        setTimeout(() => {
          //remove confirmation message after 3 seconds
          setRiskMailSent(false);
        }, 3000);
      })
      .catch((e) => {
        setError(e.message);
      })
      .finally(() => {
        setSendingRiskMail(false);
      });
  };

  const getUsersInGroup = React.useCallback(
    async (group: ExternalGroup, applications: Application[]) => {
      //try to get users in group with any of the applications //future: use tenant id when available
      for (const application of applications) {
        try {
          const users = await XFA_API.getUsersGroupsDiscoveryIntegration(
            props.role.organization.organization_id,
            application.ApplicationID,
            group.id,
          );

          if (users) {
            return users;
          }
        } catch (error: any) {
          continue;
        }
      }
      //no connection to group future: show error with tenant id not connect4ed
    },
    [props.role.organization.organization_id],
  );

  const [usersInGroups, setUsersInGroups] = React.useState<ExternalUser[]>([]);
  const [loadingUsersInGroups, setLoadingUsersInGroups] = React.useState(false);

  React.useEffect(() => {
    if (!organization) {
      return;
    }

    if (
      organization.notify_only_groups &&
      organization.notify_only_groups.length > 0
    ) {
      setLoadingUsersInGroups(true);
      XFA_API.getApplications(organization.organization_id).then(
        async (applications) => {
          // Filter for relevant applications
          const filteredApplications = applications.filter((app) =>
            isDiscoveryApplication(app),
          );

          // Process groups in parallel
          const groupUsersPromises = organization.notify_only_groups?.map(
            async (group) => {
              const users = await getUsersInGroup(group, filteredApplications);
              return users || [];
            },
          );

          if (!groupUsersPromises) {
            return;
          }

          const results = await Promise.all(groupUsersPromises);

          // Flatten the results into `usersInGroups`
          const allUsers = results.flat();

          // Deduplicate by email or ID
          const uniqueUsers = allUsers.filter((user, index, self) => {
            return user.email
              ? self.findIndex((t) => t.email === user.email) === index
              : self.findIndex((t) => t.id === user.id) === index;
          });

          setUsersInGroups(uniqueUsers);
          setLoadingUsersInGroups(false);
        },
      );
    } else {
      setUsersInGroups([]);
      setLoadingUsersInGroups(false);
    }
    //eslint-disable-next-line
  }, [organization?.notify_only_groups]);

  const {
    devices,
    loading: devicesLoading,
    error: devicesError,
  } = useDevices(props.role, organization?.default_policy_id);

  const isScopeDefined = React.useCallback(
    (organization: undefined | Organization | FullOrganization) => {
      if (!organization) {
        return false;
      }
      if (
        organization.notify_no_managed_devices ||
        organization.notify_no_desktop_devices ||
        organization.notify_no_mobile_devices ||
        (organization.notify_only_groups &&
          organization.notify_only_groups.length > 0)
      ) {
        return true;
      }
      return false;
    },
    [],
  );

  const [usersInScope, setUsersInScope] = React.useState<string[]>([]);
  const [devicesInScope, setDevicesInScope] = React.useState<Device[]>([]);
  const [desktopsInScope, setDesktopsInScope] = React.useState<Device[]>([]);
  const [mobilesInScope, setMobilesInScope] = React.useState<Device[]>([]);

  React.useEffect(() => {
    if (configuration === "off") {
      setUsersInScope([]);
      setDevicesInScope([]);
      setDesktopsInScope([]);
      setMobilesInScope([]);
      return;
    }

    const activeDevices = (devices || [])?.filter(
      (device) =>
        device.timestamp_submitted &&
        Date.now() / 1000 - device.timestamp_submitted < 30 * 24 * 60 * 60,
    );
    let scopedDevices = activeDevices;
    if (organization?.notify_no_managed_devices) {
      scopedDevices = scopedDevices.filter((device) => device.managed !== true);
    }
    if (organization?.notify_no_desktop_devices) {
      scopedDevices = scopedDevices.filter(
        (device) =>
          device.os_name !== "iOS" &&
          device.os_name !== "Android" &&
          device.os_name !== "iPadOS",
      );
    }
    if (organization?.notify_no_mobile_devices) {
      scopedDevices = scopedDevices.filter(
        (device) =>
          device.os_name === "iOS" ||
          device.os_name === "Android" ||
          device.os_name === "iPadOS",
      );
    }
    //get all unique emails (users) from devices
    const activeUsers = scopedDevices
      .map((device) => device.email)
      .filter((value, index, self) => self.indexOf(value) === index);

    let scopedUsers = activeUsers;
    if (
      organization?.notify_only_groups &&
      organization.notify_only_groups.length > 0
    ) {
      scopedUsers = activeUsers.filter((user) =>
        usersInGroups.find(
          (groupUser) => groupUser.email.toLowerCase() === user.toLowerCase(),
        ),
      );
    } else {
      scopedUsers = activeUsers;
    }
    scopedDevices = scopedDevices.filter((device) =>
      scopedUsers.includes(device.email),
    );

    setUsersInScope(scopedUsers);
    setDevicesInScope(scopedDevices);
    setDesktopsInScope(
      scopedDevices.filter(
        (device) =>
          device.os_name !== "iOS" &&
          device.os_name !== "Android" &&
          device.os_name !== "iPadOS",
      ),
    );
    setMobilesInScope(
      scopedDevices.filter(
        (device) =>
          device.os_name === "iOS" ||
          device.os_name === "Android" ||
          device.os_name === "iPadOS",
      ),
    );
  }, [configuration, devices, organization, usersInGroups]);

  const handleLearnMoreClick = (risk: boolean) => {
    if (risk) {
      setLearnMoreRiskDialogOpen(true);
      return;
    }
    localStorage.setItem("verifyMail", JSON.stringify(true));
    setLearnMoreDialogOpen(true);
  };

  const handleLearnMoreDialogClose = () => {
    setLearnMoreDialogOpen(false);
    setLearnMoreRiskDialogOpen(false);
  };

  React.useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const showLearnMore = searchParams.get("showLearnMore");
    if (showLearnMore === "true") {
      setLearnMoreDialogOpen(true);
      localStorage.setItem("verifyMail", JSON.stringify(true));
    }
    refreshOrganization();
    refreshDevices();
    refreshPolicies();
    refreshGroups();
  }, [location.search, refreshOrganization]);

  React.useEffect(() => {
    if (!organization) {
      setConfiguration(undefined);
      return;
    }

    //check to update configuration according to organization settings
    if (
      organization?.notify_discovered_devices ||
      organization?.notify_unsafe_devices
    ) {
      if (
        (organization.notify_only_groups &&
          organization.notify_only_groups.length > 0) ||
        organization?.notify_no_managed_devices ||
        organization?.notify_no_desktop_devices ||
        organization?.notify_no_mobile_devices
      ) {
        setConfiguration("scoped");
        return;
      } else {
        setConfiguration("all");
        return;
      }
    }

    if (!configuration) {
      setConfiguration("off");
    }
  }, [organization, configuration]);

  return (
    <div>
      <div className="flex">
        <Typography variant="pagetitle" color="primary">
          {t("navigation.awareness")}
        </Typography>
      </div>
      <Divider style={{ marginTop: 16 }} />
      <div className="w-full" data-cy="applications">
        {error && (
          <div style={{ marginBottom: 8, marginTop: 16, width: "50%" }}>
            <Alert
              severity="error"
              onClose={() => {
                setError("");
              }}
            >
              {error}
            </Alert>
          </div>
        )}
        {loading ? (
          <div className="center">
            <CircularProgress />
          </div>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 8,
              }}
            >
              <div
                className="section awarenessCard"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ display: "flex", width: "100%" }}>
                  <ToggleButtonGroup
                    color="primary"
                    value={configuration}
                    exclusive
                    onChange={(event, newValue) => {
                      if (newValue === "off") {
                        updateOrganization(
                          {
                            ...organization!,
                            notify_discovered_devices: false,
                            notify_unsafe_devices: false,
                            notify_no_managed_devices: false,
                            notify_no_desktop_devices: false,
                            notify_no_mobile_devices: false,
                            notify_only_groups: [],
                          },
                          "off",
                        );
                        setConfiguration("off");
                      } else if (newValue === "scoped") {
                        updateOrganization(
                          {
                            ...organization!,
                            notify_discovered_devices: false,
                            notify_unsafe_devices: false,
                          },
                          "scoped",
                        );
                        setConfiguration("scoped");
                      } else if (newValue === "all") {
                        updateOrganization(
                          {
                            ...organization!,
                            notify_no_managed_devices: false,
                            notify_no_desktop_devices: false,
                            notify_no_mobile_devices: false,
                            notify_only_groups: [],
                          },
                          "all",
                        );
                        setConfiguration("all");
                      }
                    }}
                    aria-label="Platform"
                  >
                    <ToggleButton value="off">{t("verify.off")}</ToggleButton>
                    <ToggleButton value="scoped">
                      {t("verify.scoped")}
                    </ToggleButton>
                    <ToggleButton value="all">{t("verify.all")}</ToggleButton>
                  </ToggleButtonGroup>
                </div>
                {configuration === "scoped" && (
                  <div>
                    <div style={{ marginTop: 16 }} />
                    <BooleanSetting
                      label={t("verify.notifyNoManagedDevicesLabel")}
                      explanation={t(
                        "verify.notifyNoManagedDevicesDescription",
                      )}
                      value={organization?.notify_no_managed_devices ?? false}
                      onChange={(value) => {
                        updateOrganization(
                          {
                            ...organization!,
                            notify_no_managed_devices: value,
                          },
                          configuration,
                        );
                      }}
                    />
                    <div style={{ marginTop: 16 }} />
                    <BooleanSetting
                      label={t("verify.notifyNoDesktopDevicesLabel")}
                      explanation={t(
                        "verify.notifyNoDesktopDevicesDescription",
                      )}
                      value={organization?.notify_no_desktop_devices ?? false}
                      onChange={(value) => {
                        updateOrganization(
                          {
                            ...organization!,
                            notify_no_desktop_devices: value,
                          },
                          configuration,
                        );
                      }}
                    />
                    <div style={{ marginTop: 16 }} />
                    <BooleanSetting
                      label={t("verify.notifyNoMobileDevicesLabel")}
                      explanation={t("verify.notifyNoMobileDevicesDescription")}
                      value={organization?.notify_no_mobile_devices ?? false}
                      onChange={(value) => {
                        updateOrganization(
                          {
                            ...organization!,
                            notify_no_mobile_devices: value,
                          },
                          configuration,
                        );
                      }}
                    />
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                      }}
                    >
                      <Typography variant="setting" style={{ marginTop: 16 }}>
                        {t("verify.groups")}
                      </Typography>
                      <div
                        style={{
                          marginLeft: "auto",
                        }}
                      />
                    </div>
                    <FormControl
                      size="small"
                      style={{
                        marginTop: 8,
                        width: "100%",
                      }}
                    >
                      <Autocomplete
                        disabled={loadingGroups || groups.length === 0}
                        multiple
                        options={groups}
                        getOptionLabel={(group) => group.name}
                        value={organization?.notify_only_groups ?? []}
                        onChange={(event, newValue) => {
                          updateOrganization(
                            {
                              ...organization!,
                              notify_only_groups: newValue,
                            },
                            configuration,
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            placeholder={
                              loadingGroups
                                ? t("verify.loadingGroups")
                                : groups.length > 0
                                  ? t("verify.search")
                                  : t("verify.noGroups")
                            }
                          />
                        )}
                        loading={loadingGroups}
                        renderOption={(props, option) => (
                          <li {...props} key={option.id}>
                            {option.name}
                          </li>
                        )}
                      />
                    </FormControl>
                    <Divider style={{ marginTop: 16 }} />
                    {loadingUsersInGroups ? (
                      <div className="center">
                        <CircularProgress size={20} />
                      </div>
                    ) : (
                      <span>
                        <Typography variant="setting" style={{ marginTop: 16 }}>
                          {t("verify.scopePreviewTitle")}
                        </Typography>
                        <div
                          style={{
                            display: "grid",
                            gridTemplateColumns: "180px auto",
                            gap: "8px",
                            marginTop: 8,
                          }}
                        >
                          <Typography variant="instructions">
                            {t("verify.usersInScope")}
                          </Typography>
                          <Typography variant="instructions">
                            {usersInScope.length}
                          </Typography>
                          <Typography variant="instructions">
                            {t("verify.devicesInScope")}
                          </Typography>
                          <Typography variant="instructions">
                            {devicesInScope.length}
                          </Typography>
                          <Typography variant="instructions">
                            {"- "}
                            {t("verify.desktopsInScope")}{" "}
                          </Typography>
                          <Typography variant="instructions">
                            {desktopsInScope.length}
                          </Typography>
                          <Typography variant="instructions">
                            {"- "}
                            {t("verify.mobilesInScope")}
                          </Typography>
                          <Typography variant="instructions">
                            {mobilesInScope.length}
                          </Typography>
                        </div>
                      </span>
                    )}
                  </div>
                )}
              </div>
              <div className="section awarenessCard">
                <BooleanSetting
                  label={t("verify.autoVerifyMail")}
                  disabled={
                    configuration === "off" ||
                    (configuration === "scoped" &&
                      !isScopeDefined(organization))
                  }
                  disabledTooltip={t("verify.setScopeFirst")}
                  explanation={t("verify.autoVerifyMailDescription")}
                  value={organization?.notify_discovered_devices ?? false}
                  onChange={(value) => {
                    updateOrganization(
                      {
                        ...organization!,
                        notify_discovered_devices: value,
                        notify_discovered_devices_frequency_in_days:
                          organization?.notify_discovered_devices_frequency_in_days ??
                          3,
                      },
                      configuration,
                    );
                  }}
                />
                <SettingsBar
                  isCollapsibleVisible={
                    organization?.notify_discovered_devices ?? false
                  }
                  daysInput={
                    organization?.notify_discovered_devices_frequency_in_days
                  }
                  setDaysInput={(value) =>
                    updateOrganization(
                      {
                        ...organization!,
                        notify_discovered_devices_frequency_in_days: value,
                      },
                      configuration,
                    )
                  }
                  isTestYourselfDisabled={!hasDevices}
                  testYourselfTooltip={
                    !hasDevices
                      ? t("verify.testNoDevices")
                      : t("verify.testVerifiedDevices")
                  }
                  handleLearnMoreClick={() => handleLearnMoreClick(false)}
                  handleTestYourselfClick={SendVerifyTestEmail}
                  sendingMail={sendingVerifyMail}
                  mailSent={verifyMailSent}
                />
              </div>
              <div className="section awarenessCard">
                <BooleanSetting
                  label={t("verify.autoRiskMail")}
                  explanation={t("verify.autoRiskMailDescription")}
                  disabled={
                    configuration === "off" ||
                    (configuration === "scoped" &&
                      !isScopeDefined(organization))
                  }
                  disabledTooltip={t("verify.setScopeFirst")}
                  value={organization?.notify_unsafe_devices ?? false}
                  onChange={(value) => {
                    updateOrganization(
                      {
                        ...organization!,
                        notify_unsafe_devices: value,
                        notify_unsafe_devices_frequency_in_days:
                          organization?.notify_unsafe_devices_frequency_in_days ??
                          3,
                      },
                      configuration,
                    );
                  }}
                />
                <SettingsBar
                  isCollapsibleVisible={
                    organization?.notify_unsafe_devices ?? false
                  }
                  daysInput={
                    organization?.notify_unsafe_devices_frequency_in_days
                  }
                  setDaysInput={(value) =>
                    updateOrganization(
                      {
                        ...organization!,
                        notify_unsafe_devices_frequency_in_days: value,
                      },
                      configuration,
                    )
                  }
                  isTestYourselfDisabled={!hasDevices}
                  testYourselfTooltip={
                    !hasDevices
                      ? t("verify.testNoDevices")
                      : t("verify.testRiskDevices")
                  }
                  handleLearnMoreClick={() => handleLearnMoreClick(true)}
                  handleTestYourselfClick={SendRiskTestEmail}
                  sendingMail={sendingRiskMail}
                  mailSent={riskMailSent}
                />
              </div>
            </div>
            {policies.length > 0 && (
              <div
                className="section awarenessCard"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="setting" style={{ margin: "auto 0" }}>
                  {t("verify.defaultPolicyTitle")}
                </Typography>
                <Button
                  variant="text"
                  style={{ flexShrink: 0 }}
                  onClick={() => {
                    NavigationService.navigateToPolicies();
                  }}
                >
                  {t("verify.defaultPolicyButton")}
                </Button>
              </div>
            )}
          </>
        )}
        <Dialog
          open={learnMoreDialogOpen || learnMoreRiskDialogOpen}
          onClose={handleLearnMoreDialogClose}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>
            {learnMoreDialogOpen
              ? t("verify.learnMoreDialogTitle")
              : t("verify.learnMoreDialogRiskTitle")}
            <IconButton
              aria-label="close"
              onClick={handleLearnMoreDialogClose}
              style={{ position: "absolute", right: 8, top: 8 }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Typography variant="body1">
              {learnMoreDialogOpen
                ? t("verify.learnMoreDialogDescription1")
                : t("verify.learnMoreDialogRiskDescription1")}
            </Typography>
            <Typography variant="body1" style={{ marginTop: 8 }}>
              {learnMoreDialogOpen
                ? t("verify.learnMoreDialogDescription2")
                : t("verify.learnMoreDialogRiskDescription2")}
            </Typography>
            {learnMoreDialogOpen && (
              <Typography variant="body1" style={{ marginTop: 8 }}>
                {t("verify.learnMoreDialogDescription3")}
              </Typography>
            )}
            <img
              src={
                learnMoreDialogOpen ? VerificationMailPreview : RiskMailPreview
              }
              alt="Learn More"
              style={{ width: "100%", marginTop: 16, borderRadius: 16 }}
            />
          </DialogContent>

          <DialogActions>
            <Tooltip
              title={
                !hasDevices
                  ? t("verify.testNoDevices")
                  : learnMoreRiskDialogOpen
                    ? t("verify.testRiskDevices")
                    : t("verify.testVerifiedDevices")
              }
              placement="right"
            >
              <span>
                <Button
                  variant="contained"
                  disabled={!hasDevices}
                  onClick={() => {
                    handleLearnMoreDialogClose();
                    learnMoreRiskDialogOpen
                      ? SendRiskTestEmail()
                      : SendVerifyTestEmail();
                  }}
                >
                  {t("verify.testYourself")}
                </Button>
              </span>
            </Tooltip>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};
export default Awareness;
