import React from "react";
import { Drawer, Divider, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DeviceDetailsCard from "./DeviceDetailsCard";
import XFA_API, { Device, Policies, Shadow } from "../API/XFA_API";
import { useTranslation } from "react-i18next";
import StickyFooter from "./StickyFooter";
import { getUserSecurityStatus } from "./UserUtils";
import "./UserDevicesSidebar.css";

interface UserDevicesSidebarProps {
  open: boolean;
  policy?: Policies | undefined;
  onClose: () => void;
  user: Shadow;
  activeDevices: Device[];
  organizationId: string;
  onDeleteDevice: () => void;
}

const UserDevicesSidebar: React.FC<UserDevicesSidebarProps> = ({
  open,
  policy,
  onClose,
  user,
  activeDevices: initialActiveDevices,
  organizationId,
  onDeleteDevice,
}) => {
  const { t } = useTranslation();
  const [mfaDevices, setMfaDevices] = React.useState<string[]>([]);
  const [activeDevices, setActiveDevices] =
    React.useState<Device[]>(initialActiveDevices);
  const securityStatus = getUserSecurityStatus(activeDevices, policy);

  React.useEffect(() => {
    XFA_API.getMfaDevices(organizationId, user.email)
      .then((devices) => {
        setMfaDevices(devices);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [user]);

  const handleDeleteDevice = (deviceId: string) => {
    // Update the state by removing the device from the activeDevices array
    setActiveDevices((prevDevices) =>
      prevDevices.filter((device) => device.device_id !== deviceId),
    );
    onDeleteDevice();
  };

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100vh",
          backgroundColor: "var(--color-gray-50)",
          width: "min(480px, 100vw)",
        }}
      >
        <div
          style={{
            flexGrow: 1,
            overflowY: "auto",
            padding: 20,
            paddingBottom: "100px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div className="sidebar-title">{t("Users.deviceStatus")}</div>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <div className="sidebar-active-devices"></div>
          {activeDevices.length + " " + t("Users.past30days")}
          <Divider style={{ margin: "10px 0" }} />
          {mfaDevices &&
            activeDevices.map((device) => (
              <DeviceDetailsCard
                key={device.device_id}
                device={device}
                policy={policy}
                mfaEnabled={mfaDevices.includes(device.device_id)}
                onDelete={() => handleDeleteDevice(device.device_id)}
              />
            ))}
        </div>

        <StickyFooter user={user} securityStatus={securityStatus} />
      </div>
    </Drawer>
  );
};

export default UserDevicesSidebar;
