import React from "react";
import SettingsSection from "../../../Settings/SettingsSection";
import { useTranslation } from "react-i18next";

interface RiskPeriodInputProps {
  value: number;
  onChange: (value: number) => void;
  title: string;
  description: string;
}

const RiskPeriodInput: React.FC<RiskPeriodInputProps> = ({
  value,
  onChange,
  title,
  description,
}) => {
  const { t } = useTranslation();
  return (
    <div className="risk-period-input">
      <SettingsSection title={title} description={description}>
        <input
          type="number"
          value={value}
          onChange={(e) => onChange(parseInt(e.target.value) || 0)}
          min="0"
        />
        <label>{t("policies.editPolicy.days")}</label>
      </SettingsSection>
    </div>
  );
};

export default RiskPeriodInput;
