let mollieInstance = null;

export function initializeMollieComponents(profileId, locale) {
  if (!window.Mollie) {
    console.error("Mollie script not loaded");
    return null;
  }

  
  if (!mollieInstance) {
    mollieInstance = window.Mollie(profileId, {
      locale: locale, 
      testmode: true,
    });
  }

  const options = {
    styles: {
      base: {
        color: "var(--color-grey-900)",
        fontSize: "16px",
        "::placeholder": {
          color: "rgba(68, 68, 68, 0.5)",
        },
        padding: "0px",
      },
    },
  };

  const cardHolder = mollieInstance.createComponent("cardHolder", options);
  const cardNumber = mollieInstance.createComponent("cardNumber", options);
  const expiryDate = mollieInstance.createComponent("expiryDate", options);
  const verificationCode = mollieInstance.createComponent("verificationCode", options);

  function mountComponents() {
    cardHolder.mount("#card-holder");
    cardNumber.mount("#card-number");
    expiryDate.mount("#expiry-date");
    verificationCode.mount("#verification-code");
  }

  function unmountComponents() {
    cardHolder.unmount();
    cardNumber.unmount();
    expiryDate.unmount();
    verificationCode.unmount();
  }

  return { mountComponents, unmountComponents, mollie: mollieInstance };
}

export async function createMollieToken(name) {
  if (!mollieInstance) {
    throw new Error("Mollie is not initialized");
  }

  try {
    const { token, error } = await mollieInstance.createToken();

    if (error) {
      return { error: error.message };
    }

    return { token };
  } catch (err) {
    console.error("Payment error:", err);
    return { error: "An unexpected error occurred." };
  }
}
