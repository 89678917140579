import React, { useState, useEffect, useCallback } from "react";
import {
  Typography,
  FormControl,
  Input,
  Chip,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Application } from "../../API/XFA_API";

interface FilteredComponentProps {
  application: Application;
  creatingOrUpdating: boolean;
  setApplication: (app: Application) => void;
}

const FilteredComponent: React.FC<FilteredComponentProps> = ({
  application,
  creatingOrUpdating,
  setApplication,
}) => {
  const { t } = useTranslation();
  const [emailInput, setEmailInput] = useState<string>("");
  const [emailInputValid, setEmailInputValid] = useState<boolean>(true);

  const removeEmail = useCallback(
    (email: string) => {
      if (application?.FilteredEmail) {
        const filteredEmail = application.FilteredEmail.filter(
          (e: string) => e.toLowerCase() !== email.toLowerCase(),
        );
        setApplication({
          ...application,
          FilteredEmail: filteredEmail.length > 0 ? filteredEmail : undefined,
        });
      }
    },
    [application, setApplication],
  );

  const addEmail = useCallback(
    (email: string) => {
      email = email.trim().toLowerCase();

      if (application?.FilteredEmail?.includes(email)) {
        return;
      }

      const newEmails = application?.FilteredEmail
        ? [...application.FilteredEmail, email]
        : [email];

      setApplication({
        ...application,
        FilteredEmail: newEmails,
      });
    },
    [application, setApplication],
  );

  const isEmailOrDomain = useCallback((email: string) => {
    const pattern = new RegExp(
      /^[A-Z][A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,63}$/i,
    );
    const domainPattern = new RegExp(/^[A-Z][A-Z0-9.-]+\.[A-Z]{2,63}$/i);

    return pattern.test(email.trim()) || domainPattern.test(email.trim());
  }, []);

  const handleEmailInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const input = e.target.value;
      if (input) {
        setEmailInput(input);
      } else {
        setEmailInput("");
      }
    },
    [],
  );

  useEffect(() => {
    if (emailInput.indexOf(",") > -1) {
      const parts = emailInput.split(",");
      for (let i = 0; i < parts.length; i++) {
        if (isEmailOrDomain(parts[i])) {
          addEmail(parts[i]);
        } else {
          if (i !== 0) {
            setEmailInput(parts.slice(i).join(", "));
          }
          return;
        }
      }
      setEmailInput("");
    }
  }, [emailInput, addEmail, isEmailOrDomain]);

  useEffect(() => {
    if (isEmailOrDomain(emailInput)) {
      setEmailInputValid(true);
    } else {
      setEmailInputValid(false);
    }
  }, [emailInput, isEmailOrDomain]);

  return (
    <div className="section">
      <div style={{ marginBottom: 24 }}>
        <Typography variant="formsectiontitle">
          {t("applications.policies.filters")}
        </Typography>
        <Typography variant="instructions">
          {t("applications.policies.filtersInstructions")}
        </Typography>
      </div>
      <div style={{ display: "flex", flexDirection: "column", gap: 24 }}>
        {application.OAuthConfiguration === undefined && (
          <>
            <div>
              <FormControl fullWidth={true}>
                <Typography variant="setting">
                  {t("applications.policies.emailFilter")}
                </Typography>
                <Typography variant="instructions">
                  {t("applications.policies.emailFilterExplanation")}
                </Typography>
                <Input
                  style={{ display: "block" }}
                  error={!emailInputValid && emailInput !== ""}
                  startAdornment={
                    <div
                      style={{
                        marginBottom:
                          application?.FilteredEmail &&
                          application?.FilteredEmail.length > 0
                            ? 10
                            : undefined,
                      }}
                    >
                      {application?.FilteredEmail &&
                        application.FilteredEmail.map((email: string) => (
                          <Chip
                            key={email}
                            label={email}
                            onDelete={() => removeEmail(email)}
                            style={{ margin: 5 }}
                          />
                        ))}
                    </div>
                  }
                  value={emailInput}
                  onKeyDown={({ nativeEvent }) => {
                    if (
                      (nativeEvent.key === "Enter" ||
                        nativeEvent.key === " ") &&
                      emailInput.trim() !== ""
                    ) {
                      addEmail(emailInput);
                      setEmailInput("");
                    } else if (
                      nativeEvent.key === "Backspace" &&
                      emailInput === ""
                    ) {
                      if (
                        application &&
                        application.FilteredEmail &&
                        application?.FilteredEmail?.length > 0
                      ) {
                        const lastEmail =
                          application.FilteredEmail[
                            application.FilteredEmail.length - 1
                          ];
                        removeEmail(lastEmail);
                        setEmailInput(lastEmail);
                      }
                    }
                  }}
                  onChange={handleEmailInput}
                />
              </FormControl>
            </div>
          </>
        )}
        <div>
          <FormControlLabel
            control={
              <Checkbox
                checked={application.FilterMobile}
                disabled={creatingOrUpdating}
                onChange={(event) => {
                  setApplication({
                    ...application,
                    FilterMobile: event.target.checked,
                  });
                }}
              />
            }
            label={<>{t("applications.createDialog.filterMobile")}</>}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={application.FilterDesktop}
                disabled={creatingOrUpdating}
                onChange={(event) => {
                  setApplication({
                    ...application,
                    FilterDesktop: event.target.checked,
                  });
                }}
              />
            }
            label={<>{t("applications.createDialog.filterDesktop")}</>}
          />
        </div>
      </div>
    </div>
  );
};

export default FilteredComponent;
