import { useState, useEffect, useCallback } from "react";
import XFA_API, { Policies, Role } from "../../API/XFA_API";
import { useTranslation } from "react-i18next";

export const usePolicies = (role: Role, isBlocked: boolean) => {
  const [policies, setPolicies] = useState<Policies[] | undefined>(undefined);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const { t } = useTranslation();

  const fetchPolicies = useCallback(async () => {
    if (isBlocked) return;
    setLoading(true);
    try {
      const fetchedPolicies = await XFA_API.getPolicies(
        role.organization.organization_id,
      );
      setPolicies(fetchedPolicies);
    } catch (error) {
      setError(t("applications.error"));
    } finally {
      setLoading(false);
    }
  }, [isBlocked, role.organization.organization_id, t]);

  useEffect(() => {
    fetchPolicies();
  }, [fetchPolicies]);

  return { policies, loading, error, refresh: fetchPolicies };
};
