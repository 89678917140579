import React from "react";
import { useTranslation } from "react-i18next";
import {
  CircularProgress,
  Typography
} from "@mui/material";
import { BillingSettings, Role } from "../../API/XFA_API";

interface BillingPlanProps {
  role: Role;
  settings: BillingSettings | undefined;
  settingsLoading: boolean;
}

const BillingPlan: React.FC<BillingPlanProps> = (props: BillingPlanProps) => {
  const { t } = useTranslation();

  return (<>
      <Typography className="paperTitle" color="primary">
        {t("Billing.billingPlanTitle")}
      </Typography>

      {props.settings && !props.settingsLoading ? (<>
        <Typography className="paperContent">{t("Billing.billingPlan")}</Typography>
        <Typography className="paperContentBold">
          {!props.settings.PerUserPrice ? (
              <>
                {props.settings.InsightPrice}{" "}
                {t("Billing.pricingStructureDevice")}
              </>
            ) : (
            <>
              {props.settings.PerUserPrice}{" "}
              {t("Billing.pricingStructureUser")}
            </>
          )}</Typography>
        <Typography className="paperContentSmall">{!props.settings.PerUserPrice && props.settings.FreeDevices > 0 ? (<>{t("Billing.firstXDevicesFree", {
          count: props.settings.FreeDevices,
        })} {t("Billing.firstMonthFree")}</>) : <>{t("Billing.firstMonthFreeCap")}</>}</Typography>
        {props.settings.TrialEndDate != "" ? (<>
          <Typography className="paperContent" style={{ marginTop: "24px" }}>{t("Billing.startDate")}</Typography>
          <Typography className="paperContentBold">
            {props.settings.TrialEndDate.substring(6).replace(/^0+/, '')}{" "}
            {t("months." + props.settings.TrialEndDate.substring(4, 6))}{" "}
            {props.settings.TrialEndDate.substring(0, 4)}
          </Typography>
        </>) : (<></>)}</>) : props.settingsLoading ? (
        <CircularProgress
          size="20px"
          style={{ marginRight: "10px" }}
          color="primary"
        />) : (
        <></>
      )}
    </>
  );
};

export default BillingPlan;
