import React from "react";
import { useTranslation } from "react-i18next";
import { FormControl, Typography } from "@mui/material";
import { Application } from "../../../API/XFA_API";
import CopyIdComponent from "../CopyIdComponent";

interface MicrosoftPageProps {
  creating: boolean;
  applicationId: string;
  application: Application;
  setApplication: (application: Application) => void;
  vertical: boolean;
}

const MicrosoftEAMPage: React.FC<MicrosoftPageProps> = (
  props: MicrosoftPageProps,
) => {
  const { t } = useTranslation();

  return (
    <div>
      <div
        className={
          props.vertical ? "verticalSelectionSection" : "selectionSection"
        }
      >
        <div style={{ marginBottom: 16 }}>
          <Typography variant="formsectiontitle">
            {t("applications.createDialog.idpTitleMicrosoft")}
          </Typography>
          <Typography variant="instructions">
            {t("applications.createDialog.idpContentMicrosoft")}
          </Typography>
        </div>
        <div>
          <FormControl fullWidth={true}>
            <CopyIdComponent
              title={t("applications.createDialog.MicrosoftClientID")}
              value={props.applicationId}
              disabled={false}
            />
            <CopyIdComponent
              title={t("applications.createDialog.MicrosoftDiscoveryURL")}
              value={`https://device-api.xfa.tech/${props.applicationId}/oauth2/.well-known/oidc-configuration`}
              disabled={false}
            />
            <CopyIdComponent
              title={t("applications.createDialog.MicrosoftAppID")}
              value={"79af80c6-c777-42cf-90ca-27ea04087e58"}
              disabled={false}
            />
          </FormControl>
        </div>
      </div>
    </div>
  );
};

export default MicrosoftEAMPage;
