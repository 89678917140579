import React, { useRef, useState } from "react";
import UploadIcon from "../../images/upload-icon.png";
import "./FileUpload.css";

interface FileUploadProps {
  onFileSelect: (file: File) => void;
  disabled?: boolean;
  t: (key: string) => string;
}

const FileUpload: React.FC<FileUploadProps> = ({
  onFileSelect,
  disabled = false,
  t,
}) => {
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const acceptedTypes = [
    "image/svg+xml",
    "image/png",
    "image/jpeg",
    "image/gif",
  ];

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];

      if (acceptedTypes.includes(file.type)) {
        setError(null);
        onFileSelect(file);
      } else {
        setError(
          "Invalid file type. Please upload an image (SVG, PNG, JPG, GIF).",
        );
      }
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      onFileSelect(e.dataTransfer.files[0]);
    }
  };

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div
      className={`upload-container ${isDragging ? "dragging" : ""} ${error ? "error" : ""} ${disabled ? "disabled" : ""}`}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      onClick={handleClick}
    >
      <input
        type="file"
        accept=".svg, .png, .jpg, .gif"
        onChange={handleFileSelect}
        ref={fileInputRef}
        className="upload-input"
        disabled={disabled}
      />
      <div className="upload-content">
        <div className="upload-icon">
          <img src={UploadIcon} alt="search" className="upload-icon" />
        </div>
        <div>
          <div className="upload-text">
            <strong>{t("settings.upload")}</strong> {t("settings.dragDrop")}
          </div>
          <div className="upload-hint">
            {error
              ? t("settings.uploadFailed")
              : t("settings.uploadInstructions")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FileUpload;
