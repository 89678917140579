import React from "react";
import { Typography, Button, Box } from "@mui/material";
import "./StageCard.css";
import XFATag from "../../General/XfaTag/XfaTag";
import ActiveIcon from "../../../icons/active.svg";
import AccessTime from "../../../icons/AccessTimeGrey.svg";
import { t } from "i18next";

interface StageCardProps {
  title: string;
  active: boolean;
  description: string;
  children: React.ReactNode;
  actionText?: string;
  actionTime?: string;
  onActionClick?: () => void;
}

const StageCard: React.FC<StageCardProps> = ({
  title,
  active,
  description,
  children,
  actionText,
  actionTime,
  onActionClick,
}) => {
  return (
    <Box className="stage-card">
      <Box className="stage-card-header">
        <Typography variant="h6">{title}</Typography>
        <XFATag
          text={active ? t("status.active") : t("status.inactive")}
          type={active ? "active" : "inactive"}
          icon={active ? ActiveIcon : undefined}
        />
      </Box>
      <Box className="stage-card-content">
        <Box>{children}</Box>
        <Typography variant="body2">{description}</Typography>
      </Box>
      {onActionClick && actionText ? (
        <Box className="stage-card-footer">
          <Button variant="text" size="small" onClick={onActionClick}>
            {actionText} {" ->"}
          </Button>
          {actionTime && (
            <XFATag text={actionTime} type={"inactive"} icon={AccessTime} />
          )}
        </Box>
      ) : (
        <div style={{ height: "24px", marginTop: "auto" }}></div>
      )}
    </Box>
  );
};

export default StageCard;
