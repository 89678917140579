import { useState, useEffect, useCallback } from "react";
import XFA_API, { BillingSettings } from "../../API/XFA_API";

export const useBillingSettings = (
  organizationId: string,
  roleType: string,
) => {
  const [settings, setSettings] = useState<BillingSettings | null>(null);
  const [loading, setLoading] = useState(true);
  const [onTrial, setOnTrial] = useState(false);

  const fetchBillingSettings = useCallback(async () => {
    if (!organizationId) {
      setLoading(false);
      return;
    }

    try {
      const billingSettings = await XFA_API.getBillingSettings(organizationId);
      setSettings(billingSettings);

      if (
        billingSettings &&
        billingSettings.TrialEndDate &&
        roleType !== "Organization Administrator"
      ) {
        const trialEndTime = new Date(
          parseInt(billingSettings.TrialEndDate.substring(0, 4)),
          parseInt(billingSettings.TrialEndDate.substring(4, 6)) - 1,
          parseInt(billingSettings.TrialEndDate.substring(6)),
        ).getTime();

        setOnTrial(trialEndTime >= new Date().getTime());
      }
    } catch (error) {
      console.error("Failed getting billing settings - ", error);
    } finally {
      setLoading(false);
    }
  }, [organizationId, roleType]);

  useEffect(() => {
    fetchBillingSettings();
  }, [fetchBillingSettings]);

  return { settings, onTrial, loading };
};
