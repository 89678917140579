import React from "react";
import { useTranslation } from "react-i18next";
import {
  CircularProgress,
  Typography,
  Alert,
  Tooltip,
  Chip,
  Divider,
  Button,
} from "@mui/material";
import "./DevicesOverview.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import XFA_API, { Device, Role } from "../API/XFA_API";
import dayjs from "dayjs";

import CheckIcon from "@mui/icons-material/Check";

import * as relativeTime from "dayjs/plugin/relativeTime";

import { Browsers, Check, OSCheck, PasswordManagerCheck } from "./Checks";

import { faIndustry } from "@fortawesome/free-solid-svg-icons";

import GoogleIcon from "../Applications/NewOrEditPage/Google/google.svg";
import MicrosoftIcon from "../Applications/NewOrEditPage/Microsoft/microsoft.svg";
import RedoIcon from "@mui/icons-material/Redo";
import ScatterPlotIcon from "@mui/icons-material/ScatterPlot";

import PhonelinkOffIcon from "@mui/icons-material/PhonelinkOff";
import ConfirmationDialog from "../Dialogs/ConfirmationDialog";

dayjs.extend(relativeTime);

interface DevicesOverviewProps {
  role: Role;
}

const DevicesOverview: React.FC<DevicesOverviewProps> = (
  props: DevicesOverviewProps,
) => {
  const { t } = useTranslation();
  const [devices, setDevices] = React.useState<Device[] | undefined>(undefined);
  const [error, setError] = React.useState("");
  const [mfaInProgress, setMfaInProgress] = React.useState<boolean>(false);
  const [confirmMfaReset, setConfirmMfaReset] = React.useState<
    | {
        deviceId: string;
        email: string;
      }
    | undefined
  >(undefined);
  const [loading, setLoading] = React.useState<boolean>(true);

  const getDevices = React.useCallback(
    (role: Role) => {
      setLoading(true);
      if (role.roleType === "System Administrator") {
        XFA_API.getAllDevices().then((result) => {
          if (result) {
            setDevices(result);
            setLoading(false);
          } else {
            setError(t("devices.RetrieveError"));
            setLoading(false);
          }
        });
      } else {
        XFA_API.getDevices(role.organization.organization_id).then((result) => {
          if (result) {
            setDevices(result);
            setLoading(false);
          } else {
            setError(t("devices.RetrieveError"));
            setLoading(false);
          }
        });
      }
    },
    [t],
  );
  React.useEffect(() => {
    if (props.role) {
      getDevices(props.role);
    }
  }, [props.role, getDevices]);

  return (
    <div>
      <Typography variant="pagetitle" color="primary">
        {t("navigation.devices")}
      </Typography>
      <Divider style={{ marginTop: 16 }} />
      <div className="table w-full" data-cy="devices">
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead style={{ background: "var(--color-gray-100)" }}>
              <TableRow>
                {props.role.organization.organization_id && (
                  <TableCell>{t("devices.Device")}</TableCell>
                )}
                <TableCell>{t("devices.Checks")}</TableCell>
                {!props.role.organization.organization_id && (
                  <TableCell>{t("devices.Connect")}</TableCell>
                )}
                <TableCell>{t("devices.TimestampSubmitted")}</TableCell>
                {props.role.organization.alpha_features && (
                  <TableCell></TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {devices &&
                devices
                  .sort((a: Device, b: Device) => {
                    return a.timestamp_submitted > b.timestamp_submitted
                      ? -1
                      : 1;
                  })
                  .map((device: Device) => {
                    //don't show unsupported devices for now in dashboard of clients (note: change this in Vanta service as well)
                    if (
                      props.role.organization.organization_id &&
                      device.unsupported
                    ) {
                      return null;
                    }

                    return (
                      <>
                        <TableRow key={device.device_id}>
                          {props.role.organization.organization_id && (
                            <TableCell>
                              <div>{device.computer_name?.name}</div>
                              <div
                                style={{
                                  color: "var(--color-gray-500)",
                                  fontSize: "smaller",
                                }}
                              >
                                {device.user?.full_name ? (
                                  <div>
                                    {device.user?.full_name}
                                    {device.user?.username && (
                                      <span> ({device.user?.username})</span>
                                    )}
                                  </div>
                                ) : (
                                  <div>{device.user?.username}</div>
                                )}
                                <div>{device.email ? device.email : ""}</div>
                              </div>
                            </TableCell>
                          )}
                          <TableCell
                            style={{
                              opacity:
                                device.skip || device.unsupported ? 0.38 : 1,
                            }}
                          >
                            <>
                              <div
                                style={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  rowGap: 4,
                                  columnGap: 4,
                                }}
                              >
                                <OSCheck device={device} />
                                {device.manufacturer !== undefined &&
                                  device.manufacturer !== null &&
                                  device.manufacturer !== "" && (
                                    <Check
                                      mainLabel={t("devices.Manufacturer")}
                                      subLabel={device.manufacturer}
                                      status="info"
                                      customFaIcon={faIndustry}
                                    />
                                  )}
                                {device.nativeclient_installed == false && (
                                  <Check
                                    mainLabel={t(
                                      "devices.NativeClientInstalled",
                                    )}
                                    subLabel={t("devices.missing")}
                                    status={"warn"}
                                  />
                                )}
                                {device.os_version !== null &&
                                device.os_version !== "" ? (
                                  device.os_uptodate !== null && (
                                    <Check
                                      mainLabel={t("devices.OSVersion")}
                                      subLabel={
                                        !device.os_uptodate
                                          ? t("devices.outOfDate")
                                          : undefined
                                      }
                                      status={device.os_uptodate ? "ok" : "nok"}
                                    />
                                  )
                                ) : (
                                  <Check
                                    mainLabel={t("devices.OSVersion")}
                                    subLabel={t("devices.unknown")}
                                    status={"nok"}
                                  />
                                )}
                                {device.os_autoupdate !== null && (
                                  <Check
                                    mainLabel={t("devices.OSAutoUpdate")}
                                    subLabel={
                                      !device.os_autoupdate
                                        ? t("devices.disabled")
                                        : undefined
                                    }
                                    status={
                                      device.os_autoupdate ? "ok" : "warn"
                                    }
                                  />
                                )}
                                {device.biometrics != null && (
                                  <Check
                                    mainLabel={t("devices.Biometrics")}
                                    subLabel={
                                      !device.biometrics
                                        ? t("devices.disabled")
                                        : undefined
                                    }
                                    status={device.biometrics ? "ok" : "warn"}
                                  />
                                )}
                                {device.authentication_active != null && (
                                  <Check
                                    mainLabel={t("devices.Authentication")}
                                    subLabel={
                                      !device.authentication_active
                                        ? t("devices.disabled")
                                        : undefined
                                    }
                                    status={
                                      device.authentication_active
                                        ? "ok"
                                        : "nok"
                                    }
                                  />
                                )}
                                {device.diskencryption_supported != null && (
                                  <Check
                                    mainLabel={t("devices.DiskEncryption")}
                                    subLabel={
                                      device.diskencryption_active === false
                                        ? t("devices.disabled")
                                        : device.diskencryption_supported ===
                                            false
                                          ? t("devices.unsupported")
                                          : undefined
                                    }
                                    status={
                                      device.diskencryption_active
                                        ? "ok"
                                        : "nok"
                                    }
                                  />
                                )}
                                {device.antivirus_enabled != null && (
                                  <Check
                                    mainLabel={t("devices.Antivirus")}
                                    subLabel={
                                      !device.antivirus_enabled
                                        ? t("devices.disabled")
                                        : undefined
                                    }
                                    status={
                                      device.antivirus_enabled ? "ok" : "nok"
                                    }
                                  />
                                )}
                                <Browsers device={device} />
                                <PasswordManagerCheck device={device} />
                              </div>
                            </>
                          </TableCell>
                          {!props.role.organization.organization_id && (
                            <TableCell align="center">
                              {device.connect !== undefined &&
                                device.connect && <CheckIcon />}
                            </TableCell>
                          )}
                          <TableCell>
                            <LastSeen device={device} />
                          </TableCell>
                          {props.role.organization.organization_id && (
                            <TableCell>
                              <Button
                                onClick={() => {
                                  setConfirmMfaReset({
                                    deviceId: device.device_id,
                                    email: device.email,
                                  });
                                }}
                              >
                                {t("devices.resetMfa")}
                              </Button>
                            </TableCell>
                          )}
                        </TableRow>
                      </>
                    );
                  })}
            </TableBody>
          </Table>
          {error && (
            <Alert
              onClose={() => {
                setError("");
              }}
              severity="error"
            >
              {error}
            </Alert>
          )}
          <div
            style={{
              textAlign: "center",
              paddingTop: 5,
              backgroundColor: "var(--color-gray-50)",
            }}
          >
            {devices && devices.length === 0 && (
              <span>{t("devices.NoDevices")}</span>
            )}
            {loading && <CircularProgress size={30} />}
          </div>
        </TableContainer>
      </div>
      {confirmMfaReset !== undefined && (
        <ConfirmationDialog
          title={t("devices.mfa.confirmTitle") + confirmMfaReset.email}
          cancelText={t("devices.mfa.cancel")}
          confirmText={t("devices.mfa.confirm")}
          inProgress={mfaInProgress}
          onCancel={() => {
            setConfirmMfaReset(undefined);
          }}
          onConfirm={() => {
            setMfaInProgress(true);
            XFA_API.deleteMfa(
              props.role.organization.organization_id,
              confirmMfaReset?.deviceId,
              confirmMfaReset?.email,
            )
              .catch((e) => {
                setError(e.message);
              })
              .finally(() => {
                setMfaInProgress(false);
                setConfirmMfaReset(undefined);
              });
          }}
        />
      )}
    </div>
  );
};

const LastSeen: React.FC<{ device: Device }> = ({ device }) => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
      }}
    >
      <div>{dayjs.unix(device.timestamp_submitted).fromNow()}</div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          gap: 4,
        }}
      >
        {device.discovered === true && (
          <Tooltip title={t("devices.deviceDiscoveredTooltip")}>
            <Chip
              size="small"
              label={
                <span style={{ display: "flex", alignItems: "center" }}>
                  <ScatterPlotIcon style={{ width: 14, height: 14 }} />
                </span>
              }
            />
          </Tooltip>
        )}
        {device.skip === true && (
          <Tooltip title={t("devices.deviceSkippedTooltip")}>
            <Chip
              size="small"
              label={
                <span style={{ display: "flex", alignItems: "center" }}>
                  <RedoIcon style={{ width: 14, height: 14 }} />
                </span>
              }
            />
          </Tooltip>
        )}
        {device.unsupported === true && (
          <Tooltip title={t("devices.deviceUnsupportedTooltip")}>
            <Chip
              size="small"
              label={
                <span style={{ display: "flex", alignItems: "center" }}>
                  <PhonelinkOffIcon style={{ width: 14, height: 14 }} />
                </span>
              }
              variant="outlined"
            />
          </Tooltip>
        )}
        {((device.google_mobile_device_ids &&
          device.google_mobile_device_ids.length > 0) ||
          (device.google_cloud_identity_device_ids &&
            device.google_cloud_identity_device_ids.length > 0)) && (
          <Tooltip title={t("devices.deviceDiscoveredGoogle")}>
            <img src={GoogleIcon} style={{ width: 14, height: 14 }} />
          </Tooltip>
        )}
        {device.microsoft_device_ids &&
          device.microsoft_device_ids.length > 0 && (
            <Tooltip title={t("devices.deviceDiscoveredMicrosoft")}>
              <img src={MicrosoftIcon} style={{ width: 14, height: 14 }} />
            </Tooltip>
          )}
      </div>
    </div>
  );
};

export default DevicesOverview;
