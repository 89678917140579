import React from "react";
import SignIn from "./SignIn";
import ForgotPassword from "./ForgotPassword";
import SetNewPassword from "../SetNewPassword";
import { Alert } from "@mui/material";
import { useParams } from "react-router-dom";
import { Buffer } from "buffer";
import { useTranslation } from "react-i18next";

interface LoginFormProps {
  onSignIn: (user: any) => void;
  onSwitchToSignup?: () => void;
  cognitoUser: any;
  setCognitoUser: (user: any) => void;
}

const LoginForm = (props: LoginFormProps) => {
  const [authState, setAuthState] = React.useState("SignIn");
  const params = useParams();
  const decode = (str: string): string =>
    Buffer.from(str, "base64").toString("binary");
  const [username, setUsername] = React.useState<string>(
    params.username ? decode(params.username) : "",
  );

  const { t } = useTranslation();

  return (
    <div id="loginForm" data-cy="login">
      {authState === "SignIn" && (
        <SignIn
          onSignIn={props.onSignIn}
          onForgotPassword={() => setAuthState("ForgotPassword")}
          username={username}
          onUsernameChange={setUsername}
          onNewPasswordRequired={(user) => {
            props.setCognitoUser(user);
            setAuthState("ResetPassword");
          }}
          onSignUp={() => {
            setAuthState("SignUp");
            props.onSwitchToSignup && props.onSwitchToSignup();
          }}
          fixedEmail={params.username !== undefined}
        />
      )}
      {authState === "ForgotPassword" && (
        <ForgotPassword
          username={username}
          goBack={() => setAuthState("SignIn")}
        />
      )}
      {authState === "ResetPassword" && (
        <SetNewPassword user={props.cognitoUser} onSignIn={props.onSignIn} />
      )}
      {authState === "SignedIn" && <Alert severity="success">Signed in</Alert>}
    </div>
  );
};

export default LoginForm;
