import React from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import TrialCard from "./TrialCard";
import TrialProgress from "./TrialProgress";
import ArrowDownward from "../../../icons/ArrowDownward.svg";
import Favorite from "../../../icons/Favorite.svg";
import AccessTime from "../../../icons/AccessTime.svg";
import Security from "../../../icons/Security.svg";
import { BillingSettings, Role } from "../../API/XFA_API";

interface TrialCTAProps {
  role: Role;
  settings: BillingSettings;
}

const TrialCTA: React.FC<TrialCTAProps> = ({ role, settings }) => {
  const { t } = useTranslation();

  const trialCards = t("overview.trialCards", {
    returnObjects: true,
  }) as Array<{
    title: string;
    description: string;
    tagText: string;
  }>;

  const icons = [ArrowDownward, Favorite, AccessTime, Security];

  return (
    <Box display="flex" flexWrap="wrap" gap={2} className="trial-cta-container">
      <Box flex="1" minWidth="300px">
        <TrialProgress settings={settings} />
      </Box>

      <Box
        flex="1"
        display="flex"
        flexWrap="wrap"
        justifyContent="center"
        gap={1}
      >
        {trialCards.map((card, index) => {
          const Icon = icons[index];

          return (
            <TrialCard
              key={index}
              text={card.title}
              description={card.description}
              tagText={card.tagText}
              tagIcon={Icon}
            />
          );
        })}
        <span className="sources" >
          {t("overview.trialProgress.sources")}
        </span>
      </Box>
    </Box>
  );
};

export default TrialCTA;
