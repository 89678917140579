import React from "react";
import RiskPeriodInput from "./RiskPeriodInput";
import ToggleSwitch from "./ToggleSwitch";
import ValidationAction from "./ValidationAction";
import AdditionalSettings, { SettingOption } from "./AdditionalSettings";
import "./PolicyDropdown.css";
import { Divider } from "@mui/material";
import { t } from "i18next";

interface PolicyDropdownProps {
  toggleTitle: string;
  toggleLabel?: string;
  description?: string;
  isVerificationEnabled: boolean;
  onVerificationToggle: (enabled: boolean) => void;
  riskPeriod?: number;
  onRiskPeriodChange?: (period: number) => void;
  validationAction?: string;
  onValidationActionChange?: (action: string) => void;
  warnActionPeriod?: number;
  onWarnActionPeriodChange?: (period: number) => void;
  blockActionPeriod?: number;
  onBlockActionPeriodChange?: (period: number) => void;
  settings?: SettingOption[];
  showRiskPeriodInput: boolean;
  showValidationAction: boolean;
  showAdditionalSettings: boolean;
}

const PolicyDropdown: React.FC<PolicyDropdownProps> = ({
  toggleTitle,
  toggleLabel,
  description,
  isVerificationEnabled,
  onVerificationToggle,
  riskPeriod,
  onRiskPeriodChange,
  validationAction,
  onValidationActionChange,
  warnActionPeriod,
  onWarnActionPeriodChange,
  blockActionPeriod,
  onBlockActionPeriodChange,
  settings = [],
  showRiskPeriodInput = true,
  showValidationAction = true,
  showAdditionalSettings = true,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);

  return (
    <div className={`policy-dropdown ${isDropdownOpen ? "" : "closed"}`}>
      <ToggleSwitch
        title={toggleTitle}
        label={toggleLabel}
        isChecked={isVerificationEnabled}
        onCheckedToggle={onVerificationToggle}
        onToggle={setIsDropdownOpen}
        showToggle={
          description !== undefined ||
          showRiskPeriodInput ||
          showValidationAction ||
          showAdditionalSettings
        }
      >
        {isDropdownOpen && (
          <>
            {description && (
              <>
                <span className="policy-description">{description}</span>
                <Divider style={{ marginTop: "20px" }} />
              </>
            )}

            {showRiskPeriodInput &&
              riskPeriod !== undefined &&
              onRiskPeriodChange && (
                <RiskPeriodInput
                  value={riskPeriod}
                  onChange={(value) => {
                    onRiskPeriodChange(value);
                  }}
                  title={t("policies.editPolicy.acceptedRiskPeriod")}
                  description={t(
                    "policies.editPolicy.acceptedRiskPeriodDescription",
                  )}
                />
              )}

            {showValidationAction && validationAction !== undefined && (
              <ValidationAction
                value={validationAction}
                onChange={(value) => {
                  if (onValidationActionChange) onValidationActionChange(value);
                }}
                warnPeriod={warnActionPeriod}
                onWarnPeriodChange={onWarnActionPeriodChange}
                blockPeriod={blockActionPeriod}
                onBlockPeriodChange={onBlockActionPeriodChange}
              />
            )}

            {showAdditionalSettings && settings.length > 0 && (
              <AdditionalSettings settings={settings} />
            )}
          </>
        )}
      </ToggleSwitch>
    </div>
  );
};

export default PolicyDropdown;
