import React from "react";
import "./GetStarted.css";
import checkmarkIcon from "../../images/checkmark-icon.png";
import emptyCheckmarkIcon from "../../images/empty-checkmark.svg";
import { Tooltip } from "@mui/material";

interface TaskProps {
  text: string;
  completed: boolean;
  onClick: () => void;
  disabled?: boolean;
  tooltip?: string;
}

const GetStartedTask: React.FC<TaskProps> = ({
  text,
  completed,
  onClick,
  disabled,
  tooltip,
}) => {
  return (
    <Tooltip
      title={disabled && tooltip ? tooltip : ""}
      disableHoverListener={!disabled || !tooltip}
      placement="right"
    >
      <div
        className={`task ${disabled ? "disabled" : ""}`}
        onClick={!disabled ? onClick : undefined}
        style={{
          cursor: disabled ? "not-allowed" : "pointer",
          opacity: disabled ? 0.5 : 1,
        }}
      >
        <div className="task-left">
          <div className={`task-checkbox ${completed ? "completed" : ""}`}>
            <img
              src={completed ? checkmarkIcon : emptyCheckmarkIcon}
              height={20}
              width={20}
              alt="Completed"
              className="task-checkmark-icon"
            />
          </div>
          <div className={`task-text ${completed ? "completed" : ""}`}>
            {text}
          </div>
        </div>
        {!disabled && (
          <div className="task-chevron">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 6L15 12L9 18"
                stroke="#9BA1AB"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        )}
      </div>
    </Tooltip>
  );
};

export default GetStartedTask;
