import React from "react";
import { useTranslation } from "react-i18next";
import { Alert, Button, CircularProgress, Typography } from "@mui/material";
import XFA_API, { Role } from "../../API/XFA_API";
import ConfirmationDialog from "../../Dialogs/ConfirmationDialog";

interface PaymentMethodProps {
  role: Role;
}

interface PaymentMethodObj {
  Account: string;
  Bic: string;
  CardExpiryDate: string;
  CardHolder: string;
  CardLabel: string;
  CardNumber: string;
  Date: string;
  Name: string;
  OrganizationID: string;
  PaymentProviderCustomerID: string;
  Type: string;
}

const PaymentMethod: React.FC<PaymentMethodProps> = (
  props: PaymentMethodProps,
) => {
  const { t } = useTranslation();
  const [method, setMethod] = React.useState<PaymentMethodObj | undefined>(
    undefined,
  );
  const [methodLoading, setMethodLoading] = React.useState<boolean>(true);
  const [confirmChangePaymentMethod, setConfirmChangePaymentMethod] =
    React.useState<boolean>(false);
  const [changePaymentMethodInProgress, setChangePaymentMethodInProgress] =
    React.useState<boolean>(false);
  const [error, setError] = React.useState<string>("");

  const refreshPaymentMethod = React.useCallback(async () => {
    try {
      setMethodLoading(true);
      const paymentMethod = await XFA_API.getPaymentMethod(
        props.role.organization.organization_id,
      );
      setMethod(paymentMethod);
      setMethodLoading(false);
    } catch (error) {
      setMethodLoading(false);
    }
  }, [props.role.organization.organization_id]);

  React.useEffect(() => {
    refreshPaymentMethod();
  }, [refreshPaymentMethod]);

  return (
    <div>
      <Typography className="paperTitle" color="primary">
        {t("Billing.paymentMethodTitle")}
      </Typography>
      {error && (
        <div style={{ marginBottom: 15 }}>
          <Alert severity="error">{error}</Alert>
        </div>
      )}
      {method && !methodLoading ? (
        method.Type === "creditcard" ? (
          <>
            <Typography className="paperContent">
              {method.CardLabel} {t("Billing.ending")} {method.CardNumber}
            </Typography>
            <Typography className="paperContent">
              {t("Billing.expiring")} {method.CardExpiryDate}
            </Typography>
          </>
        ) : (
          <>
            <Typography className="paperContent">{method.Account}</Typography>
            <Typography className="paperContent">{method.Bic}</Typography>
          </>
        )
      ) : methodLoading ? (
        <div>
          <CircularProgress
            size="20px"
            style={{ marginRight: "10px" }}
            color="primary"
          />
        </div>
      ) : (
        <></>
      )}
      <Button
        className={"editButton"}
        variant={"contained"}
        type={"button"}
        color={"primary"}
        disableElevation
        size="small"
        onClick={() => {
          setConfirmChangePaymentMethod(true);
        }}
      >
        {method
          ? t("Billing.editPaymentMethod")
          : t("Billing.addPaymentMethod")}
      </Button>

      {confirmChangePaymentMethod && (
        <ConfirmationDialog
          title={
            method
              ? t("Billing.changeMethodDialogTitle")
              : t("Billing.addMethodDialogTitle")
          }
          content={
            method
              ? t("Billing.changeMethodDialogContent")
              : t("Billing.addMethodDialogContent")
          }
          cancelText={t("Users.delete.cancel")}
          confirmText={t("Users.delete.confirm")}
          inProgress={changePaymentMethodInProgress}
          onCancel={() => {
            setConfirmChangePaymentMethod(false);
            setChangePaymentMethodInProgress(false);
          }}
          onConfirm={() => {
            setChangePaymentMethodInProgress(true);
            if (method) {
              XFA_API.updatePaymentMethod(
                props.role.organization.organization_id,
              )
                .then((checkoutUrl) => {
                  setConfirmChangePaymentMethod(false);
                  setChangePaymentMethodInProgress(false);
                  window.location.href = checkoutUrl;
                })
                .catch(() => {
                  setConfirmChangePaymentMethod(false);
                  setChangePaymentMethodInProgress(false);
                  setError("Something went wrong, try again later");
                });
            } else {
              XFA_API.addPaymentMethod(
                props.role.organization.organization_id,
                undefined,
              )
                .then((checkoutUrl) => {
                  setConfirmChangePaymentMethod(false);
                  setChangePaymentMethodInProgress(false);
                  window.location.href = checkoutUrl;
                })
                .catch(() => {
                  setConfirmChangePaymentMethod(false);
                  setChangePaymentMethodInProgress(false);
                  setError("Something went wrong, try again later");
                });
            }
          }}
        />
      )}
    </div>
  );
};

export default PaymentMethod;
