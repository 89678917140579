import { Auth } from "aws-amplify";

export interface ApiConfig {
  endpoints: {
    name: string;
    endpoint: string;
    custom_header: () => Promise<{ Authorization: string }>;
  }[];
}

export const getApiConfig = (): ApiConfig => ({
  endpoints: [
    {
      name: "XFA_API",
      endpoint: process.env.REACT_APP_API_ENDPOINT || "",
      custom_header: async () => ({
        Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
      }),
    },
  ],
});
