import React from "react";

interface XFATagProps {
  text: string;
  type?:
    | "active"
    | "info"
    | "phishing"
    | "ransomware"
    | "dataBreach"
    | "inactive";
  icon?: string;
}

const XFATag: React.FC<XFATagProps> = ({ text, type = "active", icon }) => {
  const styles = {
    base: {
      display: "flex",
      alignItems: "center",
      minHeight: "24px",
      padding: "3px 6px",
      gap: "4px",
      borderRadius: "4px",
      fontSize: "12px",
      fontWeight: "500",
      width: "fit-content",
    },
    types: {
      active: { backgroundColor: "#D1FAE5", color: "#065F46" },
      info: { backgroundColor: "#DBEAFE", color: "#1E40AF" },
      phishing: { backgroundColor: "#EDE9FE", color: "#5B21B6" },
      ransomware: { backgroundColor: "#FEE2E2", color: "#991B1B" },
      dataBreach: { backgroundColor: "#FEF3C7", color: "#9A3412" },
      inactive: { backgroundColor: "#F3F4F6", color: "#21252B" },
    },
  };

  const typeStyle = styles.types[type] || styles.types.active;

  return (
    <div style={{ ...styles.base, ...typeStyle }}>
      {icon && (
        <img src={icon} style={{ color: typeStyle.color, fontSize: "16px" }} />
      )}
      <span
        style={{ color: typeStyle.color }}
        dangerouslySetInnerHTML={{ __html: text }}
      />
    </div>
  );
};

export default XFATag;
