import { styled, Switch } from "@mui/material";

export const XfaSwitch = styled(Switch)(({ theme }) => ({
  width: 36,
  height: 20,
  padding: 0,
  margin: 8,
  "& .MuiSwitch-switchBase": {
    padding: 2,
    margin: 0,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        borderRadius: 36 / 2,
        backgroundColor: "#393E45",
        opacity: 1,
        border: 0,
      },
    },
    "&.Mui-disabled": {
      "& + .MuiSwitch-track": {
        backgroundColor: "var(--color-gray-100)",
        opacity: 1,
      },
      "&.Mui-checked + .MuiSwitch-track": {
        backgroundColor: "var(--color-gray-200)",
      },
      "& .MuiSwitch-thumb": {
        backgroundColor: "var(--color-gray-200)",
      },
      "&.Mui-checked .MuiSwitch-thumb": {
        backgroundColor: "#CCD0D6",
      },
    },
  },
  "&:not(.Mui-disabled) .MuiSwitch-switchBase:not(.Mui-checked):hover + .MuiSwitch-track":
    {
      backgroundColor: "#CCD0D6",
    },
  "&:not(.Mui-disabled) .MuiSwitch-switchBase:not(.Mui-checked):hover .MuiSwitch-thumb":
    {
      backgroundColor: "#fff",
    },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 16,
    height: 16,
  },
  "& .MuiSwitch-track": {
    borderRadius: 20 / 2,
    backgroundColor: "var(--color-gray-200)",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));
