import React from "react";
import "../GetStarted.css";
import { useTranslation } from "react-i18next";
import { Button, Typography, LinearProgress } from "@mui/material";
import { BillingSettings } from "../../API/XFA_API";
import NavigationService from "../../../utils/NavigationService";

interface TrialProgressProps {
  settings: BillingSettings;
  small?: boolean;
}

const TOTAL_TRIAL_DAYS = 30;

const TrialProgress: React.FC<TrialProgressProps> = ({ settings, small }) => {
  const { t } = useTranslation();

  const trialEndDate = settings.TrialEndDate;
  const trialEndTime = new Date(
    parseInt(trialEndDate.substring(0, 4)),
    parseInt(trialEndDate.substring(4, 6)) - 1,
    parseInt(trialEndDate.substring(6)),
  ).getTime();

  const now = new Date().getTime();

  const daysLeft = Math.max(
    Math.round((trialEndTime - now) / (1000 * 60 * 60 * 24)),
    0,
  );
  const percentage = ((TOTAL_TRIAL_DAYS - daysLeft) / TOTAL_TRIAL_DAYS) * 100;

  return (
    <div className={`trial-progress-container ${small ? "small" : ""}`}>
      <div className={`progress-container  ${small ? "small" : ""}`}>
        <Typography
          variant="h2"
          className={`trial-progress-header ${small ? "small" : ""}`}
        >
          {small
            ? t("overview.trialProgress.smallHeader")
            : t("overview.trialProgress.header")}
        </Typography>
        {!small && (
          <Typography variant="body1" className="trial-progress-description">
            {t("overview.trialProgress.description")}
          </Typography>
        )}
      </div>

      <div className={`progress-container ${small ? "small" : ""}`}>
        <LinearProgress
          variant="determinate"
          value={percentage}
          sx={{
            backgroundColor: "#CCD0D6",
            "& .MuiLinearProgress-bar": {
              backgroundColor: "#3B82F6",
            },
          }}
          className="trial-progress-bar"
        />
        <Typography variant="body2" className="trial-days-left">
          {t("overview.trialProgress.daysLeft", {
            days: daysLeft,
            count: daysLeft,
          })}
        </Typography>
      </div>
      <div className="cta-button-container">
        <Button
          variant={small ? "text" : "contained"}
          color="primary"
          className={`cta-button ${small ? "small" : ""}`}
          onClick={() => NavigationService.navigateToBilling()}
        >
          {t("overview.trialProgress.ctaButton")}
        </Button>
      </div>
    </div>
  );
};

export default TrialProgress;
