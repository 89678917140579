import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Collapse,
  FormControl,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { OAuthConfiguration } from "../../../API/XFA_API";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { XfaSwitch } from "../../../General/XfaSwitch";

interface OAuth2PageProps {
  creating: boolean;
  oauthConfiguration?: OAuthConfiguration;
  applicationId: string;
  setOAuthConfiguration: (oauthConfig: OAuthConfiguration) => void;
}

const OAuth2Page: React.FC<OAuth2PageProps> = (props: OAuth2PageProps) => {
  const { t } = useTranslation();
  const [copiedRedirectUrl, setCopiedRedirectUrl] = useState(false);

  const redirectUrlReplacement =
    "https://device-api.xfa.tech/" + props.applicationId + "/oauth2/redirect";

  const [copiedTokenEndpoint, setCopiedTokenEndpoint] = useState(false);

  const tokenEndpointReplacement = "https://device-api.xfa.tech/oauth2/token";

  const [oauthConfiguration, setOAuthConfiguration] =
    useState<OAuthConfiguration>(
      props.oauthConfiguration
        ? props.oauthConfiguration
        : {
            Enabled: true,
            RedirectURLToClient: "",
          },
    );

  const [tokenEndpointOfProviderSet, setTokenEndpointOfProviderSet] = useState(
    props.oauthConfiguration?.TokenEndpointOfProvider !== undefined &&
      props.oauthConfiguration.TokenEndpointOfProvider !== null,
  );

  return (
    <div className="section">
      <Typography variant="formsectiontitle">
        {t("applications.createDialog.oauth2Redirect")}
      </Typography>
      <Typography variant="instructions">
        {t("applications.createDialog.oauth2RedirectContent")}
      </Typography>
      <div style={{ marginBottom: 16 }} />
      <FormControl fullWidth={true}>
        <Typography variant="formlabel">
          {t("applications.createDialog.RedirectURLToClient")}
        </Typography>
        <TextField
          required
          id="redirectUrlToClient"
          placeholder={t(
            "applications.createDialog.RedirectURLToClientPlaceholder",
          )}
          value={oauthConfiguration.RedirectURLToClient}
          onChange={(event) => {
            setOAuthConfiguration({
              ...oauthConfiguration,
              RedirectURLToClient: event.target.value,
            });
            props.setOAuthConfiguration({
              ...oauthConfiguration,
              RedirectURLToClient: event.target.value,
            });
          }}
          variant="outlined"
        />
        <div style={{ marginBottom: 16 }} />
        <Typography variant="instructions">
          {t("applications.createDialog.oauth2RedirectContent2")}
        </Typography>
        <div style={{ marginBottom: 16 }} />
        <Typography variant="formlabel">
          {t("applications.createDialog.RedirectUrlReplacement")}
        </Typography>
        <FormControl style={{ flexDirection: "row", alignItems: "center" }}>
          <TextField
            required
            rows={6}
            id="name"
            value={redirectUrlReplacement}
            InputProps={{
              readOnly: true,
            }}
            variant="filled"
            fullWidth={true}
            style={{
              textAlign: "left",
            }}
          />
          <Tooltip
            title={
              copiedRedirectUrl
                ? t("applications.createDialog.copied")
                : t("applications.createDialog.clickToCopy")
            }
            arrow
            placement={"top"}
          >
            <Button
              style={{
                height: "min-content",
                margin: 5,
                padding: 10,
                minWidth: 0,
              }}
              onClick={async () => {
                await navigator.clipboard.writeText(redirectUrlReplacement);
                setCopiedRedirectUrl(true);
              }}
            >
              <ContentCopyIcon fontSize="small" />
            </Button>
          </Tooltip>
        </FormControl>
        <div style={{ marginBottom: 16 }} />
        <div>
          <div style={{ display: "flex" }}>
            <div style={{ width: "100%" }}>
              <Typography variant="setting">
                {t("applications.createDialog.TokenEndpointOfProviderEnabled")}
              </Typography>
              <Typography variant="instructions">
                {t(
                  "applications.createDialog.TokenEndpointOfProviderProxiedInformation",
                )}
              </Typography>
            </div>
            <XfaSwitch
              checked={tokenEndpointOfProviderSet}
              disableRipple
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                if (event.target.checked) {
                  setTokenEndpointOfProviderSet(true);
                } else {
                  setTokenEndpointOfProviderSet(false);
                  setOAuthConfiguration({
                    ...oauthConfiguration,
                    TokenEndpointOfProvider: undefined,
                  });
                  props.setOAuthConfiguration({
                    ...oauthConfiguration,
                    TokenEndpointOfProvider: undefined,
                  });
                }
              }}
            />
          </div>
          <Collapse in={tokenEndpointOfProviderSet} style={{ marginTop: 8 }}>
            <FormControl fullWidth={true} style={{ paddingLeft: 24 }}>
              <Typography variant="formlabel">
                {t("applications.createDialog.TokenEndpointOfProvider")}
              </Typography>
              <TextField
                required
                id="redirectUrlToClient"
                placeholder={t(
                  "applications.createDialog.TokenEndpointOfProviderPlaceholder",
                )}
                value={oauthConfiguration.TokenEndpointOfProvider}
                onChange={(event) => {
                  setOAuthConfiguration({
                    ...oauthConfiguration,
                    TokenEndpointOfProvider: event.target.value,
                  });
                  props.setOAuthConfiguration({
                    ...oauthConfiguration,
                    TokenEndpointOfProvider: event.target.value,
                  });
                }}
                variant="outlined"
              />
              <div style={{ marginBottom: 16 }} />
              <Typography variant="formlabel">
                {t("applications.createDialog.ReplacementTokenEndpoint")}
              </Typography>
              <FormControl
                style={{ flexDirection: "row", alignItems: "center" }}
              >
                <TextField
                  required
                  rows={6}
                  id="name"
                  value={tokenEndpointReplacement}
                  InputProps={{
                    readOnly: true,
                  }}
                  variant="filled"
                  fullWidth={true}
                  style={{
                    textAlign: "left",
                  }}
                />
                <Tooltip
                  title={
                    copiedTokenEndpoint
                      ? t("applications.createDialog.copied")
                      : t("applications.createDialog.clickToCopy")
                  }
                  arrow
                  placement={"top"}
                >
                  <Button
                    style={{
                      height: "min-content",
                      margin: 5,
                      padding: 10,
                      minWidth: 0,
                    }}
                    onClick={async () => {
                      await navigator.clipboard.writeText(
                        tokenEndpointReplacement,
                      );
                      setCopiedTokenEndpoint(true);
                    }}
                  >
                    <ContentCopyIcon fontSize="small" />
                  </Button>
                </Tooltip>
              </FormControl>
            </FormControl>
          </Collapse>
        </div>
      </FormControl>
    </div>
  );
};

export default OAuth2Page;
