import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  FormControl,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Application } from "../../../API/XFA_API";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DownloadIcon from "@mui/icons-material/Download";
import LinkIcon from "@mui/icons-material/Link";

interface SPPageProps {
  application: Application;
  setApplication: (application: Application) => void;
  creating: boolean;
  sp: string;
}

const SPPage: React.FC<SPPageProps> = (props: SPPageProps) => {
  const { t } = useTranslation();
  const [copiedUrlLabel, setCopiedUrlLabel] = useState(false);
  const [copiedIssuerLabel, setCopiedIssuerLabel] = useState(false);
  const [copiedCertLabel, setCopiedCertLabel] = useState(false);
  const [downloadedCert, setDownloadedCert] = useState(false);
  const [copiedMetadataUrl, setCopiedMetadataUrl] = useState(false);
  let startAcs = "";
  if (props.sp === "github") {
    startAcs = "https://github.com/orgs/{your-organization}/saml/consume";
  }
  const [acs, setAcs] = useState(
    props.application.SAMLConfiguration
      ?.ServiceProviderAssertionConsumerServiceURL || startAcs,
  );

  return (
    <div className="section">
      <Typography variant="formsectiontitle">
        {t("applications.createDialog.sp")}
      </Typography>
      <Typography variant="instructions">
        {t("applications.createDialog.spContent")}
      </Typography>
      <div style={{ marginBottom: 16 }} />
      <FormControl fullWidth={true}>
        <Typography variant="formlabel">
          {t("applications.createDialog.acsUrl")}
        </Typography>
        <TextField
          required
          id="acs"
          value={acs}
          placeholder={t("applications.createDialog.acsUrlPlaceholder")}
          onChange={(event) => {
            setAcs(event.target.value);
            props.setApplication({
              ...props.application,
              SAMLConfiguration: {
                ...props.application.SAMLConfiguration!,
                ServiceProviderAssertionConsumerServiceURL: event.target.value,
              },
            });
          }}
        />
        <div style={{ marginBottom: 16 }} />
        <Typography variant="formlabel">
          {t("applications.createDialog.idpUrl")}
        </Typography>
        <FormControl style={{ flexDirection: "row", alignItems: "center" }}>
          <TextField
            required
            id="name"
            value={props.application.SSOUrl}
            InputProps={{ readOnly: true }}
            variant="filled"
            style={{
              width: "100%",
            }}
          />
          <Tooltip
            title={
              copiedUrlLabel
                ? t("applications.createDialog.copied")
                : t("applications.createDialog.clickToCopy")
            }
            arrow
            placement={"top"}
            onClose={() => {
              setCopiedUrlLabel(false);
            }}
          >
            <Button
              style={{
                height: "min-content",
                margin: 5,
                padding: 10,
                minWidth: 0,
              }}
              onClick={async () => {
                await navigator.clipboard.writeText(props.application.SSOUrl!);
                setCopiedUrlLabel(true);
              }}
            >
              <ContentCopyIcon fontSize="small" />
            </Button>
          </Tooltip>
        </FormControl>
        <div style={{ marginBottom: 16 }} />
        <Typography variant="formlabel">
          {t("applications.createDialog.entityId")}
        </Typography>
        <FormControl style={{ flexDirection: "row", alignItems: "center" }}>
          <TextField
            required
            id="name"
            value={props.application.Issuer}
            InputProps={{ readOnly: true }}
            variant="filled"
            style={{
              width: "100%",
            }}
          />
          <Tooltip
            title={
              copiedIssuerLabel
                ? t("applications.createDialog.copied")
                : t("applications.createDialog.clickToCopy")
            }
            arrow
            placement={"top"}
            onClose={() => {
              setCopiedIssuerLabel(false);
            }}
          >
            <Button
              style={{
                height: "min-content",
                margin: 5,
                padding: 10,
                minWidth: 0,
              }}
              onClick={async () => {
                await navigator.clipboard.writeText(props.application.Issuer!);
                setCopiedIssuerLabel(true);
              }}
            >
              <ContentCopyIcon fontSize="small" />
            </Button>
          </Tooltip>
        </FormControl>
        <div style={{ marginBottom: 16 }} />
        <Typography variant="formlabel">
          {t("applications.createDialog.certificate")}
        </Typography>
        <FormControl
          style={{
            flexDirection: "row",
            alignItems: "center",
            marginBottom: 16,
          }}
        >
          <TextField
            required
            multiline
            rows={6}
            id="name"
            value={
              props.application.PublicCerts
                ? props.application.PublicCerts[0]
                : ""
            }
            InputProps={{ readOnly: true }}
            variant="filled"
            style={{
              width: "100%",
            }}
          />
          <div
            style={{
              width: "min-content",
            }}
          >
            <Tooltip
              title={
                copiedCertLabel
                  ? t("applications.createDialog.copied")
                  : t("applications.createDialog.clickToCopy")
              }
              arrow
              placement={"top"}
              onClose={() => {
                setCopiedCertLabel(false);
              }}
            >
              <Button
                style={{
                  height: "min-content",
                  margin: 5,
                  padding: 10,
                  minWidth: 0,
                }}
                onClick={async () => {
                  await navigator.clipboard.writeText(
                    props.application.PublicCerts![0],
                  );
                  setCopiedCertLabel(true);
                }}
              >
                <ContentCopyIcon fontSize="small" />
              </Button>
            </Tooltip>
            <Tooltip
              title={
                downloadedCert
                  ? t("applications.saml.downloaded")
                  : t("applications.saml.clickToDownloadCertificate")
              }
              arrow
              placement={"top"}
              onClose={() => {
                setDownloadedCert(false);
              }}
            >
              <Button
                style={{
                  height: "min-content",
                  margin: 5,
                  padding: 10,
                  minWidth: 0,
                }}
                onClick={async () => {
                  const file = document.createElement("a");
                  file.href = URL.createObjectURL(
                    new Blob([props.application.PublicCerts![0]], {
                      type: "application/x-x509-ca-cert",
                    }),
                  );
                  file.download = "xfa-certificate-for-application.crt";
                  file.click();
                  setDownloadedCert(true);
                }}
              >
                <DownloadIcon fontSize="small" />
              </Button>
            </Tooltip>
          </div>
        </FormControl>
        <FormControl style={{ flexDirection: "row", alignItems: "center" }}>
          <Button
            variant="outlined"
            style={{ width: "100%" }}
            onClick={() => {
              const file = document.createElement("a");
              file.href = props.application.SAMLMetadataUrl;
              file.target = "_blank";
              file.download = "xfa-metadata-for-application.xml";
              file.click();
            }}
          >
            {t("applications.saml.downloadmetadata")}
          </Button>
          <Tooltip
            title={
              copiedMetadataUrl
                ? t("applications.saml.copiedMetadataUrl")
                : t("applications.saml.copyMetadataUrl")
            }
            arrow
            placement={"top"}
            onClose={() => {
              setCopiedMetadataUrl(false);
            }}
          >
            <Button
              style={{
                height: "min-content",
                margin: 5,
                padding: 10,
                minWidth: 0,
              }}
              onClick={async () => {
                await navigator.clipboard.writeText(
                  props.application.SAMLMetadataUrl,
                );
                setCopiedMetadataUrl(true);
              }}
            >
              <LinkIcon fontSize="small" />
            </Button>
          </Tooltip>
        </FormControl>
      </FormControl>
    </div>
  );
};

export default SPPage;
