import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Alert,
  Button,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import "../AuthStateApp.css";
import XFA_API, { Organization } from "../../API/XFA_API";

interface OrganizationSetupProps {
  onNextStep: (organization_id: string) => void;
  email: string;
}

const OrganizationSetup = (props: OrganizationSetupProps) => {
  const [error, setError] = useState<string | undefined>();
  const [name, setName] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [zipCode, setZipCode] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [country, setCountry] = useState<string>("");
  const [vat, setVat] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();

  const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));
  const verify = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await XFA_API.createOrganization(name);
      sleep(1000);
      const roles = await XFA_API.getRoles();

      if (roles && roles.length > 0) {
        const billing_email = (roles && roles[0]?.email) ?? props.email;
        const orgId = roles[0].organization.organization_id;
        const organizationData: Organization = {
          name: name,
          organization_id: orgId,
          billing_email: billing_email,
          address: address,
          zip_code: zipCode,
          city: city,
          country: country,
          vat: vat,
          logo_url: "",
          notify_discovered_devices: false,
        };

        try {
          await XFA_API.updateOrganization(orgId, organizationData).catch(
            (err) => {
              console.error(err);
              setError(t("login.signup.organizationError"));
            },
          );
          props.onNextStep(orgId);
        } catch (e) {
          console.log("Unable to refresh Token", e);
        }
      } else {
        setError(t("login.signup.organizationError"));
      }
    } catch (err) {
      setError(t("login.signup.organizationError"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div id="organization-setup">
      <form onSubmit={verify}>
        <Typography variant="forminstructions">
          {t("login.signup.step3Description")}
        </Typography>
        <div id="fields">
          <div className="field">
            <Typography variant="formlabel">
              {t("organization.settings.name")}
            </Typography>
            <TextField
              id="organization-name-input"
              variant="outlined"
              placeholder={t("organization.settings.name")}
              fullWidth
              value={name}
              onChange={(event) => setName(event.target.value)}
              autoComplete="name"
              autoFocus
              disabled={loading}
              required
            />
          </div>
          <div className="field">
            <Typography variant="formlabel">
              {t("organization.settings.address")}
            </Typography>
            <TextField
              id="organization-address-input"
              variant="outlined"
              placeholder={t("organization.settings.address")}
              fullWidth
              value={address}
              onChange={(event) => setAddress(event.target.value)}
              autoComplete="address"
              disabled={loading}
              required
            />
          </div>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-between",
              gap: "16px",
              marginTop: "16px",
            }}
          >
            <div id="username">
              <Typography variant="formlabel">
                {t("organization.settings.zipCode")}
              </Typography>
              <TextField
                id="organization-zip-code-input"
                variant="outlined"
                placeholder={t("organization.settings.zipCode")}
                fullWidth
                value={zipCode}
                onChange={(event) => setZipCode(event.target.value)}
                autoComplete="zipCode"
                disabled={loading}
                required
              />
            </div>
            <div id="username">
              <Typography variant="formlabel">
                {t("organization.settings.city")}
              </Typography>
              <TextField
                id="organization-city-input"
                variant="outlined"
                placeholder={t("organization.settings.city")}
                fullWidth
                value={city}
                onChange={(event) => setCity(event.target.value)}
                autoComplete="city"
                disabled={loading}
                required
              />
            </div>
          </div>
          <div className="field">
            <Typography variant="formlabel">
              {t("organization.settings.country")}
            </Typography>
            <TextField
              id="organization-country-input"
              variant="outlined"
              placeholder={t("organization.settings.country")}
              fullWidth
              value={country}
              onChange={(event) => setCountry(event.target.value)}
              autoComplete="country"
              disabled={loading}
              required
            />
          </div>
          <div className="field">
            <Typography variant="formlabel">
              {t("organization.settings.vat")}
            </Typography>
            <TextField
              id="organization-vat-input"
              variant="outlined"
              placeholder={t("organization.settings.vat")}
              fullWidth
              value={vat}
              onChange={(event) => setVat(event.target.value)}
              autoComplete="vat"
              disabled={loading}
            />
          </div>
        </div>
        {error && (
          <div id="errorMessage">
            <Alert severity="error">{t(error)}</Alert>
          </div>
        )}
        <div className="sendButton" style={{ position: "relative" }}>
          <Button
            id="organization-setup-button"
            variant={"contained"}
            type={"submit"}
            color={"primary"}
            disableElevation
            size="large"
            style={{ width: "100%" }}
            disabled={
              loading || !name || !address || !zipCode || !city || !country
            }
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              t("login.signup.continue")
            )}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default OrganizationSetup;
