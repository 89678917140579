import React from "react";
import XFA_API, { Role } from "../../../API/XFA_API";
import NavigationService from "../../../../utils/NavigationService";
import { getMicrosoftRedirectUri } from "../../../../utils/DiscoveryService";
import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";

interface ConnectMicrosoftProps {
  role: Role;
  organizationId: string;
}

const ConnectMicrosoft: React.FC<ConnectMicrosoftProps> = (
  props: ConnectMicrosoftProps,
) => {
  const { t } = useTranslation();
  React.useEffect(() => {
    const code = new URLSearchParams(window.location.search).get("code");
    const applicationId = new URLSearchParams(window.location.search).get(
      "state",
    );
    if (code && applicationId) {
      //clear the code to prevent re-creation
      window.history.replaceState({}, document.title, window.location.pathname);

      XFA_API.connectApplication(
        props.organizationId,
        applicationId,
        code,
        getMicrosoftRedirectUri(),
      )
        .then((connectionError) => {
          NavigationService.navigateToDiscovery(applicationId, connectionError);
        })
        .catch((error) => {
          NavigationService.navigateToDiscovery(applicationId, error);
        });
    }
  }, []);

  return (
    <div style={{ marginTop: "40vh", textAlign: "center" }}>
      {t("discovery.connectingMicrosoft")}
      <CircularProgress size={16}></CircularProgress>
    </div>
  );
};

export default ConnectMicrosoft;
