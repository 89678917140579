import React, { forwardRef, RefObject, useRef } from "react";
import "./ToastMessage.css";
import SafeIcon from "../../../icons/SafeIcon.svg";
import BlockIcon from "../../../icons/toastErrorIcon.svg";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";

export interface ToastMessageProps {
  isSaved: boolean;
  id: string;
  closeButtonAction: (id: string) => void;
  message?: string;
  dismissAction?: () => void;
  undoAction?: () => void;
  title?: string;
}

// eslint-disable-next-line react/display-name
const ToastMessage = forwardRef<HTMLDivElement, ToastMessageProps>(
  (
    {
      id,
      isSaved,
      dismissAction,
      undoAction,
      closeButtonAction,
      message,
      title,
    },
    ref,
  ) => {
    const { t } = useTranslation();

    return (
      <div className="toast-notification show" ref={ref}>
        <div className="toast-icon-column">
          <img className="safe-icon" src={isSaved ? SafeIcon : BlockIcon} />
        </div>
        <div className="toast-message-column">
          <strong className="toast-message-title">
            {title
              ? title
              : isSaved
                ? t("general.success")
                : t("general.errorTitle")}
          </strong>
          <span
            className="toast-message-text"
            style={{ color: "var(--color-gray-500)" }}
          >
            {message
              ? message
              : isSaved
                ? t("general.successMessage")
                : t("general.errorMessage")}
          </span>
          <div className="button-container">
            {dismissAction && (
              <button onClick={dismissAction} className="dismiss-toast-button">
                {t("general.dismiss")}
              </button>
            )}
            {undoAction && (
              <button onClick={undoAction} className="undo-toast-button">
                {t("general.undo")}
              </button>
            )}
          </div>
        </div>
        <div className="close-button-column">
          <IconButton
            className="close-button-toast"
            onClick={() => closeButtonAction(id)}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </div>
    );
  },
);

export default ToastMessage;
