import React from "react";
import SettingsSection from "../../../Settings/SettingsSection";
import WarningIcon from "../../../../icons/WarningIcon.svg";
import BlockIcon from "../../../../icons/BlockIcon.svg";
import { useTranslation } from "react-i18next";

interface ValidationActionProps {
  value: string;
  onChange: (value: string) => void;
  warnPeriod?: number;
  onWarnPeriodChange?: (value: number) => void;
  blockPeriod?: number;
  onBlockPeriodChange?: (value: number) => void;
}

const ValidationAction: React.FC<ValidationActionProps> = ({
  value,
  onChange,
  warnPeriod,
  onWarnPeriodChange,
  blockPeriod,
  onBlockPeriodChange,
}) => {
  const { t } = useTranslation();
  const options = [
    { value: "do_nothing", label: t("policies.editPolicy.doNothingOption") },
    { value: "warn_person", label: t("policies.editPolicy.warnPersonOption") },
    {
      value: "warn_and_block",
      label: t("policies.editPolicy.warnBlockPersonOption"),
    },
  ];

  const day = 24 * 60 * 60;

  return (
    <div className="validation-action">
      <SettingsSection
        title={t("policies.editPolicy.validationAction")}
        description={""}
        mobileSupport={true}
      >
        <div className="validation-action-container">
          {options.map((option, index) => (
            <div
              className="validation-action-div"
              key={option.value}
              onClick={() => onChange(option.value)}
            >
              <input
                type="radio"
                id={option.value + index}
                value={option.value}
                checked={value === option.value}
                onChange={() => onChange(option.value)}
              />
              <label htmlFor={option.value}>{option.label}</label>
            </div>
          ))}
          <>
            {(value === "warn_person" || value === "warn_and_block") &&
              onWarnPeriodChange !== undefined && (
                <div className="warn-person-section">
                  <div className="warn-line">
                    <span className="warning-icon">
                      <img
                        src={WarningIcon}
                        style={{ height: "14px", width: "14px" }}
                      />
                    </span>
                    <span className="warn-text warn-text-bold">
                      {t("policies.editPolicy.warn")}{" "}
                    </span>
                    <span className="warn-text">
                      {t("policies.editPolicy.warnPerson")}
                    </span>
                    <input
                      type="number"
                      defaultValue={
                        warnPeriod ? Math.round(warnPeriod / day) : 0
                      }
                      className="warn-input"
                      min={0}
                      step={1}
                      onChange={(e) => {
                        onWarnPeriodChange(parseInt(e.target.value) * day || 0);
                      }}
                    />
                    <span className="warn-text warn-text-bold">
                      {t("policies.editPolicy.days")}
                    </span>
                  </div>
                </div>
              )}
            {value === "warn_and_block" &&
              onBlockPeriodChange !== undefined && (
                <div className="block-access-section">
                  <div className="warn-line">
                    <span className="block-icon">
                      <img
                        src={BlockIcon}
                        style={{ height: "14px", width: "14px" }}
                      />
                    </span>
                    <span className="block-text block-text-bold">
                      {t("policies.editPolicy.block")}{" "}
                    </span>{" "}
                    <span className="block-text">
                      {t("policies.editPolicy.blockPerson")}
                    </span>
                    <input
                      type="number"
                      defaultValue={
                        blockPeriod ? Math.round(blockPeriod / day) : 0
                      }
                      className="block-input"
                      min={0}
                      step={1}
                      onChange={(e) => {
                        onBlockPeriodChange(
                          parseInt(e.target.value) * day || 0,
                        );
                      }}
                    />
                    <span className="block-text block-text-bold">
                      {t("policies.editPolicy.days")}
                    </span>
                  </div>
                </div>
              )}
          </>
        </div>
      </SettingsSection>
    </div>
  );
};

export default ValidationAction;
