import { Role } from "../API/XFA_API";

export interface AuthState {
  cognitoUser: any | null;
  signedInUser: any | null;
  authState: "loading" | "signedIn" | "signup" | "login";
  signupStep: number;
  roles: Role[] | undefined;
  fixedEmail: string | undefined;
  emailVerified: boolean;
  hasPaymentMethod: boolean;
  loading: {
    tokenLoading: boolean | undefined;
    roleLoading: boolean | undefined;
    paymentLoading: boolean | undefined;
    signInLoading: boolean | undefined;
    urlLoading: boolean | undefined;
    discoveryLoading: boolean | undefined;
  };
}

export type AuthAction =
  | { type: "SET_COGNITO_USER"; payload: any | null }
  | { type: "SET_SIGNED_IN_USER"; payload: any | null }
  | {
      type: "SET_AUTH_STATE";
      payload: "loading" | "signedIn" | "signup" | "login";
    }
  | { type: "SET_SIGNUP_STEP"; payload: number }
  | { type: "SET_ROLES"; payload: Role[] | undefined }
  | { type: "SET_LOADING"; payload: Partial<AuthState["loading"]> }
  | { type: "SET_FIXED_EMAIL"; payload: string | undefined }
  | { type: "SET_EMAIL_VERIFIED"; payload: boolean }
  | { type: "SET_HAS_PAYMENT_METHOD"; payload: boolean };

export const initialState: AuthState = {
  cognitoUser: null,
  signedInUser: null,
  authState: "loading",
  signupStep: 0,
  roles: undefined,
  fixedEmail: undefined,
  emailVerified: false,
  hasPaymentMethod: false,
  loading: {
    tokenLoading: undefined,
    roleLoading: undefined,
    paymentLoading: undefined,
    signInLoading: undefined,
    urlLoading: undefined,
    discoveryLoading: false,
  },
};

export function reducer(state: AuthState, action: AuthAction): AuthState {
  switch (action.type) {
    case "SET_COGNITO_USER":
      return { ...state, cognitoUser: action.payload };
    case "SET_SIGNED_IN_USER":
      return { ...state, signedInUser: action.payload };
    case "SET_AUTH_STATE":
      return { ...state, authState: action.payload };
    case "SET_SIGNUP_STEP":
      return { ...state, signupStep: action.payload };
    case "SET_ROLES":
      return { ...state, roles: action.payload };
    case "SET_LOADING":
      return {
        ...state,
        loading: { ...state.loading, ...action.payload },
      };
    case "SET_FIXED_EMAIL":
      return { ...state, fixedEmail: action.payload };
    case "SET_EMAIL_VERIFIED":
      return { ...state, emailVerified: action.payload };
    case "SET_HAS_PAYMENT_METHOD":
      return { ...state, hasPaymentMethod: action.payload };
    default:
      return state;
  }
}
