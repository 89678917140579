import React from "react";
import { useTranslation } from "react-i18next";
import { FormControl, Typography } from "@mui/material";
import CopyIdComponent from "../CopyIdComponent";
import { Application } from "../../../API/XFA_API";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";

import XFAIcon from "../../xfa.png";

interface OneloginPageProps {
  creating: boolean;
  application: Application;
  setApplication: (application: Application) => void;
  vertical: boolean;
}

const OneloginPage: React.FC<OneloginPageProps> = (
  props: OneloginPageProps,
) => {
  const { t } = useTranslation();

  return (
    <div>
      <div
        className={
          props.vertical ? "verticalSelectionSection" : "selectionSection"
        }
      >
        <div style={{ marginBottom: 16 }}>
          <Typography variant="formsectiontitle">
            {t("applications.onelogin.title")}
          </Typography>
          <Typography variant="instructions" style={{ marginBottom: 16 }}>
            {t("applications.onelogin.content")}{" "}
            {t("applications.onelogin.documentation")}
            <Link
              target="_blank"
              href="https://docs.xfa.tech/docs/integration-guides/configure-on-idp/onelogin"
            >
              {t("applications.onelogin.documentationLink")}
            </Link>
          </Typography>
        </div>
        <div>
          <FormControl fullWidth={true}>
            <Typography variant="instructions" style={{ marginBottom: 16 }}>
              {t("applications.onelogin.start")}
            </Typography>
            <Typography variant="formlabel">
              Enable Trusted IdP: True
            </Typography>
            <Typography variant="formlabel">
              Show in Login Panel: False
            </Typography>
            <div style={{ marginBottom: 16 }}></div>
            <CopyIdComponent
              title={t("applications.onelogin.issuer")}
              value={"https://xfa.tech"}
              disabled={false}
            />
            <Typography variant="formlabel">
              Email Domains: {t("applications.onelogin.leaveEmpty")}
            </Typography>
            <Typography variant="formlabel">
              Sign users into OneLogin: True
            </Typography>
            <Typography variant="formlabel">
              Sign users into additional applications: False
            </Typography>
            <Typography variant="formlabel">
              Send Subject Name ID or Login Hint in Auth Request: True
            </Typography>
            <Typography variant="formlabel">
              User Attribute Value: {t("applications.onelogin.leaveEmpty")}
            </Typography>
            <Typography variant="formlabel">
              User Attribute Mapping: Username
            </Typography>
            <Typography variant="formlabel">
              Allowed Redirect URIs: {t("applications.onelogin.leaveEmpty")}
            </Typography>
            <div style={{ marginBottom: 16 }}></div>
            <CopyIdComponent
              title={t("applications.onelogin.authenticationEndpoint")}
              value={"https://device-api.tech/oauth2/authorize"}
              disabled={false}
            />
            <Typography variant="formlabel" style={{ marginBottom: 16 }}>
              Token Endpoint Auth. Method: POST
            </Typography>
            <CopyIdComponent
              title={t("applications.onelogin.tokenEndpoint")}
              value={"https://device-api.tech/oauth2/token"}
              disabled={false}
            />
            <CopyIdComponent
              title={t("applications.onelogin.userInformationEndpoint")}
              value={"https://device-api.tech/oauth2/userinfo"}
              disabled={false}
            />
            <CopyIdComponent
              title={t("applications.onelogin.scopes")}
              value={"openid email profile"}
              disabled={false}
            />
            <CopyIdComponent
              title={t("applications.onelogin.clientId")}
              value={props.application.ApplicationID}
              disabled={false}
            />
            <CopyIdComponent
              title={t("applications.onelogin.clientSecret")}
              value={props.application.ClientSecret || ""}
              disabled={false}
            />
            <Typography variant="instructions" style={{ marginBottom: 16 }}>
              {t("applications.onelogin.nextsteps1")}
            </Typography>
            <Button
              variant="outlined"
              style={{ marginBottom: 16 }}
              onClick={() => {
                const file = document.createElement("a");
                //download XFA Icon
                file.href = XFAIcon;
                file.download = "xfa-icon.png";
                file.click();
              }}
            >
              {t("applications.onelogin.downloadIcon")}
            </Button>

            <Typography variant="instructions" style={{ marginBottom: 16 }}>
              {t("applications.onelogin.nextsteps2")}
            </Typography>
          </FormControl>
        </div>
      </div>
    </div>
  );
};

export default OneloginPage;
