import React from "react";
import { Auth } from "aws-amplify";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";

import GoogleIcon from "./../icons/google-icon.svg";
import AppleIcon from "./../icons/apple-icon.svg";
import MicrosoftIcon from "./../icons/microsoft-icon.svg";

interface FederatedLoginsProps {
  signUp?: boolean;
  disabled?: boolean;
}

export const FederatedLogins = ({ signUp, disabled }: FederatedLoginsProps) => {
  const federatedLogin = (provider: CognitoHostedUIIdentityProvider) => {
    Auth.federatedSignIn({
      provider: provider,
    })
      .then((credentials) => {
        console.log(credentials);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const federatedLoginCustom = (customProvider: string) => {
    Auth.federatedSignIn({
      customProvider: customProvider,
    })
      .then((credentials) => {
        console.log(credentials);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const { t } = useTranslation();

  return (
    <>
      <OrDivider />
      <div
        style={{
          marginTop: 16,
          display: "flex",
          flexDirection: "column",
          gap: 4,
        }}
      >
        <FederatedButton
          text={
            signUp
              ? t("login.signUpWithMicrosoft")
              : t("login.signInWithMicrosoft")
          }
          icon={MicrosoftIcon}
          onClick={() => federatedLoginCustom("Microsoft")}
          disabled={disabled}
        />
        <FederatedButton
          text={
            signUp ? t("login.signUpWithGoogle") : t("login.signInWithGoogle")
          }
          icon={GoogleIcon}
          onClick={() => federatedLogin(CognitoHostedUIIdentityProvider.Google)}
          disabled={disabled}
        />
        <FederatedButton
          text={
            signUp ? t("login.signUpWithApple") : t("login.signInWithApple")
          }
          icon={AppleIcon}
          onClick={() => federatedLogin(CognitoHostedUIIdentityProvider.Apple)}
          disabled={disabled}
        />
      </div>
    </>
  );
};

interface FederatedButtonProps {
  text: string;
  icon?: string;
  onClick?: () => void;
  disabled?: boolean;
}

const FederatedButton = ({
  text,
  icon,
  onClick,
  disabled,
}: FederatedButtonProps) => {
  return (
    <Button
      variant={"outlined"}
      disableElevation
      size="large"
      style={{ width: "100%", fontWeight: 600, borderRadius: 6 }}
      onClick={onClick}
      disabled={disabled}
    >
      {icon && (
        <img src={icon} alt={text} style={{ marginRight: 4, height: 18 }} />
      )}
      {text}
    </Button>
  );
};

const OrDivider = () => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: 8,
        marginTop: 16,
      }}
    >
      <div style={{ flex: 1, height: 1, background: "rgba(0,0,0,0.12)" }} />
      <div style={{ color: "var(--color-gray-500)" }}>{t("general.or")}</div>
      <div style={{ flex: 1, height: 1, background: "rgba(0,0,0,0.12)" }} />
    </div>
  );
};
